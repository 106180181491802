import DateToDisplay from './DateToDisplay';

export default class AssetVersionToDisplay {
    constructor(assetVersion) {
        this.assetVersion = assetVersion;

        return new Proxy(assetVersion, {
            get: (wrappedObject, key) => {
                const target = key in this ? this : wrappedObject;
                return Reflect.get(target, key);
            },
        });
    }

    get numberToDisplay() {
        return `Version ${this.assetVersion.number}`;
    }

    get dateToDisplay() {
        return new DateToDisplay({date: this.assetVersion.createdAt}).displayShort;
    }

    get displayTimeAgo() {
        return new DateToDisplay({date: this.assetVersion.createdAt}).displayTimeAgoWithText;
    }
}
