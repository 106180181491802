import React from 'react';
import Router from '../services/Router';

const RouterContext = React.createContext(null);

export const RouterProvider = ({children}) => {
    const router = new Router();
    return (
        <RouterContext.Provider value={router}>
            {children}
        </RouterContext.Provider>
    );
};

export const useRouter = () => React.useContext(RouterContext);
