import React from 'react';
import ModalService from '../services/ModalService';

const ModalContext = React.createContext(null);

export const ModalProvider = ({children}) => {
    const modalService = new ModalService();
    return (
        <ModalContext.Provider value={modalService}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalService = () => React.useContext(ModalContext);
