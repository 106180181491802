import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    activitySection: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: palette.dashboardCardBackgroundSecondary,
        height: '100%',
    },

    empty: {
        color: '#728391',
        fontSize: '12px',
    },

    menu: {
        marginTop: '-5px',
        marginRight: '-8px',
    },

}));
