import {
    FIRST_NAME,
    LAST_NAME,
    CONFIRM_PASSWORD,
    EMAIL,
    PASSWORD,
} from '../constants/formFields/signUpForm';

const fields = [
    {
        name: FIRST_NAME,
        label: 'First Name',
        placeholder: 'Jay',
        rules: 'required',
        type: 'text',
    },
    {
        name: LAST_NAME,
        label: 'Last Name',
        placeholder: 'Doe',
        rules: 'required',
        type: 'email',
    },
    {
        name: EMAIL,
        label: 'Email Address',
        placeholder: 'name@mail.com',
        rules: 'required',
        type: 'email',
    },
    {
        name: PASSWORD,
        label: 'Password',
        placeholder: 'Password',
        rules: 'required',
        type: 'password',
    },
    {
        name: CONFIRM_PASSWORD,
        label: 'Confirm',
        placeholder: 'Password',
        rules: `required|same:${PASSWORD}`,
        type: 'password',
    },
];

export default fields;
