import {Icon} from '../Icon';
import {ReactComponent as ReactIcon} from '../../icons/plus-icon.svg';

const PlusIcon = () => (
    <Icon>
        <ReactIcon />
    </Icon>
);

export default PlusIcon;
