export default class StorageService {
    constructor({storage}) {
        this.storage = storage;
    }

    getItem = (key) => this.storage.getItem(key);

    setItem = (key, value) => this.storage.setItem(key, value);

    removeItem = (key) => this.storage.removeItem(key);
}
