import {Icon} from '../Icon';
import {personIcon} from '../../icons';

const PersonIcon = () => (
    <Icon>
        <img src={personIcon} alt="person" />
    </Icon>
);

export default PersonIcon;
