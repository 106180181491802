import {makeAutoObservable} from 'mobx';
import Form from '../forms/Form';
import fields from '../forms/createMediaGroupFields';
import {PROJECT_DETAIL_PATH} from '../routes/paths';
import CreateAssetPresenter from './CreateAssetPresenter';

export default class CreateMediaGroupPresenter {
    constructor({
        createMediaGroup,
        router,
        uploadFile,
        addAssetToMediaGroup,
        createAsset,
        createAssetVersion,
        getProjectFromStore,
        fetchProjectFromRemote,
    } = {}) {
        this.createMediaGroup = createMediaGroup;
        this.router = router;
        this.addAssetToMediaGroup = addAssetToMediaGroup;

        this.uploadFile = uploadFile;
        this.createAsset = createAsset;
        this.createAssetVersion = createAssetVersion;

        this.createAssetPresenters = [
            new CreateAssetPresenter({
                uploadFile: this.uploadFile,
                addAssetToMediaGroup: this.addAssetToMediaGroup,
                createAsset: this.createAsset,
                createAssetVersion: this.createAssetVersion,
            }),
        ];

        this.disableSaveButton = false;

        this.form = new Form({
            fields,
        }, {
            hooks: {
                onSuccess: this.onSubmitSuccess,
            },
        });

        this.getProjectFromStore = getProjectFromStore;
        this.fetchProjectFromRemote = fetchProjectFromRemote;

        makeAutoObservable(this);

        this._fetchProject();
    }

    onSubmitSuccess = async (form) => {
        const formData = {
            ...form.values(),
            projectId: this.getProjectIdFromRouter(),
        };

        await this.createMediaGroupWithAsset(formData);
    };

    getProjectIdFromRouter() {
        return this.router.resourceIds[0];
    }

    async createMediaGroupWithAsset(formData) {
        if (this.thereAreFilesToUpload) {
            this.disableSaveButton = true;
            const mediaGroup = await this.createMediaGroup.execute(formData);
            const mediaGroupId = mediaGroup.id;

            await Promise.all(this.createAssetPresenters.map(
                (createAssetPresenter) => createAssetPresenter.createAssetForMediaGroup(mediaGroupId),
            ));

            this._redirectToProjectDetailPath();
        }
    }

    addMediaGroupButtonWasClicked = () => {
        this.createAssetPresenters.push(
            new CreateAssetPresenter({
                uploadFile: this.uploadFile,
                addAssetToMediaGroup: this.addAssetToMediaGroup,
                createAsset: this.createAsset,
                createAssetVersion: this.createAssetVersion,
            }),
        );
    }

    cancelMediaGroupCreationButtonWasClicked = () => {
        this._redirectToProjectDetailPath();
    }

    get thereAreFilesToUpload() {
        return this.createAssetPresenters.some(
            (createAssetPresenter) => createAssetPresenter.hasFilesToUpload,
        );
    }

    get saveButtonIsDisabled() {
        return this.disableSaveButton || !this.thereAreFilesToUpload;
    }

    get addMediaButtonIsDisabled() {
        return !this.createAssetPresenters[this.createAssetPresenters.length - 1].hasFilesToUpload;
    }

    _redirectToProjectDetailPath = () => {
        const projectId = this.getProjectIdFromRouter();
        const projectDetailPath = PROJECT_DETAIL_PATH.replace(':id', projectId);

        this.router.navigateTo(projectDetailPath);
    };

    _fetchProject() {
        this.fetchProjectFromRemote.execute(this.getProjectIdFromRouter());
    }
}
