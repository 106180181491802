import MuiIcon from '@material-ui/core/Icon';
import {useStyles} from './Icon.styles';

const Icon = ({children}) => {
    const classes = useStyles();

    return (
        <MuiIcon className={classes.root}>
            {children}
        </MuiIcon>
    );
};

Icon.propTypes = {
};

Icon.defaultProps = {
};

export default Icon;
