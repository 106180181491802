export default class GetEntityFromStore {
    constructor({store, createEntity}) {
        this.store = store;
        this.createEntity = createEntity;
    }

    execute(id) {
        return this.entity(id) || this.createEmptyEntity(id);
    }

    entity(id) {
        return this.store.find(id);
    }

    findBy(callback) {
        return this.store.findBy(callback);
    }

    createEmptyEntity(id) {
        return this.store.add(this.createEntity({id}));
    }
}
