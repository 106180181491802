import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        minWidth: '600px',
    },

    personPlusIcon: {
        fill: '#fff',

        '& svg': {
            width: 'auto',
            height: 'auto',
        },

        '& span': {
            width: '100%',
            height: 'auto',
        },
    },

    members: {
        height: 'inherit',
        overflow: 'auto',
        backgroundColor: palette.itemListPrimary,
    },

    memberItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '60px',
    },

    memberInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '& p': {
            marginLeft: '15px',
            ...fonts.smallTitle1,

            color: palette.formTitle,
        },
    },

    owner: {
        display: 'flex',
        backgroundColor: palette.itemListPrimary,
        alignItems: 'center',
        height: '60px',
        marginTop: '16px',
        marginBottom: '8px',
    },

    ownerInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        '& p': {
            marginLeft: '15px',
            ...fonts.smallTitle1,

            color: palette.formTitle,
        },
    },

    ownerFlag: {
        ...fonts.smallTitle2,

        color: palette.highlight,
        marginLeft: '4px',
    },
}));

export const avatarStyle = {
    width: '40px',
    height: '40px',
    fontSize: '18px',
};
