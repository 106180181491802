import {makeAutoObservable} from 'mobx';
import mime from 'mime-types';
import {
    textIcon, textIconWhite, pdfIcon, videoIcon, videoIconWhite, defaultIcon, defaultIconWhite,
} from '../icons';

export default class AssetThumbnailPresenter {
    constructor({
        asset,
        assetVersionNumber = null,
        useWhiteThumbnails = false,
        img = null,
    } = {}) {
        this.asset = asset;
        this.assetVersionNumber = assetVersionNumber;
        this.useWhiteThumbnails = useWhiteThumbnails;
        this.img = img;

        makeAutoObservable(this);
    }

    get thumbnail() {
        if (!this.asset) { return this.img; }
        const thumbnail = this.assetVersionNumber
            ? this._assetVersionThumbnail(this.assetVersionNumber)
            : this._assetVersionThumbnail(this.lastAssetVersionNumber);

        return thumbnail || this._fileThumbnail;
    }

    isDefaultThumbnail(thumbnail) {
        return [
            this._defaultTextIcon,
            this._defaultVideoIcon,
            this._defaultIcon,
        ].includes(thumbnail);
    }

    get lastAssetVersionNumber() {
        const assetVersionNumbers = this._assetVersions.map((assetVersion) => (
            assetVersion.number
        ));

        return Math.max(...assetVersionNumbers);
    }

    get lastVersionHasThumbnail() {
        return this._assetVersionThumbnail(this.lastAssetVersionNumber) !== null;
    }

    get _assetVersions() {
        return this.asset.assetVersions;
    }

    _assetVersionThumbnail(assetVersionNumber) {
        const requestedAssetVersion = this._assetVersions.find((assetVersion) => (
            assetVersion.number === assetVersionNumber
        ));

        return requestedAssetVersion?.thumbnailUrl;
    }

    get _assetContentType() {
        return this.asset.contentType;
    }

    _contentTypeOf(fileExtension) {
        return mime.lookup(fileExtension);
    }

    get _fileThumbnail() {
        const icons = {
            [this._contentTypeOf('csv')]: this._defaultTextIcon,
            [this._contentTypeOf('txt')]: this._defaultTextIcon,
            [this._contentTypeOf('pdf')]: pdfIcon,
            [this._contentTypeOf('mp4')]: this._defaultVideoIcon,
            [this._contentTypeOf('mp3')]: this._defaultVideoIcon,
        };

        return icons[this._assetContentType] || this._defaultIcon;
    }

    get _defaultTextIcon() {
        return this.useWhiteThumbnails ? textIconWhite : textIcon;
    }

    get _defaultVideoIcon() {
        return this.useWhiteThumbnails ? videoIconWhite : videoIcon;
    }

    get _defaultIcon() {
        return this.useWhiteThumbnails ? defaultIconWhite : defaultIcon;
    }
}
