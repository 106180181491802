const escapeRegexSymbols = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const highlightString = (source, target, targetRegex, highlight) => {
    const res = [];

    if (!source) return res;
    if (!target && !targetRegex) return [source];

    const regex = targetRegex || new RegExp(escapeRegexSymbols(target), 'gi');

    let lastOffset = 0;

    source.replace(regex, (val, offset) => {
        res.push(
            source.substr(lastOffset, offset - lastOffset),
            highlight(val),
        );
        lastOffset = offset + val.length;
    });

    res.push(source.substr(lastOffset));

    return res;
};

export default highlightString;
