import {useEffect, useState} from 'react';
import MediaGroupDetailPresenter from '../presenters/MediaGroupDetailPresenter';
import FetchEntityFromRemote from '../interactors/FetchEntityFromRemote';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import {useToastService} from '../providers/ToastProvider';
import {useModalService} from '../providers/ModalProvider';
import {useFirebase} from '../providers/FirebaseProvider';
import AddAssetToMediaGroup from '../interactors/AddAssetToMediaGroup';
import UploadFile from '../interactors/UploadFile';
import DirectFileUpload from '../services/DirectFileUpload';
import Api from '../services/Api';
import AuthService from '../services/AuthService';
import ApiResponseDeserializer from '../services/ApiResponseDeserializer';
import OAuthResponseDeserializer from '../services/OAuthResponseDeserializer';
import GetEntityFromStoreFactory from '../interactors/GetEntityFromStoreFactory';
import CreateAsset from '../interactors/CreateAsset';
import CreateAssetVersion from '../interactors/CreateAssetVersion';
import EntityServiceFactory from '../services/EntityServiceFactory';
import GetAssetsByIdFromStore from '../interactors/GetAssetsByIdFromStore';
import GetEntitiesFromStore from '../interactors/GetEntitiesFromStore';
import AddProjectMember from '../interactors/AddProjectMember';
import GetCurrentUserFromStore from '../interactors/GetCurrentUserFromStore';
import RemoveProjectMember from '../interactors/RemoveProjectMember';
import GetProjectMembersFromStore from '../interactors/GetProjectMembersFromStore';
import FetchProjectMembersFromRemote from '../interactors/FetchProjectMembersFromRemote';
import ArchiveAsset from '../interactors/ArchiveAsset';
import LogOutUser from '../interactors/LogOutUser';
import ManageProject from '../interactors/ManageProject';
import RenameAsset from '../interactors/RenameAsset';

const useMediaGroupDetailWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();
    const toastService = useToastService();
    const modalService = useModalService();
    const firebase = useFirebase();

    useEffect(() => {
        const {
            authStore,
            projectStore,
            projectMemberStore,
            mediaGroupStore,
            assetStore,
            userStore,
        } = root;

        const authResponseDeserializerKlass = OAuthResponseDeserializer;
        const apiResponseDeserializerKlass = ApiResponseDeserializer;

        const authApi = new Api({
            authStore,
            responseDeserializerKlass: authResponseDeserializerKlass,
        });
        const api = new Api({
            authStore,
            responseDeserializerKlass: apiResponseDeserializerKlass,
        });

        const logInService = new AuthService({api: authApi, auth: firebase.auth});

        const mediaGroupService = EntityServiceFactory.forMediaGroups({api});

        const getMediaGroupFromStore = GetEntityFromStoreFactory.forMediaGroups(
            {store: mediaGroupStore},
        );

        const fetchMediaGroupFromRemote = new FetchEntityFromRemote({
            service: mediaGroupService,
            store: mediaGroupStore,
        });

        const addAssetToMediaGroup = new AddAssetToMediaGroup({
            mediaGroupService,
            mediaGroupStore,
            assetStore,
        });

        const assetService = EntityServiceFactory.forAssets({api});

        const projectService = EntityServiceFactory.forProjects({api});

        const uploadFile = new UploadFile({
            authStore,
            uploader: DirectFileUpload,
            service: assetService,
        });

        const createAsset = new CreateAsset({service: assetService});
        const createAssetVersion = new CreateAssetVersion({
            assetService,
            assetStore,
        });

        const getAssetsFromStore = new GetAssetsByIdFromStore(
            {store: assetStore},
        );

        const getCurrentUserFromStore = new GetCurrentUserFromStore({store: userStore});

        const getUsersFromStore = new GetEntitiesFromStore({store: userStore});

        const addProjectMember = new AddProjectMember({
            projectMemberStore,
            projectService,
        });

        const projectMemberService = EntityServiceFactory.forProjectMembers({api});

        const removeProjectMember = new RemoveProjectMember({
            projectMemberStore,
            projectMemberService,
        });

        const fetchAssetFromRemote = new FetchEntityFromRemote({
            store: assetStore,
            service: assetService,
        });

        const getProjectMembersFromStore = new GetProjectMembersFromStore({
            projectMemberStore,
        });

        const fetchProjectMembersFromRemote = new FetchProjectMembersFromRemote({
            projectMemberStore,
            projectService,
        });

        const archiveAsset = new ArchiveAsset({
            assetStore,
            assetService,
            mediaGroupStore,
        });

        const logOut = new LogOutUser({authService: logInService, userStore});

        const manageProject = new ManageProject({
            service: projectService,
            store: projectStore,
        });

        const fetchProjectFromRemote = new FetchEntityFromRemote({
            service: projectService,
            store: projectStore,
        });

        const getProjectFromStore = GetEntityFromStoreFactory.forProjects({
            store: projectStore,
        });

        const renameAsset = new RenameAsset({
            store: assetStore,
            service: assetService,
        });

        const presenter = new MediaGroupDetailPresenter({
            router,
            modalService,
            getProjectFromStore,
            fetchProjectFromRemote,
            getMediaGroupFromStore,
            fetchMediaGroupFromRemote,
            uploadFile,
            addAssetToMediaGroup,
            createAsset,
            createAssetVersion,
            getAssetsFromStore,
            getCurrentUserFromStore,
            getUsersFromStore,
            addProjectMember,
            removeProjectMember,
            fetchAssetFromRemote,
            toastService,
            getProjectMembersFromStore,
            fetchProjectMembersFromRemote,
            archiveAsset,
            logOut,
            manageProject,
            renameAsset,
        });

        setPresenterInstance(presenter);
    }, [root, router, toastService, modalService, firebase]);

    return presenterInstance;
};

export default useMediaGroupDetailWireframe;
