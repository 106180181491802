export const FOLDER_NAME = 'name';

const manageFolderFields = [{
    name: FOLDER_NAME,
    label: 'Name',
    placeholder: '',
    rules: 'required',
}];

export default manageFolderFields;
