import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import {TEXT} from '../../constants/formFields/createCommentForm';
import {TextAreaWithMentions} from '../TextAreaWithMentions';
import {IconButton} from '../IconButton';
import SendCommentIcon from '../Icons/SendCommentIcon';
import Form from '../../forms/Form';
import User from '../../entities/User';

import {useStyles} from './CommentBar.styles';

const CommentBar = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.commentArea}>
                <TextAreaWithMentions
                    {...presenter.form.$(TEXT).bind()}
                    users={presenter.users}
                    submitOnEnter
                    onSubmit={presenter.form.onSubmit}
                />
                <IconButton onClick={presenter.form.onSubmit} className={classes.button} id="send-comment">
                    <SendCommentIcon />
                </IconButton>
            </div>
            <div className={classes.commentBarFooter}>
                <Tooltip title={presenter.mentionTooltipText} placement="top">
                    <div>
                        <IconButton className={classes.tooltip}>
                            @
                        </IconButton>
                    </div>
                </Tooltip>
                {
                    presenter.form.$(TEXT).value !== ''
                    && (
                        <div className={classes.helpTextContainer}>
                            <p className={classes.helpText}>
                                Return
                                <span> to send</span>
                            </p>
                            <p className={classes.helpText}>
                                Shift + Return
                                <span> to add a new line</span>
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

CommentBar.propTypes = {
    presenter: PropTypes.shape({
        form: PropTypes.instanceOf(Form).isRequired,
        users: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
        mentionTooltipText: PropTypes.string,
    }).isRequired,
};

CommentBar.defaultProps = {
};

export default observer(CommentBar);
