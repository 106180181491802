import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    backDrop: {
        width: '100%',
        height: '100%',
        backgroundColor: `${palette.modalBackground} !important`,
        opacity: '0.8 !important',
    },

    invisible: {
        visibility: 'hidden',
    },

    modal: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
    },

    header: {
        position: 'relative',
    },

    closeButton: {
        position: 'absolute',
        right: '26px',
        top: '32px',

        '& button': {
            padding: 0,
            backgroundColor: 'transparent !important',
        },

        '& svg': {
            fill: '#a6a6a6',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: 'rotate(45deg)',
        },

        '& span': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },

        '&:hover svg': {
            fill: '#000',
        },
    },
}));
