import React from 'react';
import RootStore from '../stores/RootStore';

const RootStoreContext = React.createContext(null);

export const RootStoreProvider = ({children}) => {
    const rootStore = new RootStore();
    return (
        <RootStoreContext.Provider value={rootStore}>
            {children}
        </RootStoreContext.Provider>
    );
};

export const useRootStore = () => React.useContext(RootStoreContext);
