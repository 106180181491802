export default class FileWithUrl {
    constructor(file) {
        this.file = file;
        this.url = URL.createObjectURL(file);
    }

    get name() {
        return this.file.name;
    }

    get filepath() {
        return this.file.path;
    }

    delete() {
        URL.revokeObjectURL(this.url);
    }

    openInNewTab() {
        window.open(this.url);
    }

    download() {
        const link = document.createElement('a');
        link.href = this.url;
        link.setAttribute('download', this.file.name);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    static fromJSON({data, name, contentType}) {
        const file = new File([data], name, {type: contentType});
        return new FileWithUrl(file);
    }
}
