import {Icon} from '../Icon';
import {ReactComponent as ReactIcon} from '../../icons/person-plus-icon.svg';

const PersonPlusIcon = () => (
    <Icon>
        <ReactIcon />
    </Icon>
);

export default PersonPlusIcon;
