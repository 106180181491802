import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Avatar} from '../../Avatar';
import User from '../../../entities/User';

const UserOption = ({
    innerProps,
    user,
    className,
}) => (
    <div
        {...innerProps}
        className={className}
    >
        <Avatar
            user={user}
        />
        <p>{user.fullName}</p>
    </div>
);

UserOption.propTypes = {
    innerProps: PropTypes.any.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    className: PropTypes.string.isRequired,
};

UserOption.defaultProps = {
};

export default observer(UserOption);
