export default class MarkActivitiesAsSeen {
    constructor({service, store}) {
        this.service = service;
        this.store = store;
    }

    async execute() {
        const activities = this.store.markAsSeenAll();

        return activities.map((activity) => this.service.markAsSeenById(activity.id));
    }

    async markAsSeenById(activityId) {
        this.store.markAsSeenById(activityId);
        await this.service.markAsSeenById(activityId);
    }
}
