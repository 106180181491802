/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../Button/Button';
import noop from '../../helpers/noop';
import {useStyles} from './PrimaryButton.styles';

const PrimaryButton = ({
    onClick, children, id, startIcon, disabled, size,
    danger,
}) => {
    const classes = useStyles();

    return (
        <Button
            className={clsx(classes.root, size, danger && classes.danger)}
            onClick={onClick}
            id={id}
            startIcon={startIcon}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

PrimaryButton.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['small', 'normal']),
    danger: PropTypes.bool,
};

PrimaryButton.defaultProps = {
    onClick: noop,
    size: 'normal',
    danger: false,
};

export default PrimaryButton;
