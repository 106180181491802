import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import propTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import noop from '../../helpers/noop';

import {useStyles} from './InputText.styles';

const InputText = ({
    className,
    id,
    value,
    onChange,
    onBlur,
    multiline,
    errorMessage,
    startAdornment,
    innerRef,
    type,
    placeholder,
    disabled,
    size,
    autoFocus,
}) => {
    const classes = useStyles();

    return (
        <TextField
            autoComplete="off"
            inputRef={innerRef}
            className={clsx(classes.root, className)}
            id={id}
            variant="outlined"
            value={value}
            onChange={onChange}
            multiline={multiline}
            fullWidth
            error={!!errorMessage}
            helperText={errorMessage}
            InputProps={{
                startAdornment,
                classes: {
                    input: classes.input,
                    focused: classes.focused,
                    disabled: classes.disabled,
                },
            }}
            FormHelperTextProps={{
                classes: {
                    contained: classes.inputError,
                },
            }}
            onBlur={onBlur}
            type={type}
            margin="normal"
            size={size}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
            minRows={multiline ? 10 : 1}
            maxRows={20}
        />
    );
};

InputText.propTypes = {
    className: propTypes.string,
    id: propTypes.string.isRequired,
    errorMessage: propTypes.string,
    value: propTypes.string.isRequired,
    disabled: propTypes.bool,
    onChange: propTypes.func.isRequired,
    onBlur: propTypes.func,
    multiline: propTypes.bool,
    startAdornment: propTypes.node,
    innerRef: propTypes.oneOfType([
        propTypes.func,
        propTypes.shape({current: propTypes.elementType}),
    ]),
    type: propTypes.string,
    placeholder: propTypes.string,
    size: propTypes.string,
    autoFocus: propTypes.bool,
};

InputText.defaultProps = {
    className: '',
    disabled: false,
    errorMessage: '',
    multiline: false,
    startAdornment: null,
    onBlur: noop,
    innerRef: null,
    type: 'text',
    placeholder: '',
    size: 'small',
    autoFocus: false,
};

export default observer(InputText);
