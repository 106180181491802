import {EMAIL} from '../constants/formFields/addMemberForm';

const fields = [{
    name: EMAIL,
    label: 'Email',
    placeholder: 'Email',
    rules: 'required',
}];

export default fields;
