import EntityService from './EntityService';
import ProjectMember from '../entities/ProjectMember';

export default class ProjectService extends EntityService {
    async getMembers(projectId) {
        const membersJSON = await this.api.get(`${this.getBasePath()}/${projectId}/members`);

        return membersJSON.map((memberJSON) => ProjectMember.fromJSON(memberJSON));
    }

    async addMember({projectId, userId}) {
        const memberJSON = await this.api.post(
            `${this.getBasePath()}/${projectId}/members`, {
                user_id: userId,
            },
        );

        return ProjectMember.fromJSON(memberJSON);
    }

    async createLink(projectId, {
        title,
        address,
    }) {
        return this.api.post(
            `${this.getBasePath()}/${projectId}/links`, {
                link: {
                    title,
                    address,
                },
            },
        );
    }

    async updateLink(projectId, {
        id,
        title,
        address,
    }) {
        return this.api.patch(
            `${this.getBasePath()}/${projectId}/links/${id}`, {
                link: {
                    title,
                    address,
                },
            },
        );
    }

    async deleteLink(projectId, id) {
        return this.api.delete(`${this.getBasePath()}/${projectId}/links/${id}`);
    }
}
