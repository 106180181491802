/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {Comment} from '../Comment';
import CommentSectionPresenter from '../../presenters/CommentSectionPresenter';
import Scroll from '../Scroll/Scroll';
import classes from './CommentList.module.scss';

function CommentList({presenter, selectedVersionId}) {
    const versionsRefs = presenter.assetVersions.reduce((acc, assetVersion) => ({
        ...acc,
        [assetVersion.id]: useRef(null),
    }), {});
    const versionsMap = presenter.assetVersions.reduce((acc, assetVersion) => ({
        ...acc,
        [assetVersion.id]: assetVersion,
    }), {});
    const versionIds = Object.keys(versionsRefs).map((versionIndex) => Number(versionIndex));
    const containerRef = useRef(null);
    const listRef = useRef(null);
    const [
        previousVersionId,
        setPreviousVersionId,
    ] = useState(null);
    const [
        previousCommentsCount,
        setPreviousCommentsCount,
    ] = useState(0);
    const [
        activeCommentId,
        setActiveCommentId,
    ] = useState(null);
    const [
        previousActiveCommentId,
        setPreviousActiveCommentId,
    ] = useState(null);
    const onVersionClick = (assetVersion, commentId) => {
        setActiveCommentId(commentId);
        presenter.assetVersionWasClicked(assetVersion);
    };

    const currentCommentsCount = presenter.commentPresenters.length;

    useEffect(() => {
        const isLastVersion = selectedVersionId === presenter.assetLatestVersion.id;
        const versionChanged = previousVersionId !== selectedVersionId;
        const commentsCountChanged = previousCommentsCount !== currentCommentsCount;
        const scrollToVersion = (versionId) => {
            const versionIndex = versionIds.indexOf(versionId);
            const element = Object.values(versionsRefs)[versionIndex]?.current;

            if (!element) {
                return;
            }

            const rect = element.getBoundingClientRect();

            // to the bottom
            if (versionIndex === versionIds.length - 1) {
                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'end',
                });
            // to the activeCommentId
            } else if (activeCommentId) {
                element.querySelector(`[data-comment-id="${activeCommentId}"]`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            // to the invisible version
            } else if (
                // upper top container edge
                rect.top < 0
                // lower than bottom edge
                || rect.top - containerRef.current.offsetHeight > 0
            ) {
                // to the version
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            }

            setPreviousActiveCommentId(activeCommentId);
        };

        // should stick to the version container
        if (
            // first render
            (!previousVersionId && isLastVersion)
            // initial comments rendered (if no comments on initial render)
            || (commentsCountChanged)
            // comment was added to the latest version
            || (isLastVersion && previousCommentsCount !== currentCommentsCount)
            // select latest version
            || (versionChanged)
            // comments changed (even if version — not)
            || (previousActiveCommentId !== activeCommentId)
        ) {
            scrollToVersion(selectedVersionId);
        }

        setPreviousVersionId(selectedVersionId);
        setPreviousCommentsCount(currentCommentsCount);
    }, [
        versionIds,
        previousVersionId,
        selectedVersionId,
        previousCommentsCount,
        currentCommentsCount,
        presenter.assetLatestVersion.id,
        versionsRefs,
        activeCommentId,
        previousActiveCommentId,
    ]);

    return (
        <Scroll
            className={classes.container}
            theme={'dark'}
            forwardedRef={containerRef}
        >
            <div
                className={classes.list}
                ref={listRef}
            >
                {presenter.assetVersions.map((assetVersion, i, assetVersions) => {
                    const isActiveVersion = assetVersion.id === selectedVersionId;
                    const versionComments = presenter.commentPresenters.filter(({comment}) => comment.assetVersionId === assetVersion.id);
                    const versionStart = assetVersion.createdAt.getTime();
                    const versionEnd = assetVersions.length - 1 === i
                        ? null
                        : assetVersions[i + 1].createdAt;
                    const calloutVersionIds = versionIds.slice(0, i);

                    const calloutComments = presenter.commentPresenters.filter(({comment}) => (
                        calloutVersionIds.includes(comment.assetVersionId)
                            && comment.createdAt > versionStart
                            && (versionEnd ? comment.createdAt < versionEnd : true)
                    ));

                    const commentsItems = [
                        ...versionComments.map((commentPresenter) => ({
                            type: 'comment',
                            comment: commentPresenter,
                        })),
                        ...calloutComments.map((commentPresenter) => ({
                            type: 'callout',
                            comment: commentPresenter,
                        })),
                    ].sort((a, b) => (
                        a.comment.comment.createdAt - b.comment.comment.createdAt
                    ));

                    return (
                        <div
                            key={assetVersion.id}
                            className={classes.version}
                            ref={versionsRefs[assetVersion.id]}
                        >
                            <div className={classes.versionHeader}>
                                <span
                                    onClick={() => onVersionClick(assetVersion)}
                                    className={classes.versionValue}
                                >{assetVersion.numberToDisplay}</span> was uploaded <span className={classes.versionDate}>{assetVersion.displayTimeAgo}</span>
                            </div>
                            {commentsItems.length > 0 && (
                                <div className={classes.versionComments}>
                                    {commentsItems.map(({type, comment}) => {
                                        const commentVersion = versionsMap[comment.comment.assetVersionId];
                                        const {
                                            commentId,
                                        } = comment;

                                        return (
                                            <React.Fragment key={commentId}>
                                                {type === 'comment' && (
                                                    <Comment
                                                        presenter={comment}
                                                        isActive={activeCommentId === commentId}
                                                        onClick={() => onVersionClick(commentVersion)}
                                                    />
                                                )}
                                                {type === 'callout' && (
                                                    <div
                                                        className={clsx(classes.commentCallout, !isActiveVersion && classes.inactiveCommentCallout)}
                                                        onClick={() => onVersionClick(commentVersion, commentId)}
                                                    >
                                                        <span className={classes.commentCalloutUser}>
                                                            {comment.username}
                                                        </span> left a comment on <span
                                                            className={classes.versionValue}
                                                        >{commentVersion.numberToDisplay}</span>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </Scroll>
    );
}

CommentList.propTypes = {
    presenter: PropTypes.instanceOf(CommentSectionPresenter).isRequired,
    selectedVersionId: PropTypes.number.isRequired,
};

export default observer(CommentList);
