import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {useStyles} from './MentionList.styles';
import {Mention} from '../Mention';
import MentionPresenter from '../../presenters/MentionPresenter';

const MentionList = ({mentionPresenters}) => {
    const classes = useStyles();

    return (
        <div className={classes.mentionList}>
            {mentionPresenters.map((mentionPresenter) => (
                <Mention
                    key={mentionPresenter.mentionId}
                    presenter={mentionPresenter}
                />
            ))}
        </div>
    );
};

MentionList.propTypes = {
    mentionPresenters: PropTypes.arrayOf(
        PropTypes.instanceOf(MentionPresenter),
    ).isRequired,
};

MentionList.defaultProps = {
};

export default observer(MentionList);
