import EntityService from './EntityService';
import AssetVersion from '../entities/AssetVersion';
import Comment from '../entities/Comment';

export default class AssetService extends EntityService {
    getSnakeCaseEntityName() {
        return 'asset';
    }

    async createAssetVersion({assetId, file}) {
        const assetVersionJSON = await this.api.post(`${this.getBasePath()}/${assetId}/add_version`, {
            asset_version: {
                file,
            },
        });

        return AssetVersion.fromJSON(assetVersionJSON);
    }

    async getComments({assetId}) {
        const commentsJSON = await this.api.get(`${this.getBasePath()}/${assetId}/comments`);

        return commentsJSON.map((comment) => Comment.fromJSON(comment));
    }
}
