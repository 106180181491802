import {
    observer,
} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import classes from './ProjectLinks.module.scss';

function onActionClick(evt, callback) {
    evt.preventDefault();
    evt.stopPropagation();

    callback();
}

function ProjectsLinks({
    isEditable,
    links,
    onEdit,
    onDelete,
}) {
    return (
        <div className={classes.list}>
            {links.map(({id, title, address}) => (
                <div
                    key={id}
                    className={classes.item}
                    onClick={() => window.open(address, '_blank')}
                >
                    <div title={address}>
                        {title}
                    </div>
                    {isEditable && (
                        <div className={classes.itemActions}>
                            <div
                                className={classes.itemAction}
                                onClick={(evt) => onActionClick(evt, () => onEdit(id))}
                            >
                                Edit
                            </div>
                            <div
                                className={classes.itemAction}
                                onClick={(evt) => onActionClick(evt, () => onDelete(id))}
                            >
                                Delete
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

ProjectsLinks.propTypes = {
    isEditable: PropTypes.bool.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            project_id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default observer(ProjectsLinks);
