import {makeAutoObservable} from 'mobx';
import StorageService from '../services/StorageService';
import {TOKEN_KEY} from '../constants/storageKeys';

const EMPTY_TOKEN = '';

export default class AuthenticationStore {
    constructor({
        storageService = new StorageService({storage: localStorage}),
    } = {}) {
        this._storageService = storageService;
        this._userToken = this._storageService.getItem(TOKEN_KEY);

        makeAutoObservable(this, {_userToken: false, userToken: false});
    }

    deleteUserToken() {
        this._userToken = EMPTY_TOKEN;
        this._storageService.setItem(TOKEN_KEY, EMPTY_TOKEN);
    }

    setUserToken(token) {
        this._userToken = token;
        this._storageService.setItem(TOKEN_KEY, token);
    }

    get isAuthenticated() {
        return this._userToken !== undefined && this._userToken !== null
            && this._userToken !== EMPTY_TOKEN;
    }

    get userToken() {
        return this._userToken;
    }
}
