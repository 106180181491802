export default class CreateAssetVersion {
    constructor({assetService, assetStore}) {
        this.assetService = assetService;
        this.assetStore = assetStore;
    }

    async execute({assetId, file}) {
        const asset = this.assetStore.find(assetId);
        const assetVersion = await this.assetService.createAssetVersion({assetId, file});

        asset.addAssetVersion({assetVersion});

        this.assetStore.update(assetId, asset);

        return assetVersion;
    }
}
