import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import clsx from 'clsx';
import {useStyles} from './DashboardCard.styles';

const DashboardCard = ({
    children,
    className,
    header,
    headerActions,
    scrollable,
}) => {
    const classes = useStyles();
    const listClassName = scrollable
        ? classes.scrollableList
        : classes.staticList;
    const listRef = useRef();
    const [
        isScrolled,
        setScroll,
    ] = useState(false);

    const handleScroll = useCallback(() => {
        setScroll(listRef?.current?.scrollTop > 0);
    }, []);

    useEffect(() => {
        const element = listRef.current;

        if (scrollable && element) {
            element.addEventListener('scroll', handleScroll);
        }
    }, [
        scrollable,
        handleScroll,
    ]);

    return (
        <div className={clsx(classes.dashboardCard, className)}>
            <div className={clsx(classes.header, scrollable && isScrolled && classes.scrollableHeader)}>
                <h2>
                    {header}
                </h2>
                {headerActions}
            </div>
            <div
                className={listClassName}
                ref={listRef}
            >
                {children}
            </div>
        </div>
    );
};

DashboardCard.propTypes = {
    className: PropTypes.string,
    header: PropTypes.any,
    headerActions: PropTypes.any,
    scrollable: PropTypes.bool,
};

DashboardCard.defaultProps = {
    className: '',
    header: null,
    headerActions: null,
    scrollable: false,
};

export default observer(DashboardCard);
