import PropTypes from 'prop-types';

import highlightString from '../../helpers/highlightString';

const Highlight = ({
    source,
    target,
    targetRegex,
    renderHighlight,
}) => (
    highlightString(source, target, targetRegex, renderHighlight)
        .map((element, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>{element}</span>
        ))
);
Highlight.propTypes = {
    source: PropTypes.string,
    target: PropTypes.string,
    targetRegex: PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string]),
    renderHighlight: PropTypes.func,
};

Highlight.defaultProps = {
    source: null,
    target: null,
    targetRegex: null,
    renderHighlight: (text) => <strong>{text}</strong>,
};

export default Highlight;
