import {makeAutoObservable} from 'mobx';
import Form from '../forms/Form';
import fields from '../forms/createAssetFields';
import FileUploadPresenter from './FileUploadPresenter';

export default class CreateAssetPresenter {
    constructor({
        uploadFile,
        addAssetToMediaGroup,
        createAsset,
        createAssetVersion,
    } = {}) {
        this.addAssetToMediaGroup = addAssetToMediaGroup;

        this.fileUploadPresenter = new FileUploadPresenter({
            uploadFile,
            createAsset,
            createAssetVersion,
            maxFiles: 999,
        });

        this.form = new Form({fields});

        makeAutoObservable(this);
    }

    get hasFilesToUpload() {
        return this.fileUploadPresenter.hasFilesToUpload;
    }

    async createAssetForMediaGroup(mediaGroupId) {
        if (this.hasFilesToUpload) {
            const assets = await this.fileUploadPresenter.uploadAssets();

            await assets.reduce(async (acc, asset) => {
                const prevAcc = await acc;
                const addedAsset = await this.addAssetToMediaGroup.execute({
                    mediaGroupId,
                    assetId: asset.id,
                });

                return [
                    ...prevAcc,
                    addedAsset,
                ];
            }, Promise.resolve([]));

            this.fileUploadPresenter.clearFiles();
        }
    }
}
