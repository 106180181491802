import {makeStyles} from '@material-ui/core/styles';
import {fonts, palette} from '../../Theme';

export const dropdownStyles = {
    control: (base) => ({
        ...base,
        borderStyle: 'none',
        backgroundColor: palette.selectBackgroundDark,
        color: palette.textLight,
        borderRadius: '16px',
        minHeight: '24px',
        width: '115px',
        cursor: 'pointer',
    }),

    container: (base) => ({
        ...base,
    }),

    valueContainer: (base) => ({
        ...base,
        borderStyle: 'none',
        padding: '5px 5px 5px 12px',
        textAlign: 'center',
    }),

    dropdownIndicator: (base) => ({
        ...base,
        borderStyle: 'none',
        padding: '0 11px 0 0 ',
        color: palette.textLight,
        width: '25px',
    }),

    singleValue: (base) => ({
        ...base,
        ...fonts.buttonTextSmall,
        color: palette.textLight,
    }),

    menu: (base) => ({
        ...base,
        width: '300px',
        backgroundColor: palette.dashboardCardBackgroundPrimary,
        padding: '15px 0',
    }),
};

export const useStyles = makeStyles(() => ({
    dropdownOption: {
        ...fonts.buttonTextSmall,

        fontWeight: 400,
        color: palette.selectOptionSecondaryText,
        backgroundColor: palette.dashboardCardBackgroundPrimary,
        display: 'flex',
        alignItems: 'center',
        padding: '4px 9px',
        cursor: 'pointer',

        '& .dropdownOption__number': {
            fontWeight: 600,
        },

        '& .dropdownOption__title': {
            fontWeight: 500,
            color: palette.textDark,
        },

        '&.selected': {
            color: palette.primary,

            '& .dropdownOption__title': {
                fontWeight: 600,
                color: palette.primary,
            },
        },

        '&.focused': {
            backgroundColor: palette.selectOptionBackgroundFocused,
        },

        '& .dropdownOption__detail': {
            padding: '4px 0 3px 11px',
        },
    },
}));
