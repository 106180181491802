import PropTypes from 'prop-types';
import {TextDisplay} from '../TextDisplay';
import {ImageDisplay} from '../ImageDisplay';
import {PdfDisplay} from '../PdfDisplay';
import {VideoDisplay} from '../VideoDisplay';
import {AudioDisplay} from '../AudioDisplay';

const MediaDisplay = ({presenter}) => {
    const DisplayComponents = {
        text: TextDisplay,
        image: ImageDisplay,
        pdf: PdfDisplay,
        video: VideoDisplay,
        audio: AudioDisplay,
    };

    const DisplayComponent = DisplayComponents[presenter.type];

    return <DisplayComponent presenter={presenter} />;
};

MediaDisplay.propTypes = {
    presenter: PropTypes.shape({
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default MediaDisplay;
