import {useEffect, useState} from 'react';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import OAuthResponseDeserializer from '../services/OAuthResponseDeserializer';
import Api from '../services/Api';
import AuthService from '../services/AuthService';
import GetCurrentUserFromStore from '../interactors/GetCurrentUserFromStore';
import LogOutUser from '../interactors/LogOutUser';
import {useFirebase} from '../providers/FirebaseProvider';
import ProfilePresenter from '../presenters/ProfilePresenter';
import EntityServiceFactory from '../services/EntityServiceFactory';

const useProfileWireframe = () => {
    const [
        presenterInstance,
        setPresenterInstance,
    ] = useState(null);

    const root = useRootStore();
    const router = useRouter();
    const firebase = useFirebase();

    useEffect(() => {
        const {
            authStore,
            userStore,
        } = root;

        const usersService = EntityServiceFactory.forUsers({
            api: new Api({
                authStore,
            }),
        });
        const authApi = new Api({
            authStore,
            responseDeserializerKlass: OAuthResponseDeserializer,
        });
        const authService = new AuthService({
            api: authApi,
            auth: firebase.auth,
        });
        const getCurrentUserFromStore = new GetCurrentUserFromStore({
            store: userStore,
        });

        const logOut = new LogOutUser({
            authService,
            userStore,
        });

        const presenter = new ProfilePresenter({
            router,
            getCurrentUserFromStore,
            userStore,
            usersService,
            logOut,
        });

        setPresenterInstance(presenter);
    }, [
        root,
        router,
        firebase,
    ]);

    return presenterInstance;
};

export default useProfileWireframe;
