import {NAME} from '../constants/formFields/createMediaGroupForm';

const fields = [
    {
        name: NAME,
        label: 'Post title',
        placeholder: 'New media group name',
        rules: 'required',
    },
];

export default fields;
