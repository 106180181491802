import {makeStyles} from '@material-ui/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    container: {
        minWidth: '200px',
        width: 'auto',

        '--toastify-icon-color-success': '#fff',
        '--toastify-icon-color-error': '#fff',

        '& .Toastify__toast--error': {
            backgroundColor: palette.toastErrorBackground,
        },

        '& .Toastify__progress-bar--error': {
            backgroundColor: palette.toastErrorProgressBar,
        },

        '& .Toastify__toast--success': {
            backgroundColor: palette.toastSuccessBackground,
        },

        '& .Toastify__progress-bar--success': {
            backgroundColor: palette.toastSuccessProgressBar,
        },

        '& .Toastify__close-button': {
            opacity: '1',

            '& > svg': {
                color: palette.textLight,
            },
        },
    },

    toastBody: {
        padding: '15px',
        color: palette.textLight,
        fontSize: '20px',
        ...fonts.smallTitle1,
    },
}));
