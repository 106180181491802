/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../Button/Button';
import noop from '../../helpers/noop';
import {useStyles} from './BorderButton.styles';

const BorderButton = ({
    onClick, children, id, startIcon, disabled, size,
}) => {
    const classes = useStyles();

    return (
        <Button
            className={clsx(classes.root, size)}
            onClick={onClick}
            id={id}
            startIcon={startIcon}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

BorderButton.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['small', 'normal']),
};

BorderButton.defaultProps = {
    onClick: noop,
    size: 'normal',
};

export default BorderButton;
