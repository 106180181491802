import {RootStoreProvider} from './RootStoreProvider';
import {RouterProvider} from './RouterProvider';
import {FirebaseProvider} from './FirebaseProvider';
import {ModalProvider} from './ModalProvider';
import {ToastProvider} from './ToastProvider';

const AppProvider = ({children}) => (
    <RootStoreProvider>
        <ModalProvider>
            <ToastProvider>
                <RouterProvider>
                    <FirebaseProvider>
                        {children}
                    </FirebaseProvider>
                </RouterProvider>
            </ToastProvider>
        </ModalProvider>
    </RootStoreProvider>
);

export default AppProvider;
