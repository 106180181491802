import clsx from 'clsx';
import PropTypes from 'prop-types';

import DropzoneOriginal from 'react-dropzone';
import {useCallback} from 'react';
import {PrimaryButton} from '../PrimaryButton';

import classes from './Dropzone.module.scss';

const Dropzone = ({
    addFiles,
    accept,
    maxFiles,
    disabled,
    validator,
    onSelect,
    dropzoneProps,
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        addFiles(acceptedFiles);
        onSelect();
    }, [
        addFiles,
        onSelect,
    ]);
    const dropZoneProps = {
        accept,
        onDrop,
        maxFiles,
        disabled,
        validator,
        ...dropzoneProps,
    };

    return (
        <DropzoneOriginal {...dropZoneProps}>
            {({getRootProps, getInputProps, isDragActive}) => {
                const props = getRootProps({
                    className: clsx(
                        classes.dropzone,
                        isDragActive && !disabled && classes.dropzoneDragActive,
                        disabled && classes.dropzoneDisabled,
                    ),
                });
                const message = `Drag and drop ${maxFiles > 1 ? 'files' : 'file'} to upload`;
                const button = `Select ${maxFiles > 1 ? 'files' : 'file'}`;

                return (
                    <div {...props}>
                        <input {...getInputProps()} />
                        <div>
                            {message}
                        </div>
                        <PrimaryButton disabled={disabled}>
                            {button}
                        </PrimaryButton>
                    </div>
                );
            }}
        </DropzoneOriginal>
    );
};

Dropzone.propTypes = {
    addFiles: PropTypes.func.isRequired,
    accept: PropTypes.any.isRequired,
    maxFiles: PropTypes.number,
    disabled: PropTypes.bool,
    validator: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    dropzoneProps: PropTypes.shape({}),
};

Dropzone.defaultProps = {
    maxFiles: 0,
    disabled: false,
    validator: undefined,
    dropzoneProps: {},
};

export default Dropzone;
