import {makeStyles} from '@material-ui/core/styles';
import {fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#202325',
        margin: '5px 0 0 0',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 24px 15px 24px',
        maxHeight: '100%',
    },

    text: {
        color: '#4cacf7',
    },

    button: {
        textTransform: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        height: '35px',

        '& span': {
            display: 'unset',
        },
    },

    commentBarFooter: {
        display: 'flex',
        flexDirection: 'row',
    },

    helpTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-evenly',
    },

    helpText: {
        height: '15px',
        ...fonts.helpText,

        color: '#a8a9ab',
        fontWeight: 'bold',
        marginBottom: 0,

        '& span': {
            fontWeight: 'normal',
        },
    },

    tooltip: {
        width: '20px',
        height: '20px',
        color: '#4cacf7',
        fontSize: '15px',
        opacity: 0.8,

        '& span': {
            display: 'unset',
        },
    },

    commentArea: {
        display: 'flex',
        flexDirection: 'row',
        height: '100px',
        maxHeight: '100px',
    },
}));
