import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    activityList: {
        display: 'flex',
        flexDirection: 'column',
    },

    activity: {
        width: '100%',
        backgroundColor: palette.dashboardCardBackgroundSecondary,
        boxSizing: 'border-box',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderRadius: '6px',
        padding: '11px 8px 11px 19px',
        position: 'relative',

        '&:hover': {
            zIndex: '10',
            boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 12px 0 ${palette.lightBackground}`,
            backgroundColor: '#fff',
        },

        '&:hover .mark-as-seen': {
            visibility: 'visible',
        },
    },

    activityContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        width: '100%',
        overflow: 'hidden',
    },

    activityNotification: {
        backgroundColor: '#fd5b81',
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        position: 'absolute',
        left: '9px',
        top: '18px',
    },

    activityContentTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '20px',

        '& p': {
            marginTop: 0,
            marginBottom: 0,
            ...fonts.altSmallTitle,

            color: palette.mentionSecondaryText,
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: 'normal',
            whiteSpace: 'pre',
        },

        '& span': {
            marginTop: 0,
            marginBottom: 0,
            ...fonts.altSmallTitle,

            color: palette.mentionSecondaryText,
            fontSize: '12px',
            fontWeight: 'normal',
            whiteSpace: 'pre',
        },
    },

    activityProjectAndPost: {
        display: 'flex',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        gap: '4px',

        '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    activityContentBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        gap: '4px',

        '& p': {
            maxWidth: '50%',
            marginTop: '0',
            marginBottom: 0,
            ...fonts.altSmallTitle,

            color: palette.textDark,
            lineHeight: 'normal',
            fontSize: '12px',
            fontWeight: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        '& span': {
            marginTop: '0',
            ...fonts.altSmallTitle,

            color: palette.textDark,
            fontSize: '12px',
            fontWeight: 'normal',
        },
    },

    markAsSeen: {
        visibility: 'hidden',
        position: 'absolute',
        top: '13px',
        right: '8px',
        backgroundColor: '#fff',
    },
}));
