import {
    makeAutoObservable,
} from 'mobx';

export default class AppPresenter {
    constructor({
        authStore,
        currentUser,
        fetchAllUsers,
        logService,
    } = {}) {
        this.authStore = authStore;
        this.currentUser = currentUser;
        this.fetchAllUsers = fetchAllUsers;
        this.logService = logService;

        makeAutoObservable(this);

        this._initLogService();
    }

    async fetchUsersIfAuthenticated() {
        if (!this.authStore.isAuthenticated) {
            return;
        }

        await this.fetchAllUsers.execute();
        const currentUser = await this.currentUser.execute();

        this._identifyCurrentUserIfAuthenticated(currentUser);
    }

    _initLogService() {
        this.logService.init();
    }

    _identifyCurrentUserIfAuthenticated(currentUser) {
        if (!this.authStore.isAuthenticated) return;
        this.logService?.identify(currentUser.id);
    }
}
