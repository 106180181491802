import {makeAutoObservable} from 'mobx';
import Form from '../forms/Form';
import fields from '../forms/logInFields';
import resetPasswordFields, {
    EMAIL,
} from '../forms/resetPasswordFields';
import {SIGNUP_PATH} from '../routes/paths';
import {EDIT_FIELDS_MODAL} from '../constants/modals';

export default class LogInPresenter {
    constructor({
        logInUser,
        authStore,
        authService,
        router,
        currentUser,
        fetchAllUsers,
        logService,
        modalService,
    } = {}) {
        this.logInUser = logInUser;
        this.authStore = authStore;
        this.authService = authService;
        this.router = router;
        this.currentUser = currentUser;
        this.fetchAllUsers = fetchAllUsers;
        this.logService = logService;
        this.formError = null;
        this.submitDisabled = false;
        this.modalService = modalService;

        if (this.isLoggedIn()) {
            this.router.onLogIn();
        }

        this.loginForm = new Form({
            fields,
        }, {
            hooks: {
                onSuccess: this.onSubmitSuccess,
            },
        });
        this.resetPasswordForm = new Form({
            fields: resetPasswordFields,
        }, {
            hooks: {
                onSuccess: this.onResetPasswordSuccess,
            },
        });

        makeAutoObservable(this);
    }

    onSubmitSuccess = async (form) => {
        const formData = form.values();
        this.formError = null;
        this.submitDisabled = true;

        try {
            await this.logInUser.execute(formData);
            const currentUser = await this.currentUser.execute();
            await this.fetchAllUsers.execute();
            this._identifyCurrentUserInLogService(currentUser);
            this.router.onLogIn();
        } catch (ex) {
            this.formError = 'Invalid email or password';
        } finally {
            this.submitDisabled = false;
        }
    }

    resetPassword = () => {
        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: 'Enter your email to reset password',
            inputs: {
                [EMAIL]: {
                    control: this.resetPasswordForm.$(EMAIL),
                },
            },
            onConfirm: this.resetPasswordForm.onSubmit,
            cancelButton: 'Cancel',
            submitButton: 'Reset password',
            submitDisabled: () => !this.resetPasswordForm.$(EMAIL).value.length,
            onClose: this.closeResetPasswordModal,
        });
    }

    closeResetPasswordModal = () => {
        this.modalService.closeModal(EDIT_FIELDS_MODAL);
        this.resetPasswordForm.clear();
    }

    onResetPasswordSuccess = async (form) => {
        const formData = form.values();

        try {
            await this.authService.sendPasswordResetEmail(formData[EMAIL]);
            this.resetPasswordForm.clear();
            this.modalService.setProps(EDIT_FIELDS_MODAL, {
                ...this.modalService.getProps(EDIT_FIELDS_MODAL),
                title: '',
                message: `You’ll receive an e-mail with instructions on resetting your password.
Remember to check your spam folder!`,
                inputs: {},
                onConfirm: () => this.modalService.closeModal(EDIT_FIELDS_MODAL),
                cancelButton: 'Back to Log in',
                submitButton: '',
                submitDisabled: false,
            });
        } catch (ex) {
            this.resetPasswordForm.$(EMAIL).invalidate('There’s no account for that e-mail address.');
        }
    }

    isLoggedIn() {
        return this.authStore.isAuthenticated;
    }

    clear = () => {
        this.loginForm.clear();
    };

    signUpButtonWasClicked = () => {
        this.router.navigateTo(SIGNUP_PATH);
    };

    _identifyCurrentUserInLogService(currentUser) {
        this.logService?.identify(currentUser.id);
    }
}
