import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {TextButton} from '../TextButton';
import {PrimaryButton} from '../PrimaryButton';
import {useStyles} from './FormHeader.styles';

const FormHeader = ({
    onCancelClick,
    onClick,
    formTitle,
    cancelButtonName,
    submitButtonName,
    submitButtonNameIsDisabled,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            { formTitle && <h1>{formTitle}</h1> }
            <div className={classes.margin}>
                <TextButton
                    onClick={onCancelClick}
                >
                    {cancelButtonName}
                </TextButton>
                <PrimaryButton
                    onClick={onClick}
                    disabled={submitButtonNameIsDisabled}
                >
                    {submitButtonName}
                </PrimaryButton>
            </div>
        </div>
    );
};

FormHeader.propTypes = {
    onCancelClick: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    formTitle: PropTypes.string,
    cancelButtonName: PropTypes.string,
    submitButtonName: PropTypes.string,
    submitButtonNameIsDisabled: PropTypes.bool,
};

FormHeader.defaultProps = {
    formTitle: null,
    submitButtonName: 'Submit',
    cancelButtonName: 'Cancel',
    submitButtonNameIsDisabled: false,
};

export default observer(FormHeader);
