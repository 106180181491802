export default class RenameAsset {
    constructor({
        service,
        store,
    }) {
        this.service = service;
        this.store = store;
    }

    async execute(assetId, {
        filename,
        description,
    }) {
        const asset = await this.service.update(assetId, {
            filename,
            description,
        });
        this.store.update(assetId, asset);

        return asset;
    }
}
