import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Avatar} from '../../Avatar';

import {useStyles, avatarStyle} from '../TeamModal.styles';
import User from '../../../entities/User';

const Owner = ({owner}) => {
    const classes = useStyles();

    return (
        <div className={classes.owner}>
            <div className={classes.ownerInfo}>
                <Avatar
                    user={owner}
                    styles={avatarStyle}
                />
                <p>{`${owner?.fullName} -`}</p>
            </div>
            <p className={classes.ownerFlag}>Project Manager</p>
        </div>
    );
};

Owner.propTypes = {
    owner: PropTypes.instanceOf(User),
};

Owner.defaultProps = {
    owner: null,
};

export default observer(Owner);
