import PropTypes from 'prop-types';

import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import {Select} from '../Select';
import {dropdownStyles, useStyles} from './VersionDropdown.style';
import VersionOption from './components/VersionOption';
import AssetVersion from '../../entities/AssetVersion';

const VersionDropdown = ({
    selectedVersion,
    onVersionSelected,
    versions,
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const styles = useStyles();

    const onChange = (option) => {
        onVersionSelected(option);
        setMenuIsOpen(false);
    };

    return (
        <Select
            onChange={onChange}
            onFocus={() => setMenuIsOpen(true)}
            onBlur={() => setMenuIsOpen(false)}
            menuIsOpen={menuIsOpen}
            blurInputOnSelect
            isSearchable={false}
            options={versions}
            getOptionLabel={(version) => `Version ${version.number}`}
            value={selectedVersion}
            components={{
                IndicatorSeparator: () => null,
                Option: (props) => (
                    <VersionOption
                        {...props}
                        className={styles.dropdownOption}
                    />
                ),
            }}
            styles={dropdownStyles}
        />
    );
};

VersionDropdown.propTypes = {
    selectedVersion: PropTypes.instanceOf(AssetVersion),
    onVersionSelected: PropTypes.func.isRequired,
    versions: PropTypes.arrayOf(
        PropTypes.instanceOf(AssetVersion),
    ),
};

VersionDropdown.defaultProps = {
    selectedVersion: null,
    versions: [],
};

export default observer(VersionDropdown);
