import {observer} from 'mobx-react-lite';
import {
    TEAM_MODAL,
    INVITE_MEMBER_MODAL,
    FILE_UPLOAD_MODAL,
    EDIT_FIELDS_MODAL,
    CONFIRMATION_MODAL,
    MANAGE_FOLDER_MODAL,
    CREATE_PROJECT_MODAL,
} from '../../constants/modals';
import {useModalService} from '../../providers/ModalProvider';
import TeamModal from '../TeamModal/TeamModal';
import {InviteMemberToProjectModal} from '../InviteMemberToProjectModal';
import {FileUploadModal} from '../FileUploadModal';
import {EditFieldModal} from '../EditFieldModal';
import {ConfirmationModal} from '../ConfirmationModal';
import {ManageFolderModal} from '../ManageFolderModal';
import CreateProjectModal from '../CreateProjectModal/CreateProjectModal';

const ModalWrapper = () => {
    const modalService = useModalService();

    const modals = [
        {Component: TeamModal, key: TEAM_MODAL},
        {Component: InviteMemberToProjectModal, key: INVITE_MEMBER_MODAL},
        {Component: FileUploadModal, key: FILE_UPLOAD_MODAL},
        {Component: EditFieldModal, key: EDIT_FIELDS_MODAL},
        {Component: ConfirmationModal, key: CONFIRMATION_MODAL},
        {Component: ManageFolderModal, key: MANAGE_FOLDER_MODAL},
        {Component: CreateProjectModal, key: CREATE_PROJECT_MODAL},
    ];

    const mountModal = ({Component, key}) => (
        <Component
            key={key}
            open={modalService.isOpen(key)}
            onClose={() => modalService.closeModal(key)}
            {...modalService.getProps(key)}
        />
    );

    return (
        <>
            {modals.filter(({key}) => modalService.isOpen(key)).map(mountModal)}
        </>
    );
};

export default observer(ModalWrapper);
