import {TEXT} from '../constants/formFields/createCommentForm';

const fields = [{
    name: TEXT,
    label: 'Comment',
    placeholder: 'Comment',
    rules: 'required',
}];

export default fields;
