import clsx from 'clsx';
import {
    observer,
} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import classes from './Scrollable.module.scss';
import Scroll from '../Scroll/Scroll';

function Scrollable({
    className,
    children,
    theme,
    watch,
}) {
    const scrollableRef = useRef();
    const [
        isScrolled,
        setScroll,
    ] = useState(false);

    const handleScroll = useCallback(() => {
        setScroll(scrollableRef?.current?.scrollTop > 0);
    }, []);

    useEffect(() => {
        const element = scrollableRef.current;

        if (element && watch) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [
        handleScroll,
        watch,
    ]);

    return (
        <div className={clsx(classes.wrapper, isScrolled && classes.scrolled)}>
            <Scroll
                className={className}
                forwardedRef={scrollableRef}
                theme={theme}
            >
                {children}
            </Scroll>
        </div>
    );
}

Scrollable.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]).isRequired,
    watch: PropTypes.bool.isRequired,
};

Scrollable.defaultProps = {
    className: '',
};

export default observer(Scrollable);
