import PropTypes from 'prop-types';
import {useStyles} from './InputTextLabel.styles';

const InputTextLabel = ({text}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {text}
        </div>
    );
};

InputTextLabel.propTypes = {
    text: PropTypes.any,
};

InputTextLabel.defaultProps = {
    text: '',
};

export default InputTextLabel;
