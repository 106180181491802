import EntityService from './EntityService';
import Project from '../entities/Project';

export default class UserService extends EntityService {
    async getCurrentUser() {
        const userJSON = await this.api.get(`${this.getBasePath()}/me`);

        return this.buildEntity(userJSON);
    }

    async getCurrentUserProjects() {
        const projectList = await this.api.get(`${this.getBasePath()}/me/projects`);

        return projectList.map(
            (projectJSON) => Project.fromJSON(projectJSON),
        );
    }

    async updateUser(userId, user) {
        const userJSON = await this.api.patch(`${this.getBasePath()}/${userId}`, {
            user,
        });

        return this.buildEntity(userJSON);
    }
}
