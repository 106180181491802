import PropTypes from 'prop-types';

import {Route, Redirect} from 'react-router-dom';
import {useRootStore} from '../../providers/RootStoreProvider';
import {LOGIN_PATH} from '../../routes/paths';

const PrivateRoute = ({component, ...props}) => {
    const {authStore} = useRootStore();
    const Component = component;

    return (
        <Route
            {...props}
            component={({location}) => (authStore.isAuthenticated ? (
                <Component />
            ) : (
                <Redirect
                    to={{
                        pathname: LOGIN_PATH,
                        state: {from: location},
                    }}
                />
            ))}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
