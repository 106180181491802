import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {LogInForm} from '../LogInForm';

import {useStyles} from './LogInSection.styles';
import LargeLogo from '../LargeLogo/LargeLogo';
import LogInPresenter from '../../presenters/LogInPresenter';

const LogInSection = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.LogInSection}>
            <div className={classes.logo}>
                <LargeLogo />
            </div>
            <h1 className={classes.header}>Sign in</h1>
            <LogInForm presenter={presenter} />
        </div>
    );
};

LogInSection.propTypes = {
    presenter: PropTypes.instanceOf(LogInPresenter).isRequired,
};

export default observer(LogInSection);
