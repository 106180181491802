import PropTypes from 'prop-types';
import iconDark from '../../icons/kebab.svg';
import iconLight from '../../icons/kebab-light.svg';

const KebabIcon = ({
    theme,
}) => (
    <img
        src={theme === 'dark' ? iconDark : iconLight}
        alt={''}
    />
);

KebabIcon.propTypes = {
    theme: PropTypes.oneOf(['dark', 'light']).isRequired,
};

KebabIcon.defaultProps = {};

export default KebabIcon;
