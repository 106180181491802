import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    addMember: {
        padding: 0,
        fill: '#728391',

        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },

    projectTeamSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '3px',
        margin: '0 0 32px 0',
    },

    teamList: {
        borderRadius: '8px',
    },

    teamSection: {
        display: 'flex',
        alignItems: 'center',
        gap: '3px',
        margin: '0 0 32px 0',
    },
}));

export const addMemberStyle = {
    width: '32px',
    height: '32px',
    marginLeft: '0 !important',
    border: 'none !important',
};
