export default class LogInUser {
    constructor({authService}) {
        this.authService = authService;
    }

    async execute({email, password}) {
        const firebaseIdToken = await this.authService.logInWithFirebase({email, password});
        await this.authService.logIn(firebaseIdToken);
    }
}
