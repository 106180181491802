import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import noop from '../../helpers/noop';
import {useStyles} from './TextButton.styles';

const TextButton = ({
    onClick, children, id, startIcon,
}) => {
    const classes = useStyles();

    return (
        <Button
            className={classes.button}
            onClick={onClick}
            id={id}
            startIcon={startIcon}
        >
            {children}
        </Button>
    );
};

TextButton.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.number,
    startIcon: PropTypes.string,
};

TextButton.defaultProps = {
    onClick: noop,
    id: null,
    startIcon: null,
};

export default observer(TextButton);
