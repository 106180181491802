import {useEffect, useState} from 'react';
import {useRootStore} from '../providers/RootStoreProvider';
import AppPresenter from '../presenters/AppPresenter';
import CurrentUser from '../interactors/CurrentUser';
import Api from '../services/Api';
import FetchEntitiesFromRemote from '../interactors/FetchEntitiesFromRemote';
import EntityServiceFactory from '../services/EntityServiceFactory';
import LogService from '../services/LogService';

const useAppWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();

    useEffect(() => {
        const {authStore, userStore} = root;

        const authApi = new Api({authStore});

        const userService = EntityServiceFactory.forUsers(
            {api: authApi},
        );

        const currentUser = new CurrentUser({
            userStore,
            userService,
        });

        const fetchAllUsers = new FetchEntitiesFromRemote({
            store: userStore,
            service: userService,
        });

        const logService = new LogService();

        const presenter = new AppPresenter({
            authStore,
            currentUser,
            fetchAllUsers,
            logService,
        });

        async function fetchCurrentUser() {
            await presenter.fetchUsersIfAuthenticated();
            setPresenterInstance(presenter);
        }
        fetchCurrentUser();
    }, [root]);

    return presenterInstance;
};

export default useAppWireframe;
