import {useEffect} from 'react';
import {Router} from 'react-router-dom';
import Navigation from './Navigation';
import {useRouter} from './providers/RouterProvider';
import ModalWrapper from './components/ModalWrapper/ModalWrapper';
import useAppWireframe from './wireframes/useAppWireframe';
import {Toasts} from './components/Toasts';
import {ScrollToTop} from './components/ScrollToTop';

const App = () => {
    const router = useRouter();
    const presenter = useAppWireframe();

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME;
    }, []);

    if (!presenter) return null;
    return (
        <Router history={router.history}>
            <ScrollToTop />
            <Navigation />
            <Toasts />
            <ModalWrapper />
        </Router>
    );
};

export default App;
