import Font from './Font';

class FontFactory {
    static createTitle(fontFamily) {
        return new Font({
            fontFamily: fontFamily.semiBold,
            fontSize: 32,
            fontWeight: 600,
            letterSpacing: 'normal',
        });
    }

    static createSubTitle(fontFamily) {
        return new Font({
            fontFamily: fontFamily.semiBold,
            fontSize: 22,
            fontWeight: 600,
            letterSpacing: 'normal',
        });
    }

    static createSmallTitle1(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regular,
            fontSize: 16,
            fontWeight: 'normal',
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
        });
    }

    static createSmallTitle2(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regularPoppins,
            fontSize: 14,
            fontWeight: 'normal',
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
        });
    }

    static createButtonText(fontFamily) {
        return new Font({
            fontFamily: fontFamily.button,
            fontSize: '14px',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            fontWeight: '500',
        });
    }

    static createButtonTextSmall(fontFamily) {
        return new Font({
            fontFamily: fontFamily.button,
            fontSize: '12px',
            lineHeight: 'normal',
            letterSpacing: 0.4,
            fontWeight: '500',
        });
    }

    static createBodyFocus(fontFamily) {
        return new Font({
            fontFamily: fontFamily.bold,
            fontSize: 16,
            lineHeight: '1.5',
            letterSpacing: 0,
        });
    }

    static createBody(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regularPoppins,
            fontSize: 14,
            lineHeight: '1.5',
            letterSpacing: 0,
        });
    }

    static createBodySmall(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regular,
            fontSize: 13,
            lineHeight: '1.5',
            letterSpacing: 0,
            fontWeight: '700',
        });
    }

    static createTextLink(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regular,
            fontSize: 16,
            lineHeight: 20,
            letterSpacing: 0,
            fontWeight: '500',
        });
    }

    static createAltSmallTitle(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regularPoppins,
            fontSize: '16px',
            fontWeight: '500',
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
        });
    }

    static createBreadcrumbs(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regularPoppins,
            fontSize: '16px',
            fontWeight: '300',
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
        });
    }

    static createHelpText(fontFamily) {
        return new Font({
            fontFamily: fontFamily.regularPoppins,
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
        });
    }
}

export default FontFactory;
