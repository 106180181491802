import {
    makeObservable,
    observable,
    action,
    computed,
} from 'mobx';

export default class Store {
    constructor() {
        this.entities = [];

        makeObservable(this, {
            entities: observable,
            add: action,
            update: action,
            updateList: action,
            set: action,
            all: computed,
            find: action,
        });
    }

    prepend(entity) {
        this.entities = [
            entity,
            ...this.entities,
        ];
        return entity;
    }

    add(entity) {
        this.entities.push(entity);
        return entity;
    }

    update(entityId, updated) {
        let entity = this.find(entityId);
        if (entity) {
            Object.keys(entity).forEach((property) => {
                entity[property] = updated[property];
            });
        } else {
            entity = this.add(updated);
        }
        return entity;
    }

    updateList(updatedEntities) {
        this.entities.reduceRight((_, entity) => {
            if (!updatedEntities.find((updatedEntity) => updatedEntity.id === entity.id)) {
                this.entities.remove(entity);
            }
            return null;
        }, null);

        return updatedEntities.map((updatedEntity) => (
            this.update(updatedEntity.id, updatedEntity)
        ));
    }

    set(entities) {
        this.entities.replace(entities);
    }

    get all() {
        return this.entities;
    }

    find(id) {
        return this.entities.find((entity) => entity.id === id) || null;
    }

    findAll(ids) {
        return this.filter((entity) => ids.includes(entity.id)) || null;
    }

    findBy(callback) {
        return this.entities.find(callback);
    }

    filter(callback) {
        return this.entities.filter(callback);
    }

    delete(entityId) {
        const entityToRemove = this.entities.find((entity) => entity.id === entityId);
        this.entities.remove(entityToRemove);
    }

    deleteAll() {
        this.entities.clear();
    }
}
