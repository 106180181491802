import {makeAutoObservable} from 'mobx';

export default class ModalService {
    constructor() {
        this.modals = {};
        makeAutoObservable(this);
    }

    getModal = (key) => this.modals[key] || null

    isOpen = (key) => {
        const modal = this.getModal(key);
        return !!modal && modal.open;
    };

    getProps = (key) => {
        const modal = this.getModal(key);
        return modal && modal.props;
    }

    setProps = (key, props) => {
        const modal = this.getModal(key);
        if (modal) {
            modal.props = props;
        }
    }

    openModal(key, props = {}) {
        this.modals[key] = {
            open: true,
            props,
        };
    }

    closeModal(key) {
        this.setProps(key, {});
        this.getModal(key).open = false;
    }
}
