import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';

import clsx from 'clsx';
import {useStyles} from './Card.styles';

const Card = ({children, className}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.card, className)}>
            {children}
        </div>
    );
};

Card.propTypes = {
    className: PropTypes.string,
};

Card.defaultProps = {
    className: '',
};

export default observer(Card);
