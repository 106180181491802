import AuthenticationStore from './AuthenticationStore';
import UserStore from './UserStore';
import MentionsStore from './MentionsStore';
import Store from './Store';
import ActivitiesStore from './ActivitiesStore';

export default class RootStore {
    constructor() {
        this.authStore = new AuthenticationStore();
        this.userStore = new UserStore();
        this.projectStore = new Store();
        this.mediaGroupStore = new Store();
        this.assetStore = new Store();
        this.commentStore = new Store();
        this.mentionStore = new MentionsStore();
        this.projectMemberStore = new Store();
        this.assetVersionStore = new Store();
        this.activitiesStore = new ActivitiesStore();
    }
}
