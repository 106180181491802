export default class CreateComment {
    constructor({assetVersionService, commentStore}) {
        this.assetVersionService = assetVersionService;
        this.commentStore = commentStore;
    }

    async execute({assetVersionId, text}) {
        const comment = await this.assetVersionService.createComment({assetVersionId, text});
        this.commentStore.add(comment);

        return comment;
    }
}
