import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {ModalHeader} from '../ModalHeader';
import Form from '../../forms/Form';
import User from '../../entities/User';
import {AddMemberForm} from '../AddMemberForm';
import {ModalCard} from '../ModalCard';
import Modal from '../Modal/Modal';

import {useStyles} from './InviteMemberToProjectModal.styles';
import ModalBody from '../ModalBody/ModalBody';

const InviteMemberToProjectModal = ({
    open,
    onClose,
    returnToPreviousModalButtonWasClicked,
    form,
    users,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            {...rest}
        >
            <ModalCard className={classes.root}>
                <ModalHeader>
                    <h1>Invite member to project</h1>
                </ModalHeader>
                <ModalBody>
                    <AddMemberForm
                        returnToPreviousModalButtonWasClicked={returnToPreviousModalButtonWasClicked}
                        form={form}
                        users={users}
                    />
                </ModalBody>
            </ModalCard>
        </Modal>
    );
};

InviteMemberToProjectModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    returnToPreviousModalButtonWasClicked: PropTypes.func,
    form: PropTypes.instanceOf(Form),
    users: PropTypes.arrayOf(PropTypes.instanceOf(User)),
};

InviteMemberToProjectModal.defaultProps = {
    open: false,
    returnToPreviousModalButtonWasClicked: null,
    form: null,
    users: [],
};

export default observer(InviteMemberToProjectModal);
