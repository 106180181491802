import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';

const TextDisplay = ({presenter}) => (
    <div>
        {presenter.text}
    </div>
);

TextDisplay.propTypes = {
    presenter: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }).isRequired,
};

export default observer(TextDisplay);
