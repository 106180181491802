import AvailabilityError from '../errors/AvailabilityError';
import InputError from '../errors/InputError';
import NetworkError from '../errors/NetworkError';
import UnknownError from '../errors/UnknownError';

export default class ErrorFactory {
    static fromHTTPStatusCode({code, name, description}) {
        switch (code) {
        case 404:
            return new AvailabilityError({name, description});
        case 422:
            return new InputError({name, description});
        default:
            return new UnknownError({name, description});
        }
    }

    static fromDirectUploadError({error}) {
        const getStatusRegExp = /Status: (\d+)/;
        const getDescriptionRegExp = /(.+)\. Status/;

        const code = +error.match(getStatusRegExp)[1];
        const description = error.match(getDescriptionRegExp)[1];

        return this.fromHTTPStatusCode({code, description});
    }

    static fromFirebaseError(error) {
        const errors = {
            'auth/invalid-email': {Class: InputError, name: 'invalid_email'},
            'auth/user-disabled': {Class: AvailabilityError, name: 'user_disabled'},
            'auth/user-not-found': {Class: InputError, name: 'user_not_found'},
            'auth/wrong-password': {Class: InputError, name: 'invalid_password'},
            'auth/email-already-in-use': {Class: InputError, name: 'email_in_use'},
            'auth/network-request-failed': {Class: NetworkError, name: 'network_error'},
            other: {Class: UnknownError, name: 'unknown_error'},
        };
        const mappedError = errors[error.code] || errors.other;
        return new mappedError.Class({name: mappedError.name});
    }
}
