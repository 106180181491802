import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import MuiAvatar from '@mui/material/Avatar';

import {defaultStyle, avatarPalette} from './Avatar.styles';
import User from '../../entities/User';

const Avatar = ({
    icon,
    styles,
    user,
    useTooltip,
}) => {
    let upperCaseNameLetters;
    let avatarColorStyle;
    let tooltipText;

    if (user?.initials) {
        tooltipText = user.fullName;
        upperCaseNameLetters = user.initials;
        const colorIndex = upperCaseNameLetters.charCodeAt(0) % avatarPalette.length;
        avatarColorStyle = {
            bgcolor: avatarPalette[colorIndex],
        };
    }

    const avatarStyle = {
        ...defaultStyle,
        ...styles,
        ...avatarColorStyle,
    };

    const avatarComponent = (
        <MuiAvatar sx={avatarStyle}>
            {upperCaseNameLetters || icon}
        </MuiAvatar>
    );

    if (useTooltip && tooltipText) {
        return (
            <Tooltip title={tooltipText}>
                {avatarComponent}
            </Tooltip>
        );
    }

    return avatarComponent;
};

Avatar.propTypes = {
    icon: PropTypes.any,
    styles: PropTypes.any,
    user: PropTypes.instanceOf(User),
    useTooltip: PropTypes.bool,
};

Avatar.defaultProps = {
    icon: null,
    styles: {},
    user: null,
    useTooltip: false,
};

export default observer(Avatar);
