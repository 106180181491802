import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import MuiButtonBase from '@material-ui/core/ButtonBase';

const DivButton = ({
    onClick, children, id, className,
}) => (
    <MuiButtonBase
        className={className}
        onClick={onClick}
        data-testid={id}
        disableRipple
    >
        {children}
    </MuiButtonBase>
);

DivButton.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.number,
    className: PropTypes.string,
};

DivButton.defaultProps = {
    onClick: () => {},
    id: null,
    className: null,
};

export default observer(DivButton);
