import {makeAutoObservable} from 'mobx';
import {PROFILE_PATH} from '../routes/paths';

export default class SidebarPresenter {
    constructor({
        getCurrentUserFromStore,
        logOut,
        router,
    } = {}) {
        this._getCurrentUserFromStore = getCurrentUserFromStore;
        this._logOut = logOut;
        this._router = router;

        makeAutoObservable(this);
    }

    get currentUser() {
        return this._currentUser;
    }

    profileButtonWasClicked = () => {
        this._router.navigateTo(PROFILE_PATH);
    }

    logOutButtonWasClicked = () => {
        this._router.onLogOut();
        this._logOut.execute();
    }

    get _currentUser() {
        return this._getCurrentUserFromStore.execute();
    }
}
