import {useEffect, useState} from 'react';
import ProjectDetailPresenter from '../presenters/ProjectDetailPresenter';
import FetchEntityFromRemote from '../interactors/FetchEntityFromRemote';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import {useFirebase} from '../providers/FirebaseProvider';
import GetEntityFromStoreFactory from '../interactors/GetEntityFromStoreFactory';
import Api from '../services/Api';
import EntityServiceFactory from '../services/EntityServiceFactory';
import AuthService from '../services/AuthService';
import ApiResponseDeserializer from '../services/ApiResponseDeserializer';
import OAuthResponseDeserializer from '../services/OAuthResponseDeserializer';
import GetAssetsByIdFromStore from '../interactors/GetAssetsByIdFromStore';
import FetchProjectMembersFromRemote from '../interactors/FetchProjectMembersFromRemote';
import GetProjectMembersFromStore from '../interactors/GetProjectMembersFromStore';
import {useModalService} from '../providers/ModalProvider';
import GetCurrentUserFromStore from '../interactors/GetCurrentUserFromStore';
import GetEntitiesFromStore from '../interactors/GetEntitiesFromStore';
import AddProjectMember from '../interactors/AddProjectMember';
import RemoveProjectMember from '../interactors/RemoveProjectMember';
import ManageProject from '../interactors/ManageProject';
import LogOutUser from '../interactors/LogOutUser';
import {useToastService} from '../providers/ToastProvider';
import CreateMediaGroup from '../interactors/CreateMediaGroup';
import UpdateMediaGroup from '../interactors/UpdateMediaGroup';

const useProjectDetailWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();
    const modalService = useModalService();
    const toastService = useToastService();
    const firebase = useFirebase();

    useEffect(() => {
        const {
            authStore,
            projectStore,
            mediaGroupStore,
            assetStore,
            projectMemberStore,
            userStore,
        } = root;

        const authResponseDeserializerKlass = OAuthResponseDeserializer;
        const apiResponseDeserializerKlass = ApiResponseDeserializer;

        const authApi = new Api({
            authStore,
            responseDeserializerKlass: authResponseDeserializerKlass,
        });
        const api = new Api({
            authStore,
            responseDeserializerKlass: apiResponseDeserializerKlass,
        });

        const authService = new AuthService({api: authApi, auth: firebase.auth});

        const projectService = EntityServiceFactory.forProjects({api});

        const mediaGroupService = EntityServiceFactory.forMediaGroups({api});

        const assetService = EntityServiceFactory.forAssets({api});

        const getProjectFromStore = GetEntityFromStoreFactory.forProjects({
            store: projectStore,
        });

        const fetchProjectFromRemote = new FetchEntityFromRemote({
            service: projectService,
            store: projectStore,
        });

        const getMediaGroupFromStore = GetEntityFromStoreFactory.forMediaGroups({
            store: mediaGroupStore,
        });
        const fetchMediaGroupFromRemote = new FetchEntityFromRemote({
            service: mediaGroupService,
            store: mediaGroupStore,
        });
        const getAssetsFromStore = new GetAssetsByIdFromStore({
            store: assetStore,
        });
        const fetchAssetsFromRemote = new FetchEntityFromRemote({
            service: assetService,
            store: assetStore,
        });
        const getProjectMembersFromStore = new GetProjectMembersFromStore({
            projectMemberStore,
        });
        const fetchProjectMembersFromRemote = new FetchProjectMembersFromRemote({
            projectMemberStore,
            projectService,
        });

        const getCurrentUserFromStore = new GetCurrentUserFromStore({store: userStore});
        const getUsersFromStore = new GetEntitiesFromStore({store: userStore});
        const addProjectMember = new AddProjectMember({
            projectMemberStore,
            projectService,
        });
        const projectMemberService = EntityServiceFactory.forProjectMembers({api});
        const removeProjectMember = new RemoveProjectMember({
            projectMemberStore,
            projectMemberService,
        });

        const manageProject = new ManageProject({
            service: projectService,
            store: projectStore,
        });

        const logOut = new LogOutUser({
            authService,
            userStore,
        });

        const createMediaGroup = new CreateMediaGroup({
            service: mediaGroupService,
            projectStore,
            mediaGroupStore,
        });

        const updateMediaGroup = new UpdateMediaGroup({
            service: mediaGroupService,
            projectStore,
            mediaGroupStore,
        });

        const presenter = new ProjectDetailPresenter({
            router,
            getProjectFromStore,
            fetchProjectFromRemote,
            getMediaGroupFromStore,
            fetchMediaGroupFromRemote,
            getAssetsFromStore,
            fetchAssetsFromRemote,
            getProjectMembersFromStore,
            fetchProjectMembersFromRemote,
            modalService,
            getCurrentUserFromStore,
            getUsersFromStore,
            addProjectMember,
            removeProjectMember,
            toastService,
            manageProject,
            logOut,
            createMediaGroup,
            updateMediaGroup,
        });

        setPresenterInstance(presenter);
    }, [
        root,
        router,
        modalService,
        toastService,
        firebase,
    ]);

    return presenterInstance;
};

export default useProjectDetailWireframe;
