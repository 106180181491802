// eslint-disable jsx-a11y/click-events-have-key-events
import PropTypes from 'prop-types';

import styles from './MarkAsReadButton.module.scss';

function MarkAsReadButton({
    onClick,
}) {
    return (
        <div
            className={styles.button}
            onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();

                onClick();
            }}
            role={'button'}
        >
            <div className={styles.icon} />
            <span>
                Mark as seen
            </span>
        </div>
    );
}

MarkAsReadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default MarkAsReadButton;
