import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    videoDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
    },

    videoPlayer: {
        borderRadius: '15px',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));
