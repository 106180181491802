import EntityService from './EntityService';
import Asset from '../entities/Asset';

export default class MediaGroupService extends EntityService {
    async get(mediaGroupId) {
        return super.get(mediaGroupId);
    }

    async addAsset({mediaGroupId, assetId}) {
        const assetJSON = await this.api.post(`${this.getBasePath()}/${mediaGroupId}/add_asset`, {
            asset_id: assetId,
        });

        return Asset.fromJSON(assetJSON);
    }
}
