import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import PdfView from './components/PdfView';

import classes from './PdfDisplay.module.scss';
import PdfDisplayPresenter from '../../presenters/PdfDisplayPresenter';

const PdfDisplay = ({presenter}) => {
    const showPages = presenter.loaded && presenter.numberOfPages > 0;

    return (
        <div className={clsx(classes.root, !showPages && classes.rootWithoutPages)}>
            {showPages && (
                <div className={classes.pages}>
                    {`Page ${presenter.pageNumber} / ${presenter.numberOfPages}`}
                </div>
            )}
            <PdfView
                presenter={presenter}
            />
        </div>
    );
};

PdfDisplay.propTypes = {
    presenter: PropTypes.instanceOf(PdfDisplayPresenter).isRequired,
};

export default observer(PdfDisplay);
