import React from 'react';
import Firebase from '../services/Firebase';

const FirebaseContext = React.createContext(null);

export const FirebaseProvider = ({children}) => {
    const firebase = new Firebase();
    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    );
};

export const useFirebase = () => React.useContext(FirebaseContext);
