export default class UploadFile {
    constructor({authStore, uploader}) {
        this.authStore = authStore;
        this.uploader = uploader;
    }

    async execute(file, onProgress) {
        const {
            signed_id: signedId,
        } = await this.uploader.with({
            file,
            token: this.authStore.userToken,
            onProgress,
        });

        return signedId;
    }
}
