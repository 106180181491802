import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Avatar} from '../Avatar';
import {Highlight} from '../Highlight';

import classes from './Comment.module.scss';
import CommentPresenter from '../../presenters/CommentPresenter';

const Comment = ({presenter, isActive, onClick}) => {
    const textToHighlight = presenter.mentionsTexts.join('|');
    const textToHighlightRegex = textToHighlight === '' ? textToHighlight : new RegExp(textToHighlight, 'gi');
    const className = clsx(
        classes.comment,
        !presenter.isSelectedAssetVersionComment && classes.notCurrentVersion,
        isActive && classes.activeComment,
    );

    return (
        <div
            className={className}
            onClick={onClick}
            data-comment-id={presenter.commentId}
        >
            <div className={classes.comment__header}>
                <Avatar
                    user={presenter.user}
                />
                <span className={classes.comment__username}>{presenter.username}</span>
                <span className={classes.comment__date}>{presenter.dateToDisplay}</span>
            </div>
            <div className={classes.comment__text}>
                <Highlight
                    source={presenter.text}
                    target=""
                    targetRegex={textToHighlightRegex}
                    renderHighlight={(text) => (
                        <span className={classes.comment__mention}>{text}</span>
                    )}
                />
            </div>
        </div>
    );
};

Comment.propTypes = {
    presenter: PropTypes.instanceOf(CommentPresenter).isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

Comment.defaultProps = {
    onClick: () => {},
    isActive: false,
};

export default Comment;
