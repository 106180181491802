import {EMAIL, PASSWORD} from '../constants/formFields/logInForm';

const fields = [
    {
        name: EMAIL,
        label: 'Email Address',
        placeholder: 'Email',
        rules: 'required',
        type: 'email',
    },
    {
        name: PASSWORD,
        label: 'Password',
        placeholder: 'Password',
        rules: 'required',
        type: 'password',
    },
];

export default fields;
