import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: palette.lightBackground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
    },
}));
