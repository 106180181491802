export default class ManageProject {
    constructor({
        service,
        store,
    }) {
        this.service = service;
        this.store = store;
    }

    async create({name}) {
        const project = await this.service.create({name});
        this.store.prepend(project);

        return project;
    }

    async delete(projectId) {
        await this.service.delete(projectId);
        this.store.delete(projectId);
    }

    async update(projectId, data) {
        const project = await this.service.update(projectId, data);
        this._updateProjectInStore(projectId, project);
    }

    async createLink(projectId, link) {
        const createdLink = await this.service.createLink(projectId, link);

        const project = this.store.find(projectId);
        this._updateProjectInStore(projectId, {
            ...project,
            links: [
                ...project.links,
                createdLink,
            ],
        });
    }

    async updateLink(projectId, link) {
        const updatedLink = await this.service.updateLink(projectId, link);

        const project = this.store.find(projectId);
        this._updateProjectInStore(projectId, {
            ...project,
            links: project.links.map((projectLink) => {
                if (projectLink.id === link.id) {
                    return updatedLink;
                }

                return projectLink;
            }),
        });
    }

    async deleteLink(projectId, linkId) {
        await this.service.deleteLink(projectId, linkId);

        const project = this.store.find(projectId);
        this._updateProjectInStore(projectId, {
            ...project,
            links: project.links.filter(({id}) => id !== linkId),
        });
    }

    _updateProjectInStore(projectId, project) {
        this.store.update(projectId, project);
    }
}
