import {makeAutoObservable} from 'mobx';
import Project from './Project';
import User from './User';

export default class ProjectMember {
    constructor({
        id, project, user,
    }) {
        this.id = id;
        this.project = project;
        this.user = user;

        makeAutoObservable(this);
    }

    toJSON() {
        return {
            id: this.id,
            project: this.project.toJSON(),
            user: this.user.toJSON(),
        };
    }

    static fromJSON(data) {
        const project = data.project && Project.fromJSON(data.project);
        const user = data.user && User.fromJSON(data.user);

        return new ProjectMember({
            id: data.id,
            project,
            user,
        });
    }

    get projectId() {
        return this.project.id;
    }
}
