import {makeAutoObservable} from 'mobx';

export default class VideoDisplayPresenter {
    constructor({fileUrl}) {
        this.fileUrl = fileUrl;

        makeAutoObservable(this);
    }

    get type() {
        return 'video';
    }
}
