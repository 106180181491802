export default class FetchProjectMembersFromRemote {
    constructor({projectMemberStore, projectService}) {
        this.projectMemberStore = projectMemberStore;
        this.projectService = projectService;
    }

    async execute(projectId) {
        const projectMembers = await this._fetchFromService(projectId);
        this._putInStore(projectMembers);
        return projectMembers;
    }

    async _fetchFromService(projectId) {
        return this.projectService.getMembers(projectId);
    }

    _putInStore(projectMembers) {
        this.projectMemberStore.updateList(projectMembers);
    }
}
