import PropTypes from 'prop-types';

import {ButtonMenu} from '../ButtonMenu';
import {Avatar} from '../Avatar';
import LogOutIcon from '../Icons/LogOutIcon';
import PersonIcon from '../Icons/PersonIcon';

import {useStyles} from './Sidebar.styles';
import SidebarPresenter from '../../presenters/SidebarPresenter';

const Sidebar = ({presenter}) => {
    const classes = useStyles();

    const icon = (
        <Avatar
            user={presenter.currentUser}
        />
    );

    const options = [
        {
            key: 'profile',
            label: (
                <div className={classes.logOutLabel}>
                    <PersonIcon />
                    <p>Account Settings</p>
                </div>
            ),
            onClick: presenter.profileButtonWasClicked,
        },
        {
            key: 'logout',
            label: (
                <div className={classes.logOutLabel}>
                    <LogOutIcon />
                    <p>Log out</p>
                </div>
            ),
            onClick: presenter.logOutButtonWasClicked,
        },
    ];

    return (
        <div className={classes.sidebar}>
            <div />
            <div>
                <ButtonMenu
                    icon={icon}
                    options={options}
                />
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    presenter: PropTypes.instanceOf(SidebarPresenter).isRequired,
};

export default Sidebar;
