import Api from './Api';
import {toSnakeCase} from '../helpers/switchCase';

export default class EntityService {
    constructor({
        api = new Api(),
        entityName,
        basePath,
        buildEntity,
    } = {}) {
        this.api = api;
        this.entityName = entityName;
        this.basePath = basePath;
        this.buildEntity = buildEntity;
        this.apiVersion = '1';
    }

    getSnakeCaseEntityName() {
        return toSnakeCase(this.entityName);
    }

    getSnakeCaseParams(params) {
        return toSnakeCase(params);
    }

    getBasePath() {
        return `${this.apiVersion}/${this.basePath}`;
    }

    create = async (params) => {
        const entityJSON = await this.api.post(this.getBasePath(), {
            [this.getSnakeCaseEntityName()]: this.getSnakeCaseParams(params),
        });

        return this.buildEntity(entityJSON.response || entityJSON);
    }

    async get(id) {
        const entityJSON = await this.api.get(`${this.getBasePath()}/${id}`);

        return this.buildEntity(entityJSON);
    }

    getAll = async () => {
        const entitiesJSON = await this.api.get(this.getBasePath());
        return entitiesJSON.map((entityJSON) => this.buildEntity(entityJSON));
    }

    update = async (id, params) => {
        const entityJSON = await this.api.patch(`${this.getBasePath()}/${id}`, {
            [this.getSnakeCaseEntityName()]: this.getSnakeCaseParams(params),
        });

        return this.buildEntity(entityJSON.response || entityJSON);
    }

    delete = async (id) => {
        const entityJSON = await this.api.delete(`${this.getBasePath()}/${id}`);

        return this.buildEntity(entityJSON);
    }
}
