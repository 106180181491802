import ErrorFactory from '../factories/ErrorFactory';

export default class AuthService {
    constructor({
        api,
        auth,
    }) {
        this.auth = auth;
        this.api = api;
    }

    async logInWithFirebase({email, password}) {
        try {
            await this.auth().signInWithEmailAndPassword(email, password);
            return this.auth().currentUser.getIdToken(true);
        } catch (error) {
            throw ErrorFactory.fromFirebaseError(error);
        }
    }

    async logIn(firebaseIdToken) {
        await this.api.logIn(firebaseIdToken);
    }

    logOut() {
        this.api.logOut();
    }

    async signUp({email, password}) {
        const firebaseIdToken = await this.signUpWithFirebase({email, password});
        await this.logIn(firebaseIdToken);
    }

    async signUpWithFirebase({email, password}) {
        await this.auth().createUserWithEmailAndPassword(email, password);
        return this.auth().currentUser.getIdToken(true);
    }

    async sendPasswordResetEmail(email) {
        try {
            await this.auth().sendPasswordResetEmail(email);
        } catch (ex) {
            throw ErrorFactory.fromFirebaseError(ex);
        }
    }
}
