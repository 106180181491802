import {observer} from 'mobx-react-lite';
import propTypes from 'prop-types';
import InputText from '../InputText/InputText';
import InputTextLabel from '../InputTextLabel/InputTextLabel';
import noop from '../../helpers/noop';

const InputTextWithLabel = ({
    id, value, onChange,
    onBlur, multiline, errorMessage, startAdornment,
    innerRef, type, label,
    placeholder,
    autoFocus,
}) => (
    <>
        <InputTextLabel text={label} />
        <InputText
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            multiline={multiline}
            errorMessage={errorMessage}
            startAdornment={startAdornment}
            innerRef={innerRef}
            type={type}
            placeholder={placeholder}
            autoFocus={autoFocus}
        />
    </>
);

InputTextWithLabel.propTypes = {
    id: propTypes.string.isRequired,
    errorMessage: propTypes.string,
    value: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
    onBlur: propTypes.func,
    multiline: propTypes.bool,
    startAdornment: propTypes.node,
    innerRef: propTypes.oneOfType([
        propTypes.func,
        propTypes.shape({current: propTypes.elementType}),
    ]),
    type: propTypes.string,
    label: propTypes.any,
    placeholder: propTypes.string,
    autoFocus: propTypes.bool,
};

InputTextWithLabel.defaultProps = {
    errorMessage: '',
    multiline: false,
    startAdornment: null,
    onBlur: noop,
    innerRef: null,
    type: 'text',
    label: 'text',
    placeholder: '',
    autoFocus: false,
};

export default observer(InputTextWithLabel);
