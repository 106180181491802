import {makeAutoObservable} from 'mobx';
import FileValidator from '../structures/FileValidator';
import {
    FILE_UPLOAD_STATE,
    FILE_UPLOAD_STATUS,
} from '../constants/fileUpload';

export default class FileUploadPresenter {
    constructor({
        uploadFile,
        confirmUpload,
        createAsset,
        createAssetVersion,
        maxFiles,
    } = {}) {
        this.cancelled = false;

        this.files = new Map(); // filename => {file, state, status, payload}

        this.uploadFile = uploadFile;
        this._confirmUpload = confirmUpload;
        this.createAsset = createAsset;
        this.createAssetVersion = createAssetVersion;
        this.maxFiles = maxFiles;

        this.acceptedFileTypes = {
            'video/mp4': [
                '.mp4',
            ],
            'audio/mpeg': [
                '.mp3',
            ],
            'image/png': [
                '.png',
            ],
            'image/jpeg': [
                '.jpg',
                '.jpeg',
            ],
            'application/pdf': [
                '.pdf',
                '.ai',
            ],
            'text/csv': [
                '.csv',
            ],
        };

        this.isPosting = false;

        makeAutoObservable(this);
    }

    get acceptedFiles() {
        return this.acceptedFileTypes;
    }

    get validator() {
        return (file) => FileValidator.validate(file);
    }

    get hasFilesToUpload() {
        return this.filesArray.length > 0;
    }

    get filesArray() {
        return [...this.files]
            .sort((a, b) => a[0].localeCompare(b[0]))
            .filter(([, {file}]) => Boolean(file))
            .map(([, file]) => file);
    }

    addFiles = (files) => {
        this.cancelled = false;
        files.forEach((file) => {
            if (!this.files.get(file.name)) {
                this._updateFile(file.name, {
                    file,
                    state: FILE_UPLOAD_STATE.IDLE,
                });
            }
        });
    }

    clearFiles() {
        this.isPosting = false;
        this.cancelled = false;
        this.files = new Map();
    }

    cancel() {
        this.clearFiles();
        this.cancelled = true;
    }

    get isConfirmUploadButtonActive() {
        return !this.isPosting
            && this.filesArray.length > 0
            && this.filesArray.every((file) => file.state === FILE_UPLOAD_STATE.DONE);
    }

    confirmUpload = async () => {
        this.isPosting = true;
        await this._confirmUpload();
        this.isPosting = false;
    }

    async uploadAssets() {
        const createdAssets = await Promise.all(
            this.filesArray
                // prevent re-upload if new files added
                .filter(({state}) => state === FILE_UPLOAD_STATE.IDLE)
                .map(async ({file}) => {
                    if (this.cancelled) {
                        return null;
                    }

                    try {
                        this._updateFile(file.name, {
                            state: FILE_UPLOAD_STATE.PROGRESS,
                            payload: 0,
                        });

                        const signedId = await this._uploadFile(file);
                        const asset = await this.createAsset.execute({
                            signedId,
                            // filename in asset
                            description: file.name
                                .split('.')
                                .slice(0, -1)
                                .join('.'),
                        });

                        this._updateFile(file.name, {
                            assetId: asset.id,
                            state: FILE_UPLOAD_STATE.DONE,
                            status: FILE_UPLOAD_STATUS.SUCCESS,
                        });

                        return asset;
                    } catch (ex) {
                        this._updateFile(file.name, {
                            state: FILE_UPLOAD_STATE.DONE,
                            status: FILE_UPLOAD_STATUS.FAIL,
                            payload: ex,
                        });

                        return null;
                    }
                }),
        );

        return createdAssets.filter(Boolean);
    }

    async uploadAssetVersion(assetId) {
        const {
            file,
        } = this.filesArray[0];

        try {
            this._updateFile(file.name, {
                state: FILE_UPLOAD_STATE.PROGRESS,
                payload: 0,
            });

            const signedId = await this._uploadFile(file);
            const assetVersion = await this.createAssetVersion.execute({
                assetId,
                file: signedId,
            });

            this._updateFile(file.name, {
                state: FILE_UPLOAD_STATE.DONE,
                status: FILE_UPLOAD_STATUS.SUCCESS,
            });

            return assetVersion;
        } catch (ex) {
            this._updateFile(file.name, {
                state: FILE_UPLOAD_STATE.DONE,
                status: FILE_UPLOAD_STATUS.FAIL,
                payload: ex,
            });

            return null;
        }
    }

    deleteFile = (filename) => {
        this.files.delete(filename);
    }

    _updateFile(filename, data) {
        if (this.cancelled) {
            return;
        }

        const prevFile = this.files.get(filename);
        this.files.set(filename, {
            ...prevFile,
            ...data,
        });
    }

    _uploadFile = (file) => this.uploadFile.execute(file, (progress) => {
        this._updateFile(file.name, {
            state: FILE_UPLOAD_STATE.PROGRESS,
            payload: Math.min(
                99,
                Math.floor((progress.loaded / progress.total) * 100),
            ),
        });
    })
}
