import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    mentionSection: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    empty: {
        color: '#728391',
        fontSize: '14px',
    },

    kebab: {
        marginTop: '-5px',
        marginRight: '-8px',
    },
}));
