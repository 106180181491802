import {makeAutoObservable} from 'mobx';

export default class PdfDisplayPresenter {
    constructor({fileUrl}) {
        this.fileUrl = fileUrl;
        this.numberOfPages = 0;
        this.pageNumber = 1;
        this.loaded = false;

        makeAutoObservable(this);
    }

    get type() {
        return 'pdf';
    }

    get file() {
        return {url: this.fileUrl};
    }

    setPageNumber = (pageNumber) => {
        this.pageNumber = pageNumber;
    }

    onDocumentLoadSuccess = ({numPages}) => {
        this.loaded = true;
        this.numberOfPages = numPages;
    }
}
