import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const Select = ({
    options,
    getOptionLabel,
    getOptionValue,
    ...props
}) => (
    <ReactSelect
        options={options}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        {...props}
    />
);

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any),
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
    options: [],
    getOptionLabel: (option) => option,
    getOptionValue: (option) => option,
};

export default Select;
