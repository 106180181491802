import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    mentionContainer: {
        width: '100%',
        height: 'auto',
        backgroundColor: palette.dashboardCardBackgroundPrimary,
        boxSizing: 'border-box',
        padding: '13px 16px 8px 24px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderRadius: '6px',
        position: 'relative',

        ...fonts.smallTitle1,

        '&:hover': {
            zIndex: 10,
            boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 12px 0 ${palette.lightBackground}`,
        },

        '&:hover .mark-as-read': {
            visibility: 'visible',
        },
    },

    mention: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: '6px',
    },

    mentionInfo: {
        '& span': {
            ...fonts.altSmallTitle,

            fontSize: '14px',
            fontWeight: 'normal',
            color: palette.mentionSecondaryText,
        },
    },

    mentionContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...fonts.altSmallTitle,

        color: palette.textDark,
        fontSize: '18px',

        '& p': {
            display: 'inline',
            fontWeight: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
            margin: '0 0 0 5px',
        },
    },

    mentionUsername: {
        fontWeight: '600',
        whiteSpace: 'nowrap',
    },

    mentionText: {

    },

    highlightedUsername: {
        color: '#5ea7e0',
    },

    mentionNotification: {
        backgroundColor: '#fd5b81',
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        position: 'absolute',
        top: '21px',
        left: '11px',
    },

    markAsRead: {
        visibility: 'hidden',
        position: 'absolute',
        top: '17px',
        right: '16px',
    },
}));
