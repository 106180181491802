import {makeAutoObservable} from 'mobx';
/* eslint-disable-next-line import/no-cycle */
import Asset from './Asset';

export default class AssetVersion {
    constructor({
        id,
        number,
        fileUrl,
        fileExtension,
        filename,
        thumbnailUrl,
        createdAt,
        asset,
    }) {
        this.id = id;
        this.number = number;
        this.fileUrl = fileUrl;
        this.fileExtension = fileExtension;
        this.filename = filename;
        this.thumbnailUrl = thumbnailUrl;
        this.createdAt = createdAt;
        this.asset = asset;

        makeAutoObservable(this);
    }

    toJSON() {
        const asset = this.asset && this.asset.toJSON();

        return {
            id: this.id,
            number: this.number,
            file_url: this.fileUrl,
            file_extension: this.fileExtension,
            filename: this.filename,
            thumbnail_url: this.thumbnailUrl,
            created_at: this.createdAt,
            asset,
        };
    }

    static fromJSON(data) {
        const asset = data.asset && Asset.fromJSON(data.asset);

        return new AssetVersion({
            id: data.id,
            number: data.number,
            fileUrl: data.file_url,
            fileExtension: data.file_extension,
            filename: data.filename,
            thumbnailUrl: data.thumbnail_url,
            createdAt: new Date(data.created_at),
            asset,
        });
    }
}
