import {makeAutoObservable} from 'mobx';
import {NEW_MEDIA_GROUP_PATH, DASHBOARD_PATH} from '../routes/paths';
import MediaGroupThumbnailPresenter from './MediaGroupThumbnailPresenter';
import {MANAGE_FOLDER_MODAL, EDIT_FIELDS_MODAL} from '../constants/modals';
import Form from '../forms/Form';
import updateBriefFields from '../forms/updateProjectBriefFields';
import {BRIEF} from '../constants/formFields/updateProjectBriefForm';
import updateLinksFields from '../forms/updateProjectLinksFields';
import {LINK_ADDRESS, LINK_ID, LINK_TITLE} from '../constants/formFields/updateProjectLinksForm';
import updateTimelineFields from '../forms/updateProjectTimelineFields';
import {TIMELINE} from '../constants/formFields/updateProjectTimelineForm';
import ProjectTeamPresenter from './ProjectTeamPresenter';
import SidebarPresenter from './SidebarPresenter';
import manageFolderFields, {
    FOLDER_NAME,
} from '../forms/manageFolderFields';

export default class ProjectDetailPresenter {
    constructor({
        router,
        getProjectFromStore,
        fetchProjectFromRemote,
        getMediaGroupFromStore,
        fetchMediaGroupFromRemote,
        getAssetsFromStore,
        fetchAssetsFromRemote,
        getProjectMembersFromStore,
        fetchProjectMembersFromRemote,
        modalService,
        getCurrentUserFromStore,
        getUsersFromStore,
        addProjectMember,
        removeProjectMember,
        toastService,
        manageProject,
        logOut,
        createMediaGroup,
        updateMediaGroup,
    } = {}) {
        this.router = router;
        this.getProjectFromStore = getProjectFromStore;
        this.fetchProjectFromRemote = fetchProjectFromRemote;
        this.getMediaGroupFromStore = getMediaGroupFromStore;
        this.fetchMediaGroupFromRemote = fetchMediaGroupFromRemote;
        this.getAssetsFromStore = getAssetsFromStore;
        this.fetchAssetsFromRemote = fetchAssetsFromRemote;
        this.getProjectMembersFromStore = getProjectMembersFromStore;
        this.fetchProjectMembersFromRemote = fetchProjectMembersFromRemote;
        this.modalService = modalService;
        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.getUsersFromStore = getUsersFromStore;
        this.addProjectMember = addProjectMember;
        this.removeProjectMember = removeProjectMember;
        this.toastService = toastService;
        this.manageProject = manageProject;
        this.logOut = logOut;
        this.createMediaGroup = createMediaGroup;
        this.updateMediaGroup = updateMediaGroup;

        this._initializeForms();

        makeAutoObservable(this);

        this._fetchProject();
    }

    updateBriefOnSubmitSuccess = async () => {
        const {
            brief,
        } = this.updateBriefForm.values();

        await this.manageProject.update(this.project.id, {
            brief,
        });

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
    };

    createLinkOnSubmitSuccess = async () => {
        const link = this.createLinkForm.values();
        await this.manageProject.createLink(this.project.id, link);

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
        this.toastService.notifySuccess('Link was successfully created');
    };

    updateLinkOnSubmitSuccess = async () => {
        const link = this.updateLinkForm.values();
        await this.manageProject.updateLink(this.projectId, link);

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
        this.toastService.notifySuccess('Link was successfully updated');
    };

    updateTimelineOnSubmitSuccess = async () => {
        const {
            timeline,
        } = this.updateTimelineForm.values();

        await this.manageProject.update(this.project.id, {
            timeline,
        });

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
    };

    createFolderSuccess = async () => {
        const {
            name,
        } = this.createFolderForm.values();

        await this.createMediaGroup.execute({
            name,
            projectId: this.projectId,
        });

        this.modalService.closeModal(MANAGE_FOLDER_MODAL);
    };

    getProjectIdFromRouter() {
        return this.router.resourceIds[0];
    }

    get projectTeamSectionPresenter() {
        return new ProjectTeamPresenter({
            project: this.project,
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            getProjectMembersFromStore: this.getProjectMembersFromStore,
            fetchProjectMembersFromRemote: this.fetchProjectMembersFromRemote,
            modalService: this.modalService,
            getUsersFromStore: this.getUsersFromStore,
            addProjectMember: this.addProjectMember,
            removeProjectMember: this.removeProjectMember,
        });
    }

    get currentUser() {
        return this.getCurrentUserFromStore.execute();
    }

    get projectOwner() {
        return this.project?.owner;
    }

    get isCurrentUserTheOwner() {
        if (this.projectOwner && this.currentUser) {
            return this.currentUser.id === this.projectOwner.id;
        }

        return false;
    }

    get project() {
        return this.getProjectFromStore.execute(this.getProjectIdFromRouter());
    }

    get projectName() {
        return this.project?.name;
    }

    get projectId() {
        return this.project?.id;
    }

    get projectBrief() {
        return this.project?.brief || '';
    }

    get projectLinks() {
        return this.project?.links || [];
    }

    get projectTimeline() {
        return this.project?.timeline || '';
    }

    get mediaGroups() {
        return (this.project?.mediaGroups || [])
            .filter(({name}) => name !== 'Default Folder');
    }

    get mediaGroupThumbnailPresenters() {
        return this.mediaGroups.map(this._createMediaGroupThumbnailPresenter);
    }

    get breadcrumbs() {
        return [
            this._breadcrumb('Dashboard', DASHBOARD_PATH),
            this._breadcrumb(this.projectName),
        ];
    }

    get sidebarPresenter() {
        return new SidebarPresenter({
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            logOut: this.logOut,
            router: this.router,
        });
    }

    _breadcrumb(text, url) {
        return {
            text,
            url,
        };
    }

    async _fetchProject() {
        try {
            await this.fetchProjectFromRemote.execute(this.getProjectIdFromRouter());
        } catch (error) {
            this.toastService.notifyError(error.message);
            this._redirectToDashboardPath();
        }
    }

    newMediaGroupButtonWasClicked = () => {
        this._redirectToNewMediaGroupPath();
    }

    createFolderButtonWasClicked = () => {
        this.createFolderForm.clear();
        this.createFolderForm.$(FOLDER_NAME).focus();

        this.modalService.openModal(MANAGE_FOLDER_MODAL, {
            title: 'Create folder',
            inputs: {
                [FOLDER_NAME]: {
                    multiline: false,
                    control: this.createFolderForm.$(FOLDER_NAME),
                },
            },
            onConfirm: this.createFolderForm.onSubmit,
            submitButton: 'Create',
        });
    }

    editBriefButtonWasClicked = () => {
        this.updateBriefForm.$(BRIEF).set(this.projectBrief);
        this.updateBriefForm.$(BRIEF).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: this.projectBrief
                ? 'Edit brief'
                : 'Add brief',
            inputs: {
                [BRIEF]: {
                    multiline: true,
                    control: this.updateBriefForm.$(BRIEF),
                },
            },
            onConfirm: this.updateBriefForm.onSubmit,
        });
    }

    addLinkButtonWasClicked = () => {
        this.createLinkForm.clear();
        this.createLinkForm.$(LINK_TITLE).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: 'Add a link',
            inputs: {
                [LINK_TITLE]: {
                    control: this.createLinkForm.$(LINK_TITLE),
                },
                [LINK_ADDRESS]: {
                    control: this.createLinkForm.$(LINK_ADDRESS),
                },
            },
            submitButton: 'Add',
            onConfirm: this.createLinkForm.onSubmit,
        });
    }

    editLinkButtonWasClicked = (id) => {
        const {
            address,
            title,
        } = this.projectLinks.find((link) => link.id === id);

        this.updateLinkForm.$(LINK_ID).set(id);
        this.updateLinkForm.$(LINK_TITLE).set(title);
        this.updateLinkForm.$(LINK_TITLE).focus();
        this.updateLinkForm.$(LINK_ADDRESS).set(address);

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: 'Edit link',
            inputs: {
                [LINK_TITLE]: {
                    control: this.updateLinkForm.$(LINK_TITLE),
                },
                [LINK_ADDRESS]: {
                    control: this.updateLinkForm.$(LINK_ADDRESS),
                },
            },
            onConfirm: this.updateLinkForm.onSubmit,
        });
    }

    deleteLinkButtonWasClicked = async (id) => {
        await this.manageProject.deleteLink(this.project.id, id);
        this.toastService.notifySuccess('Link was successfully deleted');
    }

    editTimelineButtonWasClicked = () => {
        this.updateTimelineForm.$(TIMELINE).set(this.projectTimeline);
        this.updateTimelineForm.$(TIMELINE).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: this.projectTimeline
                ? 'Edit timeline'
                : 'Add timeline',
            inputs: {
                [TIMELINE]: {
                    multiline: true,
                    control: this.updateTimelineForm.$(TIMELINE),
                },
            },
            onConfirm: this.updateTimelineForm.onSubmit,
        });
    }

    _redirectToNewMediaGroupPath = () => {
        const projectId = this.getProjectIdFromRouter();
        const newMediaGroupPath = NEW_MEDIA_GROUP_PATH.replace(':id', projectId);

        this.router.navigateTo(newMediaGroupPath);
    };

    _createMediaGroupThumbnailPresenter = (mediaGroup) => (
        new MediaGroupThumbnailPresenter({
            getMediaGroupFromStore: this.getMediaGroupFromStore,
            fetchMediaGroupFromRemote: this.fetchMediaGroupFromRemote,
            getAssetsFromStore: this.getAssetsFromStore,
            fetchAssetsFromRemote: this.fetchAssetsFromRemote,
            mediaGroup,
            updateMediaGroup: this.updateMediaGroup,
            modalService: this.modalService,
        })
    )

    _redirectToDashboardPath = () => {
        this.router.navigateTo(DASHBOARD_PATH);
    };

    _initializeForms() {
        this.updateBriefForm = new Form({
            fields: updateBriefFields,
        }, {
            hooks: {
                onSuccess: this.updateBriefOnSubmitSuccess,
            },
        });

        this.createLinkForm = new Form({
            fields: updateLinksFields,
        }, {
            hooks: {
                onSuccess: this.createLinkOnSubmitSuccess,
            },
        });

        this.updateLinkForm = new Form({
            fields: updateLinksFields,
        }, {
            hooks: {
                onSuccess: this.updateLinkOnSubmitSuccess,
            },
        });

        this.updateTimelineForm = new Form({
            fields: updateTimelineFields,
        }, {
            hooks: {
                onSuccess: this.updateTimelineOnSubmitSuccess,
            },
        });

        this.createFolderForm = new Form({
            fields: manageFolderFields,
        }, {
            hooks: {
                onSuccess: this.createFolderSuccess,
            },
        });
    }
}
