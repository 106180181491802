import {makeAutoObservable} from 'mobx';

export default class TextDisplayPresenter {
    constructor({fileUrl, fetchFileFromRemote}) {
        this.fileUrl = fileUrl;
        this.fetchFileFromRemote = fetchFileFromRemote;
        this.text = '';

        makeAutoObservable(this);
        this._initialize();
    }

    get type() {
        return 'text';
    }

    async _initialize() {
        const fileWithUrl = await this._fetchFileFromRemote();
        await this._readFileContent(fileWithUrl);
    }

    _fetchFileFromRemote() {
        return this.fetchFileFromRemote.execute(this.fileUrl);
    }

    async _readFileContent(fileWithUrl) {
        const text = await fileWithUrl.file.text();
        this._storeText(text);
    }

    _storeText(text) {
        this.text = text;
    }
}
