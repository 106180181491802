import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {useStyles} from '../ActivityList.styles';
import MarkAsReadButton from '../../MarkAsReadButton/MarkAsReadButton';
import ActivityPresenter from '../../../presenters/ActivityPresenter';

const Activity = ({presenter}) => {
    const classes = useStyles();

    const isUnseen = !presenter.isSeen;
    const markAsSeen = () => presenter.markOneAsSeen(presenter.activityId);
    const mentionOnClick = isUnseen ? markAsSeen : () => {};

    return (
        <Link
            className={classes.activity}
            to={presenter.assetUrl}
            onClick={mentionOnClick}
        >
            {isUnseen && (
                <div
                    className={classes.activityNotification}
                    data-testid="mention-notification"
                />
            )}
            <div
                className={classes.activityContent}
                title={`${presenter.projectName} / ${presenter.mediaGroupName}
${presenter.assetName} / ${presenter.assetVersionNumberToDisplay}`}
            >
                {isUnseen && (
                    <div className={clsx(classes.markAsSeen, 'mark-as-seen')}>
                        <MarkAsReadButton
                            onClick={markAsSeen}
                        />
                    </div>
                )}
                <div className={classes.activityContentTop}>
                    <div className={classes.activityProjectAndPost}>
                        <p>
                            {presenter.projectName}
                        </p>
                        <span>/</span>
                        <p>
                            {presenter.mediaGroupName}
                        </p>
                    </div>
                    <p>
                        {presenter.dateToDisplay}
                    </p>
                </div>
                <div className={classes.activityContentBottom}>
                    <p>
                        {presenter.assetName}
                    </p>
                    <span>/</span>
                    <p>
                        {presenter.assetVersionNumberToDisplay}
                    </p>
                </div>
            </div>
        </Link>
    );
};

Activity.propTypes = {
    presenter: PropTypes.instanceOf(ActivityPresenter).isRequired,
};

Activity.defaultProps = {
};

export default observer(Activity);
