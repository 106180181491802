import {useEffect, useState} from 'react';
import SignUpPresenter from '../presenters/SignUpPresenter';
import SignUp from '../interactors/SignUp';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import {useFirebase} from '../providers/FirebaseProvider';
import Api from '../services/Api';
import AuthService from '../services/AuthService';
import ApiResponseDeserializer from '../services/ApiResponseDeserializer';
import OAuthResponseDeserializer from '../services/OAuthResponseDeserializer';
import CurrentUser from '../interactors/CurrentUser';
import FetchEntitiesFromRemote from '../interactors/FetchEntitiesFromRemote';
import EntityServiceFactory from '../services/EntityServiceFactory';

const useSignUpWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();
    const firebase = useFirebase();

    useEffect(() => {
        const {authStore, userStore} = root;

        const authResponseDeserializerKlass = OAuthResponseDeserializer;
        const apiResponseDeserializerKlass = ApiResponseDeserializer;

        const authApi = new Api({
            authStore,
            responseDeserializerKlass: authResponseDeserializerKlass,
        });
        const api = new Api({
            authStore,
            responseDeserializerKlass: apiResponseDeserializerKlass,
        });

        const authService = new AuthService({api: authApi, auth: firebase.auth});

        const signUp = new SignUp({authService});

        const userService = EntityServiceFactory.forUsers({api});
        const currentUser = new CurrentUser({
            userStore, userService,
        });

        const fetchAllUsers = new FetchEntitiesFromRemote({
            store: userStore,
            service: userService,
        });

        const presenter = new SignUpPresenter({
            signUp,
            authStore,
            router,
            currentUser,
            fetchAllUsers,
        });

        setPresenterInstance(presenter);
    }, [root, router, firebase]);

    return presenterInstance;
};

export default useSignUpWireframe;
