export const FILE_UPLOAD_STATE = {
    IDLE: 0,
    PROGRESS: 1,
    DONE: 2,
};

export const FILE_UPLOAD_STATUS = {
    SUCCESS: 0,
    FAIL: 1,
};
