import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    addMemberForm: {
        marginBottom: '20px',
    },

    userOption: {
        backgroundColor: palette.secondary,
        color: palette.textDark,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        paddingLeft: '10px',

        '&:hover': {
            backgroundColor: `${palette.primary}20`,
        },

        '&:checked': {
            backgroundColor: `${palette.primary}`,
        },

        '& p': {
            marginLeft: '20px',
        },
    },
}));

export const selectStyles = {
    control: (styles) => ({
        ...styles,
        border: `solid 1px ${palette.darkBackground}`,
        borderRadius: '6px',
        display: 'flex',
        padding: '2px',
        cursor: 'pointer',
        ...fonts.smallTitle2,

        '&:hover': {
            boxShadow: `0 2px 8px 0 ${palette.darkBackground}20`,
        },

        '&:active': {
            borderColor: palette.primary,
            boxShadow: `0 2px 8px 0 ${palette.darkBackground}20`,
        },
    }),

};
