/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import Button from '../Button/Button';
import noop from '../../helpers/noop';
import {useStyles} from './WhiteButton.styles';

const WhiteButton = ({
    onClick,
    children,
    id,
    startIcon,
    disabled,
}) => {
    const classes = useStyles();

    return (
        <Button
            className={classes.root}
            onClick={onClick}
            id={id}
            startIcon={startIcon}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

WhiteButton.propTypes = {
    onClick: propTypes.func,
};

WhiteButton.defaultProps = {
    onClick: noop,
};

export default WhiteButton;
