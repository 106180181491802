import PropTypes from 'prop-types';

import styles from './ImageDisplay.module.scss';

const ImageDisplay = ({presenter}) => (
    <img
        className={styles.ImageDisplay}
        src={presenter.imageUrl}
        alt="display"
    />
);

ImageDisplay.propTypes = {
    presenter: PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
    }).isRequired,
};

ImageDisplay.defaultProps = {
};

export default ImageDisplay;
