import {makeAutoObservable} from 'mobx';
import MentionPresenter from './MentionPresenter';
import ActivityPresenter from './ActivityPresenter';
import SidebarPresenter from './SidebarPresenter';
import AssetThumbnailPresenter from './AssetThumbnailPresenter';
import NumberToDisplay from './ToDisplay/NumberToDisplay';
import Asset from '../entities/Asset';
import AssetVersion from '../entities/AssetVersion';
import Form from '../forms/Form';
import manageProjectFields, {
    PROJECT_NAME,
} from '../forms/manageProjectFields';
import {CONFIRMATION_MODAL, CREATE_PROJECT_MODAL, MANAGE_FOLDER_MODAL} from '../constants/modals';
import {PROJECT_DETAIL_PATH} from '../routes/paths';

export default class ProjectsPresenter {
    constructor({
        router,
        manageProject,
        getProjectsFromStore,
        getMentionsFromStore,
        fetchProjectsFromRemote,
        fetchMentionsFromRemote,
        getCurrentUserFromStore,
        getUserFromStore,
        markMentionsAsSeen,
        markActivitiesAsSeen,
        getActivitiesFromStore,
        fetchActivitiesFromRemote,
        getAssetVersionFromStore,
        fetchAssetVersionsFromRemote,
        logOut,
        modalService,
    } = {}) {
        this.router = router;
        this.manageProject = manageProject;
        this.getProjectsFromStore = getProjectsFromStore;
        this.getMentionsFromStore = getMentionsFromStore;
        this.fetchProjectsFromRemote = fetchProjectsFromRemote;
        this.fetchMentionsFromRemote = fetchMentionsFromRemote;
        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.getUserFromStore = getUserFromStore;
        this.markMentionsAsSeen = markMentionsAsSeen;
        this.markActivitiesAsSeen = markActivitiesAsSeen;
        this.getActivitiesFromStore = getActivitiesFromStore;
        this.fetchActivitiesFromRemote = fetchActivitiesFromRemote;
        this.fetchAssetVersionsFromRemote = fetchAssetVersionsFromRemote;
        this.getAssetVersionFromStore = getAssetVersionFromStore;
        this.logOut = logOut;
        this.modalService = modalService;

        makeAutoObservable(this);

        this._fetchProjects();
        this._fetchMentions();
        this._fetchActivities();
    }

    get projects() {
        return this.getProjectsFromStore.execute();
    }

    get mentions() {
        return this.getMentionsFromStore.execute();
    }

    get activities() {
        return this.getActivitiesFromStore.execute();
    }

    get numberOfUnseenMentionsToDisplay() {
        const number = this.numberOfUnseenMentions;
        return new NumberToDisplay({number}).displayLowercase;
    }

    get numberOfProjectsToDisplay() {
        const number = this.numberOfProjects;
        return new NumberToDisplay({number}).displayCapitalized;
    }

    get numberOfUnseenMentions() {
        return this.mentionPresenters.filter(
            (mentionPresenter) => mentionPresenter.unseenMention,
        ).length;
    }

    get numberOfProjects() {
        return this.projects.length;
    }

    get mentionPresenters() {
        return this.mentions
            .slice()
            .sort((mention1, mention2) => (mention1.id < mention2.id ? 1 : -1))
            .map(this._createMentionPresenter) || [];
    }

    get activityPresenters() {
        return this.activities
            .slice()
            .map((activity) => {
                const assetVersion = this.getAssetVersionFromStore.findBy((aVersion) => {
                    switch (activity.activityOnType) {
                    default:
                        return false;
                    case 'AssetVersion':
                        return aVersion.id === activity.activityOn?.id;
                    case 'Asset':
                        return aVersion.asset.id === activity.activityOn?.id;
                    }
                });
                if (!assetVersion) {
                    return null;
                }

                return new ActivityPresenter({
                    assetVersion,
                    activity,
                    markActivitiesAsSeen: this.markActivitiesAsSeen,
                });
            })
            .filter(Boolean);
    }

    get currentUser() {
        return this.getCurrentUserFromStore.execute();
    }

    get sidebarPresenter() {
        return new SidebarPresenter({
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            logOut: this.logOut,
            router: this.router,
        });
    }

    markAllMentionsAsSeenButtonWasClicked = () => {
        this.markMentionsAsSeen.execute();
    }

    markAllActivitiesAsSeenButtonWasClicked = () => {
        this.markActivitiesAsSeen.execute();
    }

    projectThumbnailPresenter(project) {
        const asset = new Asset({
            id: project.id,
            filename: project.thumbnailFileExtension,
            assetVersions: [
                new AssetVersion({
                    number: 1,
                    thumbnailUrl: project.thumbnailUrl,
                }),
            ],
        });

        return new AssetThumbnailPresenter({asset});
    }

    _fetchProjects() {
        this.fetchProjectsFromRemote.execute();
    }

    _fetchMentions() {
        this.fetchMentionsFromRemote.execute();
    }

    async _fetchActivities() {
        this.fetchAssetVersionsFromRemote.execute();
        this.fetchActivitiesFromRemote.execute();
    }

    _createMentionPresenter = (mention) => (
        new MentionPresenter({
            mention,
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            getUserFromStore: this.getUserFromStore,
            markMentionsAsSeen: this.markMentionsAsSeen,
            router: this.router,
        })
    )

    onCreateProjectButtonClicked = () => {
        if (!this.createProjectForm) {
            this.createProjectForm = new Form({
                fields: manageProjectFields,
            }, {
                hooks: {
                    onSuccess: this.onCreateProject,
                },
            });
        }

        this.createProjectForm.clear();
        this.createProjectForm.$(PROJECT_NAME).focus();

        this.modalService.openModal(CREATE_PROJECT_MODAL, {
            title: 'Create project',
            inputs: {
                [PROJECT_NAME]: {
                    multiline: false,
                    control: this.createProjectForm.$(PROJECT_NAME),
                },
            },
            onConfirm: this.createProjectForm.onSubmit,
            submitButton: 'Create',
        });
    }

    onCreateProject = async () => {
        const formData = this.createProjectForm.values();
        const createdProject = await this.manageProject.create(formData);

        this.modalService.closeModal(CREATE_PROJECT_MODAL);
        this.router.navigateTo(
            PROJECT_DETAIL_PATH.replace(':id', createdProject.id),
        );
    }

    isCurrentUserTheOwner(project) {
        const projectOwnerId = project?.owner?.id;
        if (projectOwnerId && this.currentUser) {
            return this.currentUser.id === projectOwnerId;
        }

        return false;
    }

    renameProject = (projectId) => {
        this.renameProjectForm = new Form({
            fields: manageProjectFields,
        }, {
            hooks: {
                onSuccess: () => this.onRenameProject(projectId),
            },
        });

        const project = this.projects.find(({id}) => id === projectId);

        this.renameProjectForm.$(PROJECT_NAME).set(project.name);
        this.renameProjectForm.$(PROJECT_NAME).focus();

        this.modalService.openModal(MANAGE_FOLDER_MODAL, {
            title: 'Rename project',
            inputs: {
                [PROJECT_NAME]: {
                    multiline: false,
                    control: this.renameProjectForm.$(PROJECT_NAME),
                },
            },
            onConfirm: this.renameProjectForm.onSubmit,
            submitButton: 'Rename',
        });
    }

    onRenameProject = async (projectId) => {
        const formData = this.renameProjectForm.values();
        await this.manageProject.update(projectId, formData);

        this.modalService.closeModal(MANAGE_FOLDER_MODAL);
        this.renameProjectForm.clear();
    }

    deleteProject = (projectId) => {
        const project = this.projects.find(({id}) => id === projectId);

        this.modalService.openModal(CONFIRMATION_MODAL, {
            onConfirmationClick: () => this.manageProject.delete(projectId),
            title: 'Delete project',
            body: `Are you sure you want to delete project <strong>${project.name}</strong>?`,
        });
    }
}
