import {
    toast,
} from 'react-toastify';

export default class ToastService {
    get POSITION() {
        return toast.POSITION;
    }

    options = {
        position: toast.POSITION.BOTTOM_RIGHT,
    }

    notifySuccess(content, options = {}) {
        toast.success(content, {
            ...this.options,
            ...options,
        });
    }

    notifyError(content, options = {}) {
        toast.error(content, {
            ...this.options,
            ...options,
        });
    }
}
