import {makeStyles} from '@material-ui/core/styles';
import {fonts, palette} from '../../Theme';

const padding = {
    top: '25px',
    right: '32px',
    bottom: '32px',
    left: '32px',
};

export const useStyles = makeStyles(() => ({

    dashboardCard: {
        position: 'relative',
        backgroundColor: palette.dashboardCardBackgroundPrimary,
        borderRadius: '26px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        zIndex: 1,
    },

    header: {
        boxSizing: 'border-box',
        paddingTop: padding.top,
        paddingRight: '10px',
        paddingBottom: '10px',
        paddingLeft: padding.left,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& > h2': {
            color: palette.formTitle,
            ...fonts.altSmallTitle,
            fontSize: '28px',
            marginTop: 0,
            marginBottom: 0,
        },

        '& em': {
            ...fonts.altSmallTitle,
            color: palette.dashboardStrongSecondary,
            fontSize: '28px',
        },
    },

    scrollableHeader: {
        position: 'relative',

        '&::after': {
            content: "''",
            backgroundImage: 'linear-gradient(to top, rgba(255, 249, 239, 0), rgba(22, 20, 29, 0.1))',
            height: '5px',
            position: 'absolute',
            bottom: '-5px',
            left: 0,
            right: 0,
            zIndex: 20,
        },
    },

    scrollableList: {
        height: 'auto',
        overflowY: 'auto',
        padding: '5px 12px 24px 8px',
        marginRight: '6px',

        '&::-webkit-scrollbar': {
            width: '4px',
        },

        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgb(22, 20, 29, 0.1)',
            borderRadius: '2px',
        },
    },

    staticList: {
        padding: `5px ${padding.right} ${padding.bottom} ${padding.left}`,
    },

}));
