import {
    action, makeObservable,
} from 'mobx';
import Store from './Store';

export default class ActivitiesStore extends Store {
    constructor(props) {
        super(props);

        makeObservable(this, {
            markAsSeenAll: action,
            markAsSeenById: action,
        });
    }

    markAsSeenAll() {
        const activities = this.filter((activity) => !activity.isSeen);
        activities.forEach((activity) => this.markAsSeenById(activity.id));

        return activities;
    }

    markAsSeenById(activityId) {
        const activity = this.find(activityId);
        activity.markAsSeen();

        return activity;
    }
}
