import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {ASSETS_PATH} from '../../../routes/paths';
import AssetThumbnailPresenter from '../../../presenters/AssetThumbnailPresenter';
import DateToDisplay from '../../../presenters/ToDisplay/DateToDisplay';
import {KebabMenu} from '../../KebabMenu';
import EditIcon from '../../Icons/EditIcon';
import BinIcon from '../../Icons/BinIcon';

import classes from './AssetItem.module.scss';

const AssetItem = ({
    asset,
    renameAsset,
    deleteAsset,
}) => {
    const assetThumbnailPresenter = new AssetThumbnailPresenter({asset});
    const assetLastUpdatedAt = `${new DateToDisplay({date: asset.lastUpdatedAt}).displayTimeAgoWithText}`;
    const assetVersionsLength = asset.assetVersions.length;
    const deleteAssetText = assetVersionsLength > 1
        ? `Delete all ${assetVersionsLength} versions`
        : 'Delete asset';

    return (
        <Link
            to={`${ASSETS_PATH}/${asset.id}`}
            className={classes.item}
        >
            <div className={classes.version}>
                <span>v</span>
                <span className={classes.versionValue}>{assetThumbnailPresenter.lastAssetVersionNumber}</span>
            </div>
            <img
                className={classes.thumbnail}
                src={assetThumbnailPresenter.thumbnail}
                alt={''}
            />
            <div className={classes.meta}>
                <div
                    className={classes.metaTitle}
                    title={asset.name}
                >
                    {asset.name}
                </div>
                <div className={classes.metaUpdated}>
                    {assetLastUpdatedAt}
                </div>
                <div className={classes.menu}>
                    <KebabMenu
                        theme={'dark'}
                        options={[
                            {
                                key: 'rename',
                                icon: <EditIcon />,
                                label: 'Rename',
                                onClick: renameAsset,
                            },
                            {
                                key: 'delete',
                                icon: <BinIcon />,
                                label: deleteAssetText,
                                onClick: deleteAsset,
                            },
                        ]}
                        dataTestId={`asset-button-${asset.id}`}
                    />
                </div>
            </div>
        </Link>
    );
};

AssetItem.propTypes = {
    asset: PropTypes.any.isRequired,
    renameAsset: PropTypes.func.isRequired,
    deleteAsset: PropTypes.func.isRequired,
};

AssetItem.defaultProps = {
};

export default observer(AssetItem);
