import {makeAutoObservable} from 'mobx';
import CommentPresenter from './CommentPresenter';
import Form from '../forms/Form';
import fields from '../forms/createCommentFields';
import MediaGroupTeamListSectionPresenter from './MediaGroupTeamListSectionPresenter';

export default class CommentSectionPresenter {
    constructor({
        getCommentsFromStore,
        fetchCommentsFromRemote,
        assetId,
        projectId,
        assetVersions,
        assetLatestVersion,
        assetVersionWasClicked,
        getCurrentUserFromStore,
        getUserFromStore,
        getProjectMembersFromStore,
        fetchProjectMembersFromRemote,
        createComment,
        getSelectedAssetVersionId,
    } = {}) {
        this.getCommentsFromStore = getCommentsFromStore;
        this.fetchCommentsFromRemote = fetchCommentsFromRemote;
        this.getSelectedAssetVersionId = getSelectedAssetVersionId;
        this.assetId = assetId;
        this.projectId = projectId;
        this.assetVersions = assetVersions;
        this.assetLatestVersion = assetLatestVersion;
        this.assetVersionWasClicked = assetVersionWasClicked;
        this.commentPresenters = [];
        this.commentIds = [];
        this.numberOfAvatarsShown = 4;

        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.getUserFromStore = getUserFromStore;
        this.getProjectMembersFromStore = getProjectMembersFromStore;
        this.fetchProjectMembersFromRemote = fetchProjectMembersFromRemote;

        this.createComment = createComment;

        this.formHooks = {
            onSuccess: this.onSubmitSuccess,
        };

        this.form = new Form({fields}, {hooks: this.formHooks});

        this._fetchComments();
        this._fetchProjectMembers();

        this.projectTeamListPresenter = new MediaGroupTeamListSectionPresenter({
            projectId: this.projectId,
            getProjectMembersFromStore: this.getProjectMembersFromStore,
            fetchProjectMembersFromRemote: this.fetchProjectMembersFromRemote,
            projectMemberAvatarButtonWasClicked: () => {},
        });

        makeAutoObservable(this);
    }

    onSubmitSuccess = async (form) => {
        const formData = form.values();
        const comment = await this.createComment.execute({
            ...formData,
            assetVersionId: this.getSelectedAssetVersionId(),
        });
        this._updateCommentIds(comment.id);
        this._updateCommentPresenters(comment);
        this.form.clear();
    };

    get comments() {
        return this.getCommentsFromStore.execute({ids: this.commentIds});
    }

    get projectMembers() {
        return this.getProjectMembersFromStore.execute(this.projectId);
    }

    get users() {
        return this.projectMembers
            .map((projectMember) => projectMember.user);
    }

    get mentionTooltipText() {
        return 'Type @ to mention other members';
    }

    async _fetchComments() {
        const comments = await this.fetchCommentsFromRemote.execute(this.assetId);
        this.commentIds = comments.map((comment) => comment.id);
        this._createCommentPresenters();
    }

    _createCommentPresenters() {
        this.commentPresenters = this.comments.map(
            (comment) => this._createNewCommentPresenter(comment),
        );
    }

    async _fetchProjectMembers() {
        await this.fetchProjectMembersFromRemote.execute(this.projectId);
    }

    _updateCommentIds(commentId) {
        this.commentIds.push(commentId);
    }

    _updateCommentPresenters(comment) {
        this.commentPresenters.push(this._createNewCommentPresenter(comment));
    }

    _createNewCommentPresenter(comment) {
        return new CommentPresenter({
            comment,
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            getUserFromStore: this.getUserFromStore,
            assetVersions: this.assetVersions,
            getSelectedAssetVersionId: this.getSelectedAssetVersionId,
            assetVersionWasClicked: this.assetVersionWasClicked,
        });
    }
}
