import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {KebabMenu} from '../../KebabMenu';
import BinIcon from '../../Icons/BinIcon';
import EditIcon from '../../Icons/EditIcon';

import classes from './MediaGroupItem.module.scss';
import MediaGroupThumbnailPresenter from '../../../presenters/MediaGroupThumbnailPresenter';

const MediaGroupItem = ({presenter}) => {
    const {
        assetThumbnailPresenters,
        numberOfExtraThumbnails,
        maxNumberOfAssetsToDisplay,
        mediaGroupUrl,
        mediaGroupName,
        lastUpdatedAt,
    } = presenter;

    let thumbnailsClassName;
    switch (assetThumbnailPresenters.length) {
    case 0:
        thumbnailsClassName = '';
        break;

    case 1:
        thumbnailsClassName = 'thumbnailsOne';
        break;

    case 2:
        thumbnailsClassName = 'thumbnailsTwo';
        break;

    default:
        thumbnailsClassName = 'thumbnailsMulti';
        break;
    }

    return (
        <Link
            className={classes.item}
            to={mediaGroupUrl}
        >
            <div className={classes.folder}>
                {!assetThumbnailPresenters.length && (
                    <div className={classes.emptyFolderName}>
                        {mediaGroupName}
                    </div>
                )}
                {assetThumbnailPresenters.length > 0 && (
                    <div className={clsx(classes.thumbnails, classes[thumbnailsClassName])}>
                        {assetThumbnailPresenters.map((assetThumbnailPresenter, i) => {
                            const showMore = i === maxNumberOfAssetsToDisplay - 1
                                && numberOfExtraThumbnails > 0;

                            return (
                                <div
                                    key={assetThumbnailPresenter.thumbnail}
                                    className={classes.thumbnail}
                                >
                                    <img
                                        src={assetThumbnailPresenter.thumbnail}
                                        className={classes.thumbnailPic}
                                        alt={''}
                                    />
                                    {showMore && (
                                        <div className={classes.thumbnailMore}>
                                            {`+${numberOfExtraThumbnails}`}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className={classes.meta}>
                <div
                    className={classes.metaTitle}
                    title={mediaGroupName}
                >
                    {mediaGroupName}
                </div>
                <div className={classes.metaUpdated}>
                    {[
                        lastUpdatedAt,
                    ].filter(Boolean).join(' • ')}
                </div>
                <div className={classes.menu}>
                    <KebabMenu
                        theme={'dark'}
                        options={[
                            {
                                key: 'rename',
                                icon: <EditIcon />,
                                label: 'Rename folder',
                                onClick: presenter.updateMediaGroupButtonWasClicked,
                            },
                            {
                                key: 'delete',
                                icon: <BinIcon />,
                                label: 'Delete folder',
                                onClick: presenter.deleteMediaGroupButtonWasClicked,
                            },
                        ]}
                    />
                </div>
            </div>
        </Link>
    );
};

MediaGroupItem.propTypes = {
    presenter: PropTypes.instanceOf(MediaGroupThumbnailPresenter).isRequired,
};

export default observer(MediaGroupItem);
