import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {useStyles} from './ActivityList.styles';
import Activity from './components/Activity';
import ActivityPresenter from '../../presenters/ActivityPresenter';

const ActivityList = ({activityPresenters}) => {
    const classes = useStyles();

    return (
        <div className={classes.activityList}>
            {activityPresenters.map((presenter) => (
                <Activity
                    key={String(presenter.activityId)}
                    presenter={presenter}
                />
            ))}
        </div>
    );
};

ActivityList.propTypes = {
    activityPresenters: PropTypes.arrayOf(
        PropTypes.instanceOf(ActivityPresenter),
    ).isRequired,
};

export default observer(ActivityList);
