export default class FileValidator {
    static validate(file) {
        return this.validateFileSize(file);
    }

    static validateFileSize(file) {
        const oneGigabyte = 1_000_000_000;
        return file.size < oneGigabyte ? null : 'file is too big';
    }
}
