export default class CurrentUser {
    constructor({userStore, userService}) {
        this.userStore = userStore;
        this.userService = userService;
    }

    async execute() {
        const currentUser = await this.userService.getCurrentUser();
        this.userStore.updateCurrentUser(currentUser);

        return currentUser;
    }

    async afterSignUp({
        firstName,
        lastName,
    }) {
        const currentUser = await this.userService.updateUser(this.userStore.currentUser.id, {
            first_name: firstName,
            last_name: lastName,
        });
        this.userStore.updateCurrentUser(currentUser);

        return currentUser;
    }
}
