import {makeAutoObservable} from 'mobx';
import CommentPresenter from './CommentPresenter';
import {ASSET_DETAIL_PATH} from '../routes/paths';

export default class MentionPresenter {
    constructor({
        mention,
        markMentionsAsSeen,
        getCurrentUserFromStore,
        getUserFromStore,
        router,
    } = {}) {
        this.mention = mention;
        this.markMentionsAsSeen = markMentionsAsSeen;

        this.getUserFromStore = getUserFromStore;
        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.router = router;

        this.commentPresenter = new CommentPresenter({
            comment: mention.comment,
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            getUserFromStore: this.getUserFromStore,
        });

        makeAutoObservable(this);
    }

    get username() {
        return this.commentPresenter.username;
    }

    get text() {
        return this.commentPresenter.text;
    }

    get mentionId() {
        return this.mention.id;
    }

    get mentionAssetId() {
        return this._mentionAsset.id;
    }

    get mentionPostName() {
        return this._mentionPost.name;
    }

    get mentionProjectName() {
        return this._mentionProject.name;
    }

    get unseenMention() {
        return this.mention.unseen;
    }

    get mentionUrl() {
        return ASSET_DETAIL_PATH.replace(':id', this.mentionAssetId);
    }

    get _mentionAssetVersion() {
        return this.mention.assetVersion;
    }

    get _mentionAsset() {
        return this._mentionAssetVersion.asset;
    }

    get _mentionPost() {
        return this._mentionAsset.mediaGroup;
    }

    get _mentionProject() {
        return this._mentionPost.project;
    }

    markOneAsSeenButtonWasClicked = (mentionId) => {
        this.markMentionsAsSeen.markAsSeenById(mentionId);
    }
}
