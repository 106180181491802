import {observer} from 'mobx-react-lite';
import useSignUpWireframe from '../../wireframes/useSignUpWireframe';
import {SignUpSection} from '../../components/SignUpSection';

import {useStyles} from './SignUp.styles';

const SignUp = () => {
    const presenter = useSignUpWireframe();
    const classes = useStyles();

    return !!presenter && (
        <div className={classes.root}>
            <SignUpSection presenter={presenter} />
        </div>
    );
};

SignUp.propTypes = {
};

SignUp.defaultProps = {
};

export default observer(SignUp);
