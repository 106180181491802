import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        margin: '3px 0 18px 0',
        borderRadius: '6px',
        border: '0 !important',
        borderWidth: '1px !important',
        outline: 'none',

        '&:hover': {
            borderColor: palette.primary,
        },

        '&:active': {
            borderColor: palette.primary,
        },
    },

    input: {
        ...fonts.body,
        backgroundColor: palette.secondary,
    },

    focused: {
        borderWidth: '1px',
        boxShadow: '0 0 4px 0 rgba(91, 113, 229, 0.25)',

        '& fieldset': {
            borderWidth: '1px !important',
        },
    },
    disabled: {
        '& fieldset': {
            borderColor: 'rgba(114, 131, 145, .1) !important',
            overflow: 'hidden',
        },
        '& input': {
            backgroundColor: 'rgba(114, 131, 145, .2)',
            color: '#728391',
        },
    },
    inputError: {
        marginLeft: '0 !important',
    },
}));
