import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    useCallback,
    useEffect,
    useRef,
} from 'react';

import classes from './Scroll.module.scss';

function Scroll({
    children,
    className,
    forwardedRef,
    theme,
    onScroll,
}) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = forwardedRef || useRef(null);
    const scrollCallback = useCallback(() => {
        const element = ref?.current;

        if (element && typeof onScroll === 'function') {
            onScroll({
                height: element.scrollHeight,
                top: element.scrollTop,
            });
        }
    }, [
        ref,
        onScroll,
    ]);
    useEffect(() => {
        const element = ref?.current;

        if (element) {
            element.addEventListener('scroll', scrollCallback);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', scrollCallback);
            }
        };
    }, [
        ref,
        scrollCallback,
    ]);

    return (
        <div
            className={clsx(className, classes[theme], classes.scroll)}
            ref={ref}
        >
            {children}
        </div>
    );
}

Scroll.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    forwardedRef: PropTypes.any,
    onScroll: PropTypes.func,
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
};

Scroll.defaultProps = {
    className: '',
    forwardedRef: null,
    theme: 'light',
    onScroll: null,
};

export default Scroll;
