import {makeAutoObservable} from 'mobx';
import {
    CONFIRMATION_MODAL,
    INVITE_MEMBER_MODAL,
    TEAM_MODAL,
} from '../constants/modals';
import Form from '../forms/Form';
import fields from '../forms/addMemberFields';
import MediaGroupTeamListSectionPresenter from './MediaGroupTeamListSectionPresenter';

export default class ProjectTeamPresenter {
    constructor({
        project,
        getUsersFromStore,
        addProjectMember,
        getCurrentUserFromStore,
        fetchProjectMembersFromRemote,
        getProjectMembersFromStore,
        removeProjectMember,
        modalService,
    } = {}) {
        this._project = project;

        this._getUsersFromStore = getUsersFromStore;
        this._addProjectMember = addProjectMember;
        this._getCurrentUserFromStore = getCurrentUserFromStore;
        this._fetchProjectMembersFromRemote = fetchProjectMembersFromRemote;
        this._getProjectMembersFromStore = getProjectMembersFromStore;
        this._removeProjectMember = removeProjectMember;

        this._modalService = modalService;

        this._formHooks = {
            onSuccess: this.onSubmitSuccess,
        };

        this.form = new Form({fields}, {hooks: this._formHooks});

        makeAutoObservable(this);
    }

    onSubmitSuccess = async (form) => {
        const userEmail = form.values().email;

        await this._addProjectMember.execute({
            projectId: this._project.id,
            userId: this._findUserByEmail(userEmail).id,
        });

        this.form.clear();
        this._returnToTeamModalButtonWasClicked();
    };

    expandInviteMemberButtonWasClicked = () => {
        this._openInviteMemberModal();
    }

    get mediaGroupTeamListSectionPresenter() {
        if (!this._project) return null;

        return new MediaGroupTeamListSectionPresenter({
            projectId: this._project.id,
            fetchProjectMembersFromRemote: this._fetchProjectMembersFromRemote,
            getProjectMembersFromStore: this._getProjectMembersFromStore,
            projectMemberAvatarButtonWasClicked: this._expandTeamModalButtonWasClicked,
        });
    }

    get isCurrentUserTheOwner() {
        if (this._project?.owner && this._currentUser) {
            return this._currentUser.id === this._project.owner.id;
        }

        return false;
    }

    _expandTeamModalButtonWasClicked = () => {
        this._openTeamModal();
    };

    _findUserByEmail(email) {
        return this._usersToInvite.find((user) => user.email === email);
    }

    _inviteMemberButtonWasClicked = () => {
        this._modalService.closeModal(TEAM_MODAL);
        this._openInviteMemberModal();
    }

    _openInviteMemberModal = () => {
        this._modalService.openModal(
            INVITE_MEMBER_MODAL,
            {
                returnToPreviousModalButtonWasClicked: this._returnToTeamModalButtonWasClicked,
                users: this._usersToInvite,
                form: this.form,
            },
        );
    }

    _openTeamModal = () => {
        this._modalService.openModal(
            TEAM_MODAL,
            {
                owner: this._project.owner,
                members: this._projectMembers,
                isCurrentUserTheOwner: this.isCurrentUserTheOwner,
                inviteMemberButtonWasClicked: this._inviteMemberButtonWasClicked,
                removeMemberButtonWasClicked: this._removeMemberButtonWasClicked,
            },
        );
    }

    _removeMember = async (projectMemberId) => {
        await this._removeProjectMember.execute(projectMemberId);
        this._modalService.closeModal(CONFIRMATION_MODAL);
        this._modalService.setProps(TEAM_MODAL, {
            owner: this._project.owner,
            members: this._projectMembers,
            isCurrentUserTheOwner: this.isCurrentUserTheOwner,
            inviteMemberButtonWasClicked: this._inviteMemberButtonWasClicked,
            removeMemberButtonWasClicked: this._removeMemberButtonWasClicked,
        });
    };

    _removeMemberButtonWasClicked = (projectMember) => {
        this._modalService.openModal(CONFIRMATION_MODAL, {
            title: 'Remove user from project?',
            body: `Are you sure you want to remove user <strong>${projectMember.user?.fullName}</strong> from project?`,
            onConfirmationClick: () => this._removeMember(projectMember.id),
        });
    }

    _returnToTeamModalButtonWasClicked = () => {
        this._modalService.closeModal(INVITE_MEMBER_MODAL);
        this._openTeamModal();
    }

    _userIsNotAProjectMember(user) {
        return !this._projectMembers.find(
            (projectMember) => projectMember.user.id === user.id,
        );
    }

    _userIsNotProjectOwner(user) {
        return user.id !== this._project.owner.id;
    }

    get _currentUser() {
        return this._getCurrentUserFromStore.execute();
    }

    get _projectMembers() {
        return this._getProjectMembersFromStore.execute(this._project.id).filter(
            (member) => member.user.id !== this._project.owner?.id,
        );
    }

    get _usersToInvite() {
        return this._getUsersFromStore.execute().filter(
            (user) => this._userIsNotAProjectMember(user) && this._userIsNotProjectOwner(user),
        );
    }
}
