import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },

    label: {
        fontWeight: '500',
    },

    control: {
        color: palette.altDarkText,
        ...fonts.smallTitle2,

        fontSize: '18px',
        fontWeight: '500',
        maxHeight: '48px',
        padding: '0 10px 0 24px',
        margin: '12px 0',

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    item: {
        borderBottom: `solid 1px ${palette.lightBackground}`,

        '&:last-child': {
            border: 'none',
        },
    },

    itemOpened: {
        '& button > div': {
            fontWeight: '800',
        },
    },

    addIcon: {
        display: 'flex',
        width: '13px',
        height: '13px',
    },

    content: {
        whiteSpace: 'pre-line',
        ...fonts.smallTitle2,

        fontSize: '14px',
    },

    contentInner: {
        padding: 0,
    },

    labelComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
    },

    labelComponentDisabled: {
        cursor: 'default',
    },

    labelText: {
        alignSelf: 'center',
        fontSize: '18px',
    },

    labelKebab: {
        padding: 0,

        '& svg': {
            color: palette.altDarkText,
            width: '24px',
            height: '24px',
        },
    },

    toggle: {
        '& div': {
            margin: 0,
        },
    },

    scrollable: {
        maxHeight: '500px',
    },

    contentWrapper: {
        padding: '0 24px',
    },
}));
