import PropTypes from 'prop-types';
import classes from './ModalFooter.module.scss';

function ModalFooter({children}) {
    return (
        <div className={classes.root}>
            {children}
        </div>
    );
}

ModalFooter.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ModalFooter;
