import {DESCRIPTION} from '../constants/formFields/createAssetForm';

const fields = [
    {
        name: DESCRIPTION,
        label: 'Description',
        placeholder: '',
        rules: '',
    },
];

export default fields;
