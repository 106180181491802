import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    button: {
        textTransform: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',

        '& span': {
            display: 'unset',
        },
    },
}));
