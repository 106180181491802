import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Modal as MUIModal} from '@mui/material';
import {useStyles} from './Modal.styles';
import {IconButton} from '../IconButton';
import PlusIcon from '../Icons/PlusIcon';

const Modal = ({
    open,
    backdropClose,
    children,
    onClose,
    invisible,
}) => {
    const classes = useStyles();
    const onBackdropClick = backdropClose
        ? onClose
        : null;

    return (
        <MUIModal
            disableEnforceFocus
            disableAutoFocus
            open={open}
            onClose={onBackdropClick}
            BackdropProps={{
                classes: {
                    root: clsx(classes.backDrop, invisible && classes.invisible),
                },
            }}
        >
            <div className={clsx(classes.modal, invisible && classes.invisible)}>
                <div className={classes.header}>
                    <div className={classes.closeButton}>
                        <IconButton onClick={onClose}>
                            <PlusIcon />
                        </IconButton>
                    </div>
                </div>
                {children}
            </div>
        </MUIModal>
    );
};

Modal.propTypes = {
    open: PropTypes.bool,
    invisible: PropTypes.bool,
    backdropClose: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
    backdropClose: true,
    open: false,
    invisible: false,
};

export default Modal;
