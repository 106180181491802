export const LOGIN_PATH = '/login';
export const SIGNUP_PATH = '/signup';
export const DASHBOARD_PATH = '/dashboard';
export const PROJECTS_PATH = '/projects';
export const PROJECT_DETAIL_PATH = `${PROJECTS_PATH}/:id`;
export const MEDIA_GROUPS_PATH = '/posts';
export const MEDIA_GROUP_DETAIL_PATH = `${MEDIA_GROUPS_PATH}/:id`;
export const NEW_MEDIA_GROUP_PATH = `${PROJECT_DETAIL_PATH}/new-post`;
export const ASSETS_PATH = '/assets';
export const ASSET_DETAIL_PATH = `${ASSETS_PATH}/:id`;
export const PROFILE_PATH = '/profile';

export const DEFAULT_PATH = DASHBOARD_PATH;
