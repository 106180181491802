export default class FetchCommentsFromRemote {
    constructor({commentStore, assetService}) {
        this.commentStore = commentStore;
        this.assetService = assetService;
    }

    async execute(assetId) {
        const comments = await this._fetchFromService(assetId);
        this._putInStore(comments);
        return comments;
    }

    async _fetchFromService(assetId) {
        return this.assetService.getComments({assetId});
    }

    _putInStore(comments) {
        this.commentStore.updateList(comments);
    }
}
