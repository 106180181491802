import {makeStyles} from '@material-ui/core/styles';
import {fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    list: {
        minWidth: '140px',
        padding: '0 !important',
    },
    paper: {
        borderRadius: '6px',
        boxShadow: '0 2px 12px 0 rgba(22, 20, 29, 0.15) !important',
    },

    icon: {
        height: '39px',
        width: '39px',
        padding: '0 !important',
        backgroundColor: 'transparent !important',
    },

    menuItem: {
        display: 'flex',
        gap: '7px',
        alignItems: 'center',
        justifyContent: 'center',

        '&.menuItem': {
            padding: '10px',
            ...fonts.buttonTextSmall,

            '&:hover': {
                backgroundColor: 'rgba(91, 113, 229, .1)',
            },
        },
    },

    isOpen: {
        display: 'block !important',
    },
}));
