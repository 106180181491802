import Api from './Api';
import FileWithUrl from '../structures/FileWithUrl';
import FileResponseDeserializer from './FileResponseDeserializer';

export default class FileService {
    constructor({
        api = new Api({responseDeserializerKlass: FileResponseDeserializer}),
        buildEntity = FileWithUrl.fromJSON,
    } = {}) {
        this.api = api;
        this.buildEntity = buildEntity;
    }

    get = async (url) => {
        const fileJSON = await this.api.get(url, {
            responseType: 'arraybuffer',
        });

        return this.buildEntity(fileJSON);
    }
}
