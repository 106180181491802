import {observer} from 'mobx-react-lite';
import useAssetDetailWireframe from '../../wireframes/useAssetDetailWireframe';
import {CommentSidebar} from '../../components/CommentSidebar';
import {MediaDisplay} from '../../components/MediaDisplay';
import {Breadcrumbs} from '../../components/Breadcrumbs';

import {useStyles} from './AssetDetail.styles';
import AssetHeader from './components/AssetHeader';

const AssetDetail = () => {
    const presenter = useAssetDetailWireframe();
    const classes = useStyles();

    return !!presenter && (
        <div className={classes.screen}>
            <div className={classes.breadcrumbs}>
                <Breadcrumbs breadcrumbs={presenter.breadcrumbs} theme="light" />
            </div>
            <div className={classes.content}>
                <div className={classes.assetDetail}>
                    <div className={classes.assetHeader}>
                        <AssetHeader presenter={presenter} />
                    </div>
                    {presenter.mediaDisplay && (
                        <div className={classes.mediaDisplayContainer}>
                            <MediaDisplay presenter={presenter.mediaDisplay} />
                        </div>
                    )}
                </div>
                <CommentSidebar presenter={presenter.commentSectionPresenter} />
            </div>
        </div>
    );
};

AssetDetail.propTypes = {
};

AssetDetail.defaultProps = {
};

export default observer(AssetDetail);
