import {makeAutoObservable} from 'mobx';
/* eslint-disable-next-line import/no-cycle */
import Project from './Project';

export default class MediaGroup {
    constructor({
        id, name, project, assets, lastUpdatedAt,
    }) {
        this.id = id;
        this.name = name;
        this.project = project;
        this.assets = assets;
        this.lastUpdatedAt = lastUpdatedAt;

        makeAutoObservable(this);
    }

    toJSON() {
        const project = this.project.toJSON();

        return {
            id: this.id,
            name: this.name,
            project,
            assets: this.assets,
            last_updated_at: this.lastUpdatedAt,
        };
    }

    static fromJSON(data) {
        const project = data.project && Project.fromJSON(data.project);

        return new MediaGroup({
            id: data.id,
            name: data.name,
            project,
            assets: data.assets,
            lastUpdatedAt: new Date(data.last_updated_at),
        });
    }

    addAsset({asset}) {
        this.assets.push(asset);
    }

    removeAsset({assetId}) {
        this.assets = this.assets.filter((asset) => asset.id !== assetId);
    }
}
