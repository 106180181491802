/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import MuiIconButton from '@material-ui/core/IconButton';
import noop from '../../helpers/noop';
import {useStyles} from './IconButton.styles';

const IconButton = ({
    onClick, children, id, disabled, className,
}) => {
    const classes = useStyles();

    return (
        <MuiIconButton
            className={className || classes.button}
            onClick={onClick}
            data-testid={id}
            disabled={disabled}
        >
            {children}
        </MuiIconButton>
    );
};

IconButton.propTypes = {
    onClick: propTypes.func,
};

IconButton.defaultProps = {
    onClick: noop,
};

export default IconButton;
