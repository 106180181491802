import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import {ModalHeader} from '../ModalHeader';
import ModalFooter from '../ModalFooter/ModalFooter';
import {ModalCard} from '../ModalCard';
import {InputText} from '../InputText';
import {PrimaryButton} from '../PrimaryButton';
import {TextButton} from '../TextButton';

import {useStyles} from './ManageFolderModal.styles';
import ModalBody from '../ModalBody/ModalBody';

const ManageFolderModal = ({
    open,
    onClose,
    onConfirm,
    title,
    inputs,
    submitButton,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            {...rest}
        >
            <ModalCard className={classes.root}>
                <ModalHeader>
                    <h1>{title}</h1>
                </ModalHeader>
                <ModalBody className={classes.form}>
                    {Object.entries(inputs).map(([key, {multiline, control}]) => (
                        <div
                            key={key}
                            className={multiline ? classes.textarea : classes.input}
                        >
                            {control.label && (
                                <div className={classes.label}>
                                    {control.label}
                                </div>
                            )}
                            <InputText
                                errorMessage={control.error}
                                className={classes.control}
                                multiline={multiline}
                                {...control.bind()}
                            />
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <TextButton onClick={onClose}>
                        Cancel
                    </TextButton>
                    <PrimaryButton onClick={onConfirm}>
                        {submitButton}
                    </PrimaryButton>
                </ModalFooter>
            </ModalCard>
        </Modal>
    );
};

ManageFolderModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    submitButton: PropTypes.string,
    inputs: PropTypes.any.isRequired,
};

ManageFolderModal.defaultProps = {
    open: false,
    submitButton: 'Save',
};

export default observer(ManageFolderModal);
