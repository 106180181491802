import {makeStyles} from '@material-ui/core/styles';
import {fonts} from '../../Theme';

const useStyles = makeStyles(() => ({
    root: {},

    title: {
        ...fonts.altSmallTitle,
        fontSize: '36px',
        fontWeight: 600,
        lineHeight: '51px',
        color: '#1f173b',
        margin: 0,
    },
}));

export default useStyles;
