export default class GetProjectMembersFromStore {
    constructor({projectMemberStore}) {
        this.projectMemberStore = projectMemberStore;
    }

    execute(projectId) {
        return this.entities(projectId);
    }

    entities(projectId) {
        const projectMembers = this.projectMemberStore.all;
        return projectMembers.filter((projectMember) => projectMember.projectId === projectId);
    }
}
