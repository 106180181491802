import {ToastContainer} from 'react-toastify';
import {useStyles} from './Toasts.styles';
import 'react-toastify/dist/ReactToastify.css';

const Toasts = ({children}) => {
    const {container, toastBody} = useStyles();

    return (
        <ToastContainer
            className={container}
            bodyClassName={toastBody}
        >
            {children}
        </ToastContainer>
    );
};

export default Toasts;
