import FontFactory from './structures/FontFactory';

export const colors = {
    white: '#ffffff',
    grey: '#808080',
    darkBlue: '#1f173b',
    whiteLilac: '#f8f8f9',
    royalBlue: '#5b71e5',
    havelockBlue: '#5ea7e0',
    cherry: '#dd2a54',
    mauvelous: '#f07d98',
    turquoise: '#35dbbb',
    sunsetPearl: '#f0d866',
    cornflowerBlue: '#6e83f4',
    midnightBlack: 'rgba(22, 20, 29, 0.25)',
    slateGray: '#728391',
    platinum: '#e2e5eb',
    jaguar: '#16141d',
    softRed: '#f07d98',
    lightGrey: '#f0f0f0',
    lightGrayishOrange: '#fdefe7',
    paleOrange: '#fff9ef',
    darkCyan: '#14a77b',
    vividCyan: '#04dbbd',
    softGrey: '#d8d8d8',
    blue: '#4cacf7',
};

const fontFamily = {
    bold: 'Poppins, sans serif',
    semiBold: 'Poppins, sans serif',
    black: 'Poppins, sans serif',
    regular: 'Poppins, sans serif',
    regularPoppins: 'Poppins, sans serif',
    button: 'Poppins, sans serif',
};

export const palette = {
    textDark: colors.jaguar,
    textLight: colors.white,
    primary: colors.royalBlue,
    primaryDisabled: `${colors.jaguar}20`,
    secondary: colors.white,
    modalBackground: colors.jaguar,
    loginBackground: colors.darkBlue,
    darkBackground: colors.jaguar,
    lightBackground: colors.lightGrayishOrange,
    formBackgroundPrimary: colors.whiteLilac,
    formBackgroundSecondary: colors.platinum,
    buttonHoverBackground: colors.cornflowerBlue,
    primaryButtonBackground: colors.royalBlue,
    primaryDisabledButtonBackground: `${colors.jaguar}20`,
    secondaryButtonBackground: colors.white,
    secondaryDisabledButtonBackground: `${colors.white}80`,
    buttonInactiveBorder: colors.midnightBlack,
    formTitle: colors.darkBlue,
    cancelAction: colors.royalBlue,
    lines: colors.jaguar,
    lightLines: colors.white,
    dropzoneBackground: `${colors.royalBlue}20`,
    selectBackgroundDark: colors.slateGray,
    selectOptionSecondaryText: colors.slateGray,
    selectOptionBackgroundFocused: `${colors.royalBlue}10`,
    dashboardCardBackgroundPrimary: colors.white,
    dashboardCardBackgroundSecondary: colors.paleOrange,
    dashboardStrongPrimary: colors.royalBlue,
    dashboardStrongSecondary: colors.softRed,
    itemListPrimary: colors.white,
    itemListSecondary: colors.lightGrey,
    toastSuccessBackground: colors.darkCyan,
    toastSuccessProgressBar: colors.vividCyan,
    toastErrorBackground: colors.cherry,
    toastErrorProgressBar: colors.softRed,
    mentionSecondaryText: colors.slateGray,
    breadcrumbLightText: colors.softGrey,
    breadcrumbDarkText: colors.darkBlue,
    highlight: colors.blue,
    borderButtonColor: colors.slateGray,
    altDarkText: colors.darkBlue,
};

export const fonts = {
    title: FontFactory.createTitle(fontFamily),
    subTitle: FontFactory.createSubTitle(fontFamily),
    smallTitle1: FontFactory.createSmallTitle1(fontFamily),
    smallTitle2: FontFactory.createSmallTitle2(fontFamily),
    buttonText: FontFactory.createButtonText(fontFamily),
    buttonTextSmall: FontFactory.createButtonTextSmall(fontFamily),
    textLink: FontFactory.createTextLink(fontFamily),
    bodyFocus: FontFactory.createBodyFocus(fontFamily),
    body: FontFactory.createBody(fontFamily),
    bodySmall: FontFactory.createBodySmall(fontFamily),
    altSmallTitle: FontFactory.createAltSmallTitle(fontFamily),
    breadcrumbs: FontFactory.createBreadcrumbs(fontFamily),
    helpText: FontFactory.createHelpText(fontFamily),
};

export const shadows = {
    common: '0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 12px 0 #fdefe7',
    dropdown: '0 2px 12px 0 rgba(22, 20, 29, 0.15)',
};
