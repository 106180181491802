export default class LogOutUser {
    constructor({authService, userStore}) {
        this.authService = authService;
        this.userStore = userStore;
    }

    execute() {
        this.userStore.deleteCurrentUser();
        this.authService.logOut();
    }
}
