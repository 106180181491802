import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
    Fragment,
} from 'react';
import {Link} from 'react-router-dom';
import {leftArrowIcon} from '../../icons';

import classes from './Breadcrumbs.module.scss';

const Breadcrumbs = ({breadcrumbs, theme}) => {
    const classTheme = {
        dark: classes.darkTheme,
        light: classes.lightTheme,
    };

    return (
        <div className={clsx(classes.breadcrumbs, classTheme[theme])}>
            <img src={leftArrowIcon} alt="left arrow" width={10} height={10} />
            {breadcrumbs.filter((breadcrumb) => !!breadcrumb.text).map((breadcrumb, i) => (
                <Fragment key={breadcrumb.text}>
                    {!breadcrumb.url && (
                        <div
                            key={breadcrumb.text}
                            title={breadcrumb.text}
                            className={classes.item}
                        >
                            {breadcrumb.text}
                        </div>
                    )}
                    {breadcrumb.url && (
                        <Link
                            key={breadcrumb.text}
                            to={breadcrumb.url}
                            className={classes.item}
                            title={breadcrumb.text}
                        >
                            {breadcrumb.text}
                        </Link>
                    )}
                    {i < breadcrumbs.length - 1 && (
                        <div className={classes.separator}>/</div>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
    })).isRequired,
    theme: PropTypes.string,
};

Breadcrumbs.defaultProps = {
    theme: 'light',
};

export default observer(Breadcrumbs);
