import {
    BRIEF,
} from '../constants/formFields/updateProjectBriefForm';

const fields = [{
    name: BRIEF,
    label: '',
    placeholder: '',
    rules: '',
}];

export default fields;
