import {
    LINK_ID,
    LINK_TITLE,
    LINK_ADDRESS,
} from '../constants/formFields/updateProjectLinksForm';

const fields = [
    {
        name: LINK_ID,
        type: 'hidden',
        label: '',
        placeholder: '',
        rules: '',
    },
    {
        name: LINK_ADDRESS,
        label: 'Link address',
        placeholder: '',
        rules: [
            'required',
            'url',
        ],
    },
    {
        name: LINK_TITLE,
        label: 'Link title',
        placeholder: '',
        rules: 'required',
    },
];

export default fields;
