import {useEffect, useState} from 'react';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import {useFirebase} from '../providers/FirebaseProvider';
import LogInPresenter from '../presenters/LogInPresenter';
import LogInUser from '../interactors/LogInUser';
import CurrentUser from '../interactors/CurrentUser';
import FetchEntitiesFromRemote from '../interactors/FetchEntitiesFromRemote';
import Api from '../services/Api';
import ApiResponseDeserializer from '../services/ApiResponseDeserializer';
import OAuthResponseDeserializer from '../services/OAuthResponseDeserializer';
import AuthService from '../services/AuthService';
import EntityServiceFactory from '../services/EntityServiceFactory';
import {useModalService} from '../providers/ModalProvider';

const useLogInWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();
    const firebase = useFirebase();
    const modalService = useModalService();

    useEffect(() => {
        const {authStore, userStore} = root;
        const authResponseDeserializerKlass = OAuthResponseDeserializer;
        const apiResponseDeserializerKlass = ApiResponseDeserializer;

        const authApi = new Api({
            authStore,
            responseDeserializerKlass: authResponseDeserializerKlass,
        });
        const api = new Api({
            authStore,
            responseDeserializerKlass: apiResponseDeserializerKlass,
        });

        const authService = new AuthService({
            api: authApi,
            auth: firebase.auth,
        });
        const logInUser = new LogInUser({
            authService,
        });

        const userService = EntityServiceFactory.forUsers(
            {api},
        );
        const currentUser = new CurrentUser({
            userStore, userService,
        });

        const fetchAllUsers = new FetchEntitiesFromRemote({
            store: userStore,
            service: userService,
        });

        const presenter = new LogInPresenter({
            logInUser,
            authStore,
            authService,
            router,
            currentUser,
            fetchAllUsers,
            modalService,
        });

        setPresenterInstance(presenter);
    }, [
        root,
        router,
        firebase,
        modalService,
    ]);

    return presenterInstance;
};

export default useLogInWireframe;
