export default class FetchCurrentUserProjects {
    constructor({projectStore, userService}) {
        this.projectStore = projectStore;
        this.userService = userService;
    }

    async execute() {
        const projectList = await this.userService.getCurrentUserProjects();
        this.projectStore.set(projectList);
    }
}
