import EntityService from './EntityService';
import ProjectMember from '../entities/ProjectMember';

export default class ProjectMemberService extends EntityService {
    async removeMember(projectMemberId) {
        const memberJSON = await this.api.delete(`${this.getBasePath()}/${projectMemberId}`);

        return ProjectMember.fromJSON(memberJSON);
    }
}
