export default class AddProjectMember {
    constructor({projectMemberStore, projectService}) {
        this.projectMemberStore = projectMemberStore;
        this.projectService = projectService;
    }

    async execute({projectId, userId}) {
        const projectMember = await this._addMember({projectId, userId});
        this._putInStore(projectMember);
        return projectMember;
    }

    async _addMember({projectId, userId}) {
        return this.projectService.addMember({projectId, userId});
    }

    _putInStore(projectMember) {
        this.projectMemberStore.add(projectMember);
    }
}
