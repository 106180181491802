import {makeAutoObservable} from 'mobx';
import mime from 'mime-types';
/* eslint-disable-next-line import/no-cycle */
import AssetVersion from './AssetVersion';
import MediaGroup from './MediaGroup';

export default class Asset {
    constructor({
        id, filename, description, projectId, assetVersions, mediaGroup,
    }) {
        this.id = id;
        this.filename = filename;
        this.description = description;
        this.projectId = projectId;
        this.assetVersions = assetVersions;
        this.mediaGroup = mediaGroup;

        makeAutoObservable(this);
    }

    get extension() {
        return mime.extension(this.contentType) || null;
    }

    get contentType() {
        return mime.lookup(this.filename) || null;
    }

    get name() {
        if (this.description) {
            return this.description;
        }

        if (this.filename) {
            const extensionSeparator = '.';
            return this.filename
                .split(extensionSeparator)
                .slice(0, -1)
                .join(extensionSeparator);
        }

        return '';
    }

    get lastUpdatedAt() {
        const assetVersionsDates = this.assetVersions.map((assetVersion) => assetVersion.createdAt);
        return new Date(Math.max.apply(null, assetVersionsDates));
    }

    toJSON() {
        /* eslint-disable-next-line camelcase */
        const media_group = this.mediaGroup && this.mediaGroup.toJSON();

        return {
            id: this.id,
            filename: this.filename,
            description: this.description,
            asset_versions: this.assetVersions.map(
                (assetVersion) => assetVersion.toJSON(assetVersion),
            ),
            project_id: this.projectId,
            media_group,
        };
    }

    static fromJSON(data) {
        const assetVersions = data.asset_versions && data.asset_versions.map(
            (assetVersion) => assetVersion && AssetVersion.fromJSON(assetVersion),
        );
        const mediaGroup = data.media_group && MediaGroup.fromJSON(data.media_group);

        return new Asset({
            id: data.id,
            filename: data.filename,
            description: data.description,
            projectId: data.project_id,
            assetVersions,
            mediaGroup,
        });
    }

    addAssetVersion({assetVersion}) {
        this.assetVersions.push(assetVersion);
    }
}
