import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {DashboardCard} from '../DashboardCard';
import {ActivityList} from '../ActivityList';

import {useStyles} from './ActivitySection.styles';
import {KebabMenu} from '../KebabMenu';
import ProjectsPresenter from '../../presenters/ProjectsPresenter';

const ActivitySection = ({presenter}) => {
    const classes = useStyles();
    const showEmptyMessage = presenter.activityPresenters.length === 0;
    const showMarkAllAsSeenButton = presenter.activityPresenters.some((p) => !p.isSeen);

    return (
        <DashboardCard
            className={classes.activitySection}
            scrollable={!showEmptyMessage}
            header="Activity"
            headerActions={showMarkAllAsSeenButton && (
                <div className={classes.menu}>
                    <KebabMenu
                        theme={'dark'}
                        position={'left'}
                        options={[{
                            key: 'mark',
                            label: 'Mark all as seen',
                            onClick: presenter.markAllActivitiesAsSeenButtonWasClicked,
                        }]}
                    />
                </div>
            )}
        >
            {!showEmptyMessage && (
                <ActivityList
                    activityPresenters={presenter.activityPresenters}
                />
            )}
            {showEmptyMessage && (
                <div className={classes.empty}>
                    Crickets for now, but all your activity will show up here soon
                </div>
            )}
        </DashboardCard>
    );
};

ActivitySection.propTypes = {
    presenter: PropTypes.instanceOf(ProjectsPresenter).isRequired,
};

export default observer(ActivitySection);
