import React from 'react';
import ToastService from '../services/ToastService';

const ToastContext = React.createContext(null);

export const ToastProvider = ({children}) => {
    const toastService = new ToastService();
    return (
        <ToastContext.Provider value={toastService}>
            {children}
        </ToastContext.Provider>
    );
};

export const useToastService = () => React.useContext(ToastContext);
