import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {useStyles} from './CommentSidebar.styles';
import {CommentList} from '../CommentList';
import {CommentBar} from '../CommentBar';
import {ProjectTeamList} from '../ProjectTeamList';
import CommentSectionPresenter from '../../presenters/CommentSectionPresenter';

const CommentSidebar = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {!!presenter && (
                <>
                    <ProjectTeamList presenter={presenter.projectTeamListPresenter} />
                    <div className={classes.commentListContainer}>
                        <CommentList
                            presenter={presenter}
                            selectedVersionId={presenter.getSelectedAssetVersionId()}
                        />
                    </div>
                    <CommentBar presenter={presenter} />
                </>
            )}
        </div>
    );
};

CommentSidebar.propTypes = {
    presenter: PropTypes.instanceOf(CommentSectionPresenter),
};

CommentSidebar.defaultProps = {
    presenter: null,
};

export default observer(CommentSidebar);
