/* eslint-disable react/no-array-index-key */
import {
    observer,
} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
    pdfjs,
} from 'react-pdf';
import {
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';

import {
    Document,
    Page,
} from 'react-pdf/dist/esm/entry.webpack';

import classes from '../PdfDisplay.module.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import PdfDisplayPresenter from '../../../presenters/PdfDisplayPresenter';
import PreLoader from '../../PreLoader/PreLoader';
import Scroll from '../../Scroll/Scroll';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfDisplay = ({presenter}) => {
    const containerRef = useRef();
    const [
        containerWidth,
        setContainerWidth,
    ] = useState(0);
    const [
        containerHeight,
        setContainerHeight,
    ] = useState(0);
    const [
        outsideWidth,
        setOutsideWidth,
    ] = useState(0);

    const onScroll = useCallback(() => {
        const currentPage = [...containerRef.current.querySelectorAll('[data-page]')].reduce((acc, pageElement) => {
            const rect = pageElement.getBoundingClientRect();
            if (rect.y <= containerHeight * 0.7) {
                return acc + 1;
            }

            return acc;
        }, 0);
        presenter.setPageNumber(currentPage);
    }, [
        containerHeight,
        containerRef,
        presenter,
    ]);

    useEffect(() => {
        const getContainerSize = () => {
            const containerWidthValue = containerRef.current.offsetWidth;
            const containerHeightValue = containerRef.current.offsetHeight;

            if (!containerWidth && containerWidthValue) {
                setContainerWidth(containerWidthValue);
                setOutsideWidth(window.innerWidth - containerWidthValue);
            } else {
                setContainerWidth(window.innerWidth - outsideWidth);
            }

            setContainerHeight(containerHeightValue);
        };

        getContainerSize();

        window.addEventListener('resize', getContainerSize);
        window.addEventListener('orientationchange', getContainerSize);

        return () => {
            window.removeEventListener('resize', getContainerSize);
            window.removeEventListener('orientationchange', getContainerSize);
        };
    }, [
        containerRef,
        presenter.loaded,
        containerWidth,
        containerHeight,
        outsideWidth,
    ]);

    return (
        <Scroll
            className={classes.inner}
            theme={'dark'}
            onScroll={onScroll}
        >
            <div
                className={classes.container}
                ref={containerRef}
            >
                {!presenter.loaded && (
                    <div className={classes.loading}>
                        <PreLoader />
                    </div>
                )}
                <div className={classes.document}>
                    {containerWidth > 0 && (
                        <Document
                            renderMode={'canvas'}
                            file={presenter.file}
                            onLoadSuccess={presenter.onDocumentLoadSuccess}
                            loading={''}
                        >
                            {Array.from({length: presenter.numberOfPages}).map((__, pageNumber) => (
                                <div
                                    key={String(pageNumber)}
                                    className={classes.page}
                                    data-page={pageNumber}
                                >
                                    <Page
                                        renderAnnotationLayer={false}
                                        pageNumber={pageNumber + 1}
                                        width={containerWidth}
                                        loading={''}
                                    />
                                </div>
                            ))}
                        </Document>
                    )}
                </div>
            </div>
        </Scroll>
    );
};

PdfDisplay.propTypes = {
    presenter: PropTypes.instanceOf(PdfDisplayPresenter).isRequired,
};

export default observer(PdfDisplay);
