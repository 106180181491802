import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import classes from './ModalBody.module.scss';

const ModalBody = ({children, className, scrollable}) => (
    <div className={clsx(classes.root, className, scrollable && classes.scrollable)}>
        {children}
    </div>
);

ModalBody.propTypes = {
    className: PropTypes.string,
    scrollable: PropTypes.bool,
};

ModalBody.defaultProps = {
    className: '',
    scrollable: false,
};

export default observer(ModalBody);
