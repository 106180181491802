import {makeStyles} from '@material-ui/core/styles';
import {fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        minWidth: '625px',
    },

    confirmationBody: {
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            ...fonts.smallTitle1,
        },
    },
}));
