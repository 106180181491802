export default class CreateMediaGroup {
    constructor({service, projectStore, mediaGroupStore}) {
        this.service = service;
        this.projectStore = projectStore;
        this.mediaGroupStore = mediaGroupStore;
    }

    async execute({name, projectId}) {
        const mediaGroup = await this.service.create({name, projectId});
        this.mediaGroupStore.prepend(mediaGroup);

        const project = this.projectStore.find(projectId);
        project.addMediaGroup(mediaGroup);
        this.projectStore.update(projectId, project);

        return mediaGroup;
    }
}
