import {makeAutoObservable} from 'mobx';

export default class AudioDisplayPresenter {
    constructor({fileUrl}) {
        this.fileUrl = fileUrl;

        makeAutoObservable(this);
    }

    get type() {
        return 'audio';
    }
}
