import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import PersonIcon from '../Icons/PersonIcon';
import ClockIcon from '../Icons/ClockIcon';

import {PROJECTS_PATH} from '../../routes/paths';
import DateToDisplay from '../../presenters/ToDisplay/DateToDisplay';
import ProjectsPresenter from '../../presenters/ProjectsPresenter';
import KebabMenu from '../KebabMenu/KebabMenu';
import EditIcon from '../Icons/EditIcon';
import BinIcon from '../Icons/BinIcon';

import classes from './ProjectList.module.scss';

const ProjectCard = ({
    presenter,
    project,
}) => {
    const projectThumbnailPresenter = presenter.projectThumbnailPresenter(project);
    const thumbnail = projectThumbnailPresenter.isDefaultThumbnail(projectThumbnailPresenter.thumbnail)
        ? null
        : projectThumbnailPresenter.thumbnail;
    const dateToDisplay = new DateToDisplay({
        date: project.lastUpdatedAt,
    });

    const timeToDisplay = dateToDisplay.displayTimeAgo;
    const hasUnreadActivities = dateToDisplay.dateIsInTheLast24HoursInclusive;
    const displayActions = presenter.isCurrentUserTheOwner(project);
    const className = clsx(
        classes.projectItem,
        displayActions && classes.projectItemActionable,
    );

    return (
        <Link
            to={`${PROJECTS_PATH}/${project.id}`}
            className={className}
            data-testid={`project-link-${project.id}`}
        >
            {hasUnreadActivities && (
                <div
                    className={classes.projectNotificationMarker}
                    data-testid="mention-notification"
                />
            )}
            <div className={classes.projectItemInner}>
                <div className={classes.projectItemThumbnail}>
                    {thumbnail && (
                        <img
                            className={classes.projectItemImageThumbnail}
                            src={thumbnail}
                            alt=""
                        />
                    )}
                    {!thumbnail && (
                        <div className={classes.projectItemEmptyThumbnail}>
                            No posts yet
                        </div>
                    )}
                </div>
                <div className={classes.projectItemInfo}>
                    <h3
                        className={classes.projectItemTitle}
                        title={project.name}
                    >
                        {project.name}
                    </h3>
                    <div className={classes.projectItemExtra}>
                        <div className={classes.projectItemMeta}>
                            <PersonIcon />
                            <p>{project.membersAmount}</p>
                            <ClockIcon />
                            <p>{timeToDisplay}</p>
                        </div>
                        {displayActions && (
                            <div className={classes.projectItemMenu}>
                                <KebabMenu
                                    theme={'dark'}
                                    options={[
                                        {
                                            key: 'rename',
                                            icon: <EditIcon />,
                                            label: 'Rename',
                                            onClick: () => presenter.renameProject(project.id),
                                        },
                                        {
                                            key: 'delete',
                                            icon: <BinIcon />,
                                            label: 'Delete project',
                                            onClick: () => presenter.deleteProject(project.id),
                                        },
                                    ]}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Link>
    );
};

ProjectCard.propTypes = {
    presenter: PropTypes.instanceOf(ProjectsPresenter).isRequired,
    project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        membersAmount: PropTypes.number.isRequired,
        lastUpdatedAt: PropTypes.any.isRequired,
    }).isRequired,
};

export default observer(ProjectCard);
