import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
    createRef,
    useEffect,
} from 'react';

import {Dropzone} from '../Dropzone';

import classes from './FileUploader.module.scss';
import FileUploadPresenter from '../../presenters/FileUploadPresenter';
import {
    FILE_UPLOAD_STATE,
    FILE_UPLOAD_STATUS,
} from '../../constants/fileUpload';
import BinIcon from '../Icons/BinIcon';

function readableFileSize(bytes, decimals = 1) {
    const sizes = [
        'b',
        'Kb',
        'Mb',
        'Gb',
    ];
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    if (bytes === 0) {
        return `0${sizes[0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
}

const FileUploader = ({
    presenter,
    onSelectFiles,
    openFileManager,
    dropzoneProps,
}) => {
    const dropzoneRef = createRef();

    const showDropzone = !presenter.hasFilesToUpload || presenter.maxFiles > 1;
    const showFiles = presenter.hasFilesToUpload;

    useEffect(() => {
        if (openFileManager && dropzoneRef.current) {
            dropzoneRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dropzoneRef?.current,
    ]);

    return (
        <div className={classes.root}>
            {showDropzone && (
                <div className={clsx(openFileManager && classes.invisibleDropzone)}>
                    <Dropzone
                        disabled={presenter.isPosting}
                        accept={presenter.acceptedFiles}
                        addFiles={presenter.addFiles}
                        maxFiles={presenter.maxFiles}
                        validator={presenter.validator}
                        onSelect={onSelectFiles}
                        dropzoneProps={{
                            ref: dropzoneRef,
                            ...dropzoneProps,
                        }}
                    />
                </div>
            )}
            {showFiles && (
                <div className={classes.files}>
                    {presenter.filesArray.map(({
                        file,
                        state,
                        status,
                        payload,
                    }) => {
                        let fileStatus;
                        let fileMeta = ` — ${readableFileSize(file.size)}`;
                        const isDeletable = presenter.maxFiles > 1
                            && !presenter.isPosting
                            && state === FILE_UPLOAD_STATE.DONE
                            && status === FILE_UPLOAD_STATUS.SUCCESS;

                        switch (true) {
                        default:
                            fileStatus = '';
                            break;
                        case state === FILE_UPLOAD_STATE.PROGRESS:
                            fileStatus = `${payload}%`;
                            break;
                        case status === FILE_UPLOAD_STATUS.SUCCESS:
                            fileStatus = (
                                <div className={classes.uploadSuccess} />
                            );
                            break;
                        case status === FILE_UPLOAD_STATUS.FAIL:
                            fileStatus = (
                                <div className={classes.uploadFailed} />
                            );
                            fileMeta = 'upload failed';
                            break;
                        }

                        return (
                            <div
                                key={file.name}
                                className={classes.file}
                            >
                                <div className={classes.fileStatus}>
                                    {fileStatus}
                                </div>
                                <div className={classes.fileName}>
                                    {file.name}
                                </div>
                                <div>
                                    {fileMeta}
                                </div>
                                {isDeletable && (
                                    <div
                                        className={classes.deleteFile}
                                        onClick={() => presenter.deleteFile(file.name)}
                                    >
                                        <BinIcon />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

FileUploader.propTypes = {
    onSelectFiles: PropTypes.func.isRequired,
    presenter: PropTypes.instanceOf(FileUploadPresenter).isRequired,
    openFileManager: PropTypes.bool,
    dropzoneProps: PropTypes.shape({}),
};

FileUploader.defaultProps = {
    openFileManager: false,
    dropzoneProps: {},
};

export default observer(FileUploader);
