import {
    observer,
} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import classes from './ProfileSection.module.scss';
import Checkbox from '../Checkbox/Checkbox';
import {InputText} from '../InputText';
import {
    CONFIRM_PASSWORD,
    CURRENT_PASSWORD,
    FIRST_NAME,
    LAST_NAME,
    MAIL_NOTIFICATIONS_COMMENTS,
    MAIL_NOTIFICATIONS_MENTIONS,
    NEW_PASSWORD,
} from '../../forms/profileFields';
import InputTextWithLabel from '../InputTextWithLabel/InputTextWithLabel';
import {PrimaryButton} from '../PrimaryButton';
import ProfilePresenter from '../../presenters/ProfilePresenter';

const ProfileSection = ({presenter}) => (
    <div className={classes.root}>
        <div className={classes.section}>
            <div className={classes.sectionHeader}>
                Personal info
            </div>
            <div className={classes.sectionContent}>
                <div className={classes.sectionRow}>
                    {presenter.currentForm !== presenter.formsEnum.name && (
                        <div className={classes.display}>
                            <div className={classes.displayLabel}>Name</div>
                            <div className={classes.displayEditable}>
                                <div className={classes.displayValue}>{presenter.currentUser?.fullName}</div>
                                <div
                                    className={classes.secondaryButton}
                                    onClick={() => presenter.showNameForm()}
                                >
                                    Edit
                                </div>
                            </div>
                        </div>
                    )}
                    {presenter.currentForm === presenter.formsEnum.name && (
                        <div className={classes.formRow}>
                            <div className={classes.formFields}>
                                <div className={classes.formField}>
                                    <InputTextWithLabel
                                        errorMessage={presenter.nameForm.$(FIRST_NAME).error}
                                        {...presenter.nameForm.$(FIRST_NAME).bind()}
                                    />
                                </div>
                                <div className={classes.formField}>
                                    <InputTextWithLabel
                                        errorMessage={presenter.nameForm.$(LAST_NAME).error}
                                        {...presenter.nameForm.$(LAST_NAME).bind()}
                                    />
                                </div>
                            </div>
                            <div className={classes.formActions}>
                                <PrimaryButton
                                    onClick={presenter.nameForm.onSubmit}
                                    disabled={presenter.formSubmitDisabled}
                                >
                                    Save
                                </PrimaryButton>
                                <div
                                    className={classes.secondaryButton}
                                    onClick={() => presenter.closeForm()}
                                >
                                    Cancel
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.sectionRow}>
                    <div className={classes.display}>
                        <div className={classes.displayLabel}>Email</div>
                        <div className={classes.displayValue}>
                            <InputText
                                id={'email'}
                                value={presenter.currentUser?.email}
                                onChange={() => {}}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.sectionRow}>
                    {presenter.currentForm !== presenter.formsEnum.password && (
                        <div className={classes.display}>
                            <div className={classes.displayLabel}>
                                Password
                            </div>
                            <div className={classes.displayEditable}>
                                {presenter.passwordChangedMessage && (
                                    <div className={classes.displayMessage}>
                                        {presenter.passwordChangedMessage}
                                    </div>
                                )}
                                {!presenter.passwordChangedMessage && (
                                    <div className={classes.displayValue}>
                                        <div className={classes.displayTip}>
                                            It is a good idea to use a strong password
                                            <br />
                                            that you are not using elsewhere
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={classes.secondaryButton}
                                    onClick={() => presenter.showPasswordForm()}
                                >
                                    Edit
                                </div>
                            </div>
                        </div>
                    )}
                    {presenter.currentForm === presenter.formsEnum.password && (
                        <div className={classes.formColumn}>
                            <div className={classes.formFields}>
                                <div className={classes.formField}>
                                    <InputTextWithLabel
                                        errorMessage={presenter.passwordForm.$(CURRENT_PASSWORD).error}
                                        {...presenter.passwordForm.$(CURRENT_PASSWORD).bind()}
                                    />
                                </div>
                                <div className={classes.formField}>
                                    <InputTextWithLabel
                                        errorMessage={presenter.passwordForm.$(NEW_PASSWORD).error}
                                        {...presenter.passwordForm.$(NEW_PASSWORD).bind()}
                                    />
                                </div>
                                <div className={classes.formField}>
                                    <InputTextWithLabel
                                        errorMessage={presenter.passwordForm.$(CONFIRM_PASSWORD).error}
                                        {...presenter.passwordForm.$(CONFIRM_PASSWORD).bind()}
                                    />
                                </div>
                            </div>
                            <div className={classes.formActions}>
                                <PrimaryButton
                                    onClick={presenter.passwordForm.onSubmit}
                                    disabled={presenter.formSubmitDisabled}
                                >
                                    Update
                                </PrimaryButton>
                                <div
                                    className={classes.secondaryButton}
                                    onClick={() => presenter.closeForm()}
                                >
                                    Cancel
                                </div>
                                {presenter.formError && (
                                    <div className={classes.formError}>
                                        {presenter.formError}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className={classes.section}>
            <div className={classes.sectionHeader}>
                Notifications
            </div>
            <div className={classes.sectionContent}>
                <div className={classes.notifications}>
                    <div className={classes.notificationRow}>Send email:</div>
                    <div className={classes.notificationRow}>
                        <Checkbox
                            checked={presenter.currentUser?.mailMentions}
                            label={'Mentions'}
                            id={'notifications-mentions'}
                            onChange={(value) => presenter.changeNotificationsSettings(MAIL_NOTIFICATIONS_MENTIONS, value)}
                        />
                    </div>
                    <div className={classes.notificationRow}>
                        <Checkbox
                            checked={presenter.currentUser?.mailComments}
                            label={'Comments to others'}
                            id={'notifications-comments'}
                            onChange={(value) => presenter.changeNotificationsSettings(MAIL_NOTIFICATIONS_COMMENTS, value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

ProfileSection.propTypes = {
    presenter: PropTypes.instanceOf(ProfilePresenter).isRequired,
};

ProfileSection.defaultProps = {
};

export default observer(ProfileSection);
