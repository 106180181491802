import writtenNumber from 'written-number';
import {toCapitalized} from '../../helpers/switchCase';

export default class NumberToDisplay {
    constructor({number}) {
        this.number = number;
    }

    get displayCapitalized() {
        return toCapitalized(this._numberToWord);
    }

    get displayLowercase() {
        return this._numberToWord;
    }

    get _numberToWord() {
        return writtenNumber(this.number);
    }
}
