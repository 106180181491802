import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        ...fonts.buttonText,

        textTransform: 'none',
        display: 'flex',
        backgroundColor: 'transparent',
        color: palette.borderButtonColor,
        padding: '6px 16px',
        borderRadius: '22.5px',
        boxShadow: 'none',
        border: `solid 1px ${palette.borderButtonColor}`,

        '&.small': {
            ...fonts.buttonTextSmall,

            height: '24px',
        },

        '&.normal': {
            ...fonts.buttonText,

            height: '32px',
        },

        '&:hover': {
            boxShadow: '0 4px 10px 0 rgba(67, 85, 176, 0.45)',
            backgroundColor: 'transparent',
        },
    },
}));
