import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    sidebar: {
        width: '100px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'space-between',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        padding: '100px 10px 24px',
    },

    logOutLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',

        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        '& p': {
            marginTop: 0,
            marginBottom: 0,
        },
    },
}));
