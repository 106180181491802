import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {VersionDropdown} from '../../../components/VersionDropdown';
import {PrimaryButton} from '../../../components/PrimaryButton';
import {KebabMenu} from '../../../components/KebabMenu';

import classes from './AssetHeader.module.scss';
import AssetDetailPresenter from '../../../presenters/AssetDetailPresenter';
import BinIcon from '../../../components/Icons/BinIcon';
import DownloadIcon from '../../../components/Icons/DownloadIcon';
import UploadIcon from '../../../components/Icons/UploadIcon';

const AssetHeader = ({presenter}) => (
    <div className={classes.header}>
        <h1
            className={classes.title}
            title={presenter.assetName}
        >
            {presenter.assetName}
        </h1>
        <div className={classes.versionManagement}>
            {!presenter.isLatestVersionSelected && (
                <PrimaryButton
                    size={'small'}
                    onClick={presenter.selectLatestVersion}
                >
                    See latest update
                </PrimaryButton>
            )}
            <div className={classes.versionControl}>
                <VersionDropdown
                    selectedVersion={presenter.selectedAssetVersion}
                    onVersionSelected={presenter.assetVersionWasClicked}
                    versions={presenter.assetVersions}
                />
            </div>
            <KebabMenu
                theme={'light'}
                options={[
                    {
                        key: 'upload',
                        icon: <UploadIcon theme={'dark'} />,
                        label: 'Upload new version',
                        onClick: presenter.openUploadModal,
                    },
                    {
                        key: 'download',
                        icon: <DownloadIcon />,
                        label: `Download Version ${presenter.selectedAssetVersionNumber}`,
                        onClick: presenter.downloadAsset,
                    },
                    {
                        key: 'delete',
                        icon: <BinIcon />,
                        label: 'Delete',
                        onClick: presenter.openDeleteModal,
                    },
                ]}
            />
        </div>
    </div>
);

AssetHeader.propTypes = {
    presenter: PropTypes.instanceOf(AssetDetailPresenter).isRequired,
};

export default observer(AssetHeader);
