import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import {ModalHeader} from '../ModalHeader';
import ModalFooter from '../ModalFooter/ModalFooter';
import {PrimaryButton} from '../PrimaryButton';
import {TextButton} from '../TextButton';
import {ModalCard} from '../ModalCard';

import {useStyles} from './ConfirmationModal.styles';
import ModalBody from '../ModalBody/ModalBody';

const ConfirmationModal = ({
    open,
    onClose,
    onConfirmationClick,
    title,
    body,
    confirm,
    ...rest
}) => {
    const classes = useStyles();

    const confirmClick = () => {
        onConfirmationClick();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            {...rest}
        >
            <ModalCard className={classes.root}>
                <ModalHeader>
                    <h1>{title}</h1>
                </ModalHeader>
                <ModalBody className={classes.confirmationBody}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <p dangerouslySetInnerHTML={{__html: body}} />
                </ModalBody>
                <ModalFooter>
                    <TextButton onClick={onClose}>
                        Cancel
                    </TextButton>
                    <PrimaryButton
                        danger
                        onClick={confirmClick}
                    >
                        {confirm}
                    </PrimaryButton>
                </ModalFooter>
            </ModalCard>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirmationClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    confirm: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    open: false,
    onConfirmationClick: null,
    confirm: 'Confirm',
};

export default ConfirmationModal;
