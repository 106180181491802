import {makeAutoObservable} from 'mobx';

export default class MediaGroupTeamListSectionPresenter {
    constructor({
        projectId,
        getProjectMembersFromStore,
        fetchProjectMembersFromRemote,
        projectMemberAvatarButtonWasClicked,
    } = {}) {
        this.projectId = projectId;
        this.numberOfAvatarsShown = 4;

        this.getProjectMembersFromStore = getProjectMembersFromStore;
        this.fetchProjectMembersFromRemote = fetchProjectMembersFromRemote;

        this.projectMemberAvatarButtonWasClicked = projectMemberAvatarButtonWasClicked;

        this._fetchProjectMembers();

        makeAutoObservable(this);
    }

    get projectMembers() {
        return this.getProjectMembersFromStore.execute(this.projectId);
    }

    async _fetchProjectMembers() {
        try {
            await this.fetchProjectMembersFromRemote.execute(this.projectId);
        } catch {} // eslint-disable-line no-empty
    }
}
