import {useEffect, useState} from 'react';
import AssetDetailPresenter from '../presenters/AssetDetailPresenter';
import FetchEntityFromRemote from '../interactors/FetchEntityFromRemote';
import GetEntitiesByIdFromStore from '../interactors/GetEntitiesByIdFromStore';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import {useToastService} from '../providers/ToastProvider';
import Api from '../services/Api';
import GetEntityFromStoreFactory from '../interactors/GetEntityFromStoreFactory';
import UploadFile from '../interactors/UploadFile';
import DirectFileUpload from '../services/DirectFileUpload';
import CreateAsset from '../interactors/CreateAsset';
import CreateAssetVersion from '../interactors/CreateAssetVersion';
import EntityServiceFactory from '../services/EntityServiceFactory';
import FetchFileFromRemote from '../interactors/FetchFileFromRemote';
import FetchProjectMembersFromRemote from '../interactors/FetchProjectMembersFromRemote';
import FileService from '../services/FileService';
import MediaDisplayPresenterFactory from '../factories/MediaDisplayPresenterFactory';
import FetchCommentsFromRemote from '../interactors/FetchCommentsFromRemote';
import GetCurrentUserFromStore from '../interactors/GetCurrentUserFromStore';
import CreateComment from '../interactors/CreateComment';
import GetProjectMembersFromStore from '../interactors/GetProjectMembersFromStore';
import {useModalService} from '../providers/ModalProvider';
import ArchiveAsset from '../interactors/ArchiveAsset';

const useAssetDetailWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();
    const toastService = useToastService();
    const modalService = useModalService();

    useEffect(() => {
        const {
            authStore,
            assetStore,
            commentStore,
            userStore,
            projectMemberStore,
            mediaGroupStore,
        } = root;

        const authApi = new Api({authStore});

        const assetService = EntityServiceFactory.forAssets({api: authApi});

        const projectService = EntityServiceFactory.forProjects(
            {api: authApi},
        );

        const fileService = new FileService();

        const getAssetFromStore = GetEntityFromStoreFactory.forAssets(
            {store: assetStore},
        );

        const fetchAssetFromRemote = new FetchEntityFromRemote({
            service: assetService,
            store: assetStore,
        });

        const fetchFileFromRemote = new FetchFileFromRemote({
            service: fileService,
        });

        const uploadFile = new UploadFile({
            authStore,
            uploader: DirectFileUpload,
            service: assetService,
        });

        const createAsset = new CreateAsset({service: assetService});
        const createAssetVersion = new CreateAssetVersion({
            assetService,
            assetStore,
        });

        const mediaDisplayPresenterFactory = new MediaDisplayPresenterFactory({
            fetchFileFromRemote,
        });

        const assetVersionService = EntityServiceFactory.forAssetVersions(
            {api: authApi},
        );
        const getCommentsFromStore = new GetEntitiesByIdFromStore({store: commentStore});
        const fetchCommentsFromRemote = new FetchCommentsFromRemote({
            commentStore,
            assetService,
        });

        const getCurrentUserFromStore = new GetCurrentUserFromStore({store: userStore});
        const getUserFromStore = GetEntityFromStoreFactory.forUsers(
            {store: userStore},
        );

        const createComment = new CreateComment({
            assetVersionService,
            commentStore,
        });

        const getProjectMembersFromStore = new GetProjectMembersFromStore({
            projectMemberStore,
        });

        const fetchProjectMembersFromRemote = new FetchProjectMembersFromRemote({
            projectMemberStore,
            projectService,
        });

        const archiveAsset = new ArchiveAsset({
            assetStore,
            assetService,
            mediaGroupStore,
        });

        const presenter = new AssetDetailPresenter({
            router,
            modalService,
            getAssetFromStore,
            fetchAssetFromRemote,
            mediaDisplayPresenterFactory,
            uploadFile,
            createAsset,
            createAssetVersion,
            getCommentsFromStore,
            fetchCommentsFromRemote,
            getCurrentUserFromStore,
            getUserFromStore,
            getProjectMembersFromStore,
            fetchProjectMembersFromRemote,
            createComment,
            toastService,
            archiveAsset,
        });

        setPresenterInstance(presenter);
    }, [root, router, toastService, modalService]);

    return presenterInstance;
};

export default useAssetDetailWireframe;
