export default class MarkMentionsAsSeen {
    constructor({mentionService, mentionStore}) {
        this.mentionService = mentionService;
        this.mentionStore = mentionStore;
    }

    async execute() {
        this.mentionStore.markAsSeenAll();
        return this.mentionService.markAsSeenAll();
    }

    async markAsSeenById(mentionId) {
        this.mentionStore.markAsSeenById(mentionId);
        return this.mentionService.markAsSeenById(mentionId);
    }
}
