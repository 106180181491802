import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#000',
        width: '350px',
        minWidth: '350px',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
    },

    commentListContainer: {
        flexGrow: 1,
        backgroundColor: '#202325',
        borderRadius: '8px',
        overflowY: 'auto',
    },
}));
