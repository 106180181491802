import {observer} from 'mobx-react-lite';
import {
    useRef,
} from 'react';
import useProjectDetailWireframe from '../../wireframes/useProjectDetailWireframe';
import {MediaGroupList} from '../../components/MediaGroupList';
import {FeedHeader} from '../../components/FeedHeader';
import {ProjectInformation} from '../../components/ProjectInformation';
import {Breadcrumbs} from '../../components/Breadcrumbs';
import {ProjectTeamSection} from '../../components/ProjectTeamSection';
import {Sidebar} from '../../components/Sidebar';
import FolderIcon from '../../components/Icons/FolderIcon';

import classes from './ProjectDetail.module.scss';
import useCalculatePreviewLayout from '../../helpers/calculatePreviewsLayout';

const COLUMN_WIDTH = 288;
const INFO_WIDTH = 380 + 35;

const ProjectDetail = () => {
    const presenter = useProjectDetailWireframe();
    const layoutRef = useRef();
    const [
        columnsCount,
    ] = useCalculatePreviewLayout({
        presenter,
        columnWidth: COLUMN_WIDTH,
        asideWidth: INFO_WIDTH,
        ref: layoutRef,
    });

    return !!presenter && (
        <div className={classes.root}>
            <Sidebar
                presenter={presenter.sidebarPresenter}
            />
            <div className={classes.breadcrumbs}>
                <Breadcrumbs
                    breadcrumbs={presenter.breadcrumbs}
                    theme={'dark'}
                />
            </div>
            <div
                className={classes.layout}
                ref={layoutRef}
            >
                <div
                    className={classes.wrapper}
                    style={{
                        width: columnsCount > 0 && `${columnsCount * COLUMN_WIDTH}px`,
                        minWidth: `${COLUMN_WIDTH * 2}px`,
                    }}
                >
                    <div className={classes.header}>
                        <FeedHeader
                            title={presenter.projectName}
                            secondary={{
                                icon: <FolderIcon />,
                                name: 'New Folder',
                                onClick: presenter.createFolderButtonWasClicked,
                            }}
                        />
                    </div>
                    <div className={classes.items}>
                        <MediaGroupList
                            presenter={presenter}
                        />
                    </div>
                </div>
                <div className={classes.info}>
                    {!!presenter.projectTeamSectionPresenter && (
                        <ProjectTeamSection presenter={presenter.projectTeamSectionPresenter} />
                    )}
                    <ProjectInformation presenter={presenter} />
                </div>
            </div>
        </div>
    );
};

export default observer(ProjectDetail);
