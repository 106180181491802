import PropTypes from 'prop-types';
import {SignUpForm} from '../SignUpForm';

import {useStyles} from './SignUpSection.styles';
import LargeLogo from '../LargeLogo/LargeLogo';
import SignUpPresenter from '../../presenters/SignUpPresenter';

const SignUpSection = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.SignUpSection}>
            <div className={classes.logo}>
                <LargeLogo />
            </div>
            <h1 className={classes.header}>Create account</h1>
            <SignUpForm presenter={presenter} />
        </div>
    );
};

SignUpSection.propTypes = {
    presenter: PropTypes.instanceOf(SignUpPresenter).isRequired,
};

export default SignUpSection;
