import {palette, fonts} from '../../Theme';

export const defaultStyle = {
    bgcolor: palette.secondary,
    color: palette.textLight,
    border: 'none !important',
    width: '30px',
    height: '30px',
    ...fonts.smallTitle2,

    fontSize: '14px !important',
};

export const avatarPalette = [
    '#6cbfd3',
    '#ea7979',
    '#fbbd92',
    '#735be5',
    '#6c9dd3',
];
