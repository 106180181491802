import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Member from './Member';
import ProjectMember from '../../../entities/ProjectMember';

import {useStyles} from '../TeamModal.styles';

const MemberList = ({
    members,
    removeMemberButtonWasClicked,
    isCurrentUserTheOwner,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.members}>
            {
                members?.map((member) => {
                    const removeMemberButton = () => removeMemberButtonWasClicked(member);
                    return (
                        <Member
                            user={member.user}
                            key={member.id}
                            removeMemberButtonWasClicked={removeMemberButton}
                            isCurrentUserTheOwner={isCurrentUserTheOwner}
                        />
                    );
                })
            }
        </div>
    );
};

MemberList.propTypes = {
    members: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMember)).isRequired,
    removeMemberButtonWasClicked: PropTypes.func,
    isCurrentUserTheOwner: PropTypes.bool,
};

MemberList.defaultProps = {
    removeMemberButtonWasClicked: null,
    isCurrentUserTheOwner: false,
};

export default observer(MemberList);
