import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import {ModalHeader} from '../ModalHeader';
import ModalFooter from '../ModalFooter/ModalFooter';
import {ModalCard} from '../ModalCard';
import {InputText} from '../InputText';
import {PrimaryButton} from '../PrimaryButton';

import {useStyles} from './EditFieldModal.styles';
import ModalBody from '../ModalBody/ModalBody';
import {TextButton} from '../TextButton';

const EditFieldModal = ({
    open,
    onClose,
    onConfirm,
    title,
    message,
    inputs,
    cancelButton,
    submitButton,
    submitDisabled,
    ...rest
}) => {
    const classes = useStyles();
    const submitButtonDisabled = typeof submitDisabled === 'function'
        ? submitDisabled()
        : Boolean(submitDisabled);

    return (
        <Modal
            open={open}
            onClose={onClose}
            {...rest}
        >
            {open && (
                <ModalCard className={classes.root}>
                    <ModalHeader>
                        <h1>{title}</h1>
                    </ModalHeader>
                    <ModalBody>
                        <div className={classes.form}>
                            {Object.entries(inputs).map(([key, {multiline, control}], index) => (
                                <div
                                    key={key}
                                    className={multiline ? classes.textarea : classes.input}
                                >
                                    {control.label && (
                                        <div className={classes.label}>
                                            {control.label}
                                        </div>
                                    )}
                                    <InputText
                                        errorMessage={control.error}
                                        className={classes.control}
                                        multiline={multiline}
                                        autoFocus={index === 0}
                                        {...control.bind()}
                                    />
                                </div>
                            ))}
                        </div>
                        {!!message && (
                            <div className={classes.message}>
                                {message}
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {cancelButton && (
                            <TextButton onClick={onClose}>
                                {cancelButton}
                            </TextButton>
                        )}
                        {submitButton && (
                            <PrimaryButton
                                onClick={onConfirm}
                                disabled={submitButtonDisabled}
                            >
                                {submitButton}
                            </PrimaryButton>
                        )}
                    </ModalFooter>
                </ModalCard>
            )}
        </Modal>
    );
};

EditFieldModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    cancelButton: PropTypes.string,
    submitButton: PropTypes.string,
    submitDisabled: PropTypes.any,
    message: PropTypes.string,
    inputs: PropTypes.any.isRequired,
};

EditFieldModal.defaultProps = {
    open: false,
    cancelButton: '',
    submitButton: 'Save',
    submitDisabled: false,
    message: '',
};

export default observer(EditFieldModal);
