import {observer} from 'mobx-react-lite';
import useProjectsWireframe from '../../wireframes/useProjectsWireframe';
import {MentionSection} from '../../components/MentionSection';
import {ProjectsSection} from '../../components/ProjectsSection';
import {ActivitySection} from '../../components/ActivitySection';
import {Sidebar} from '../../components/Sidebar';
import Welcome from '../../components/Welcome/Welcome';

import classes from './Projects.module.scss';

const Projects = () => {
    const presenter = useProjectsWireframe();

    return !!presenter && (
        <div className={classes.root}>
            <div className={classes.sidebar}>
                <Sidebar
                    presenter={presenter.sidebarPresenter}
                />
            </div>
            <div className={classes.layout}>
                <div className={classes.welcome}>
                    <Welcome
                        presenter={presenter}
                    />
                </div>
                <div className={classes.personal}>
                    <div className={classes.mentions}>
                        <MentionSection
                            presenter={presenter}
                        />
                    </div>
                    <div className={classes.activity}>
                        <ActivitySection
                            presenter={presenter}
                        />
                    </div>
                </div>
                <div className={classes.projects}>
                    <ProjectsSection
                        presenter={presenter}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(Projects);
