import {observer} from 'mobx-react-lite';
import useLogInWireframe from '../../wireframes/useLogInWireframe';
import {useStyles} from './Login.styles';
import {LogInSection} from '../../components/LogInSection';

const LogIn = () => {
    const presenter = useLogInWireframe();
    const classes = useStyles();

    return !!presenter && (
        <div className={classes.root}>
            <LogInSection presenter={presenter} />
        </div>
    );
};

LogIn.propTypes = {
};

LogIn.defaultProps = {
};

export default observer(LogIn);
