import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    FIRST_NAME,
    LAST_NAME,
} from '../../constants/formFields/signUpForm';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import InputTextWithLabel from '../InputTextWithLabel/InputTextWithLabel';
import styles from './SignUpForm.module.scss';
import SignUpPresenter from '../../presenters/SignUpPresenter';

const SignUpForm = ({presenter}) => (
    <>
        <div className={styles.passwordRow}>
            <div>
                <InputTextWithLabel
                    errorMessage={presenter.form.$(FIRST_NAME).error}
                    {...presenter.form.$(FIRST_NAME).bind()}
                />
            </div>
            <div>
                <InputTextWithLabel
                    errorMessage={presenter.form.$(LAST_NAME).error}
                    {...presenter.form.$(LAST_NAME).bind()}
                />
            </div>
        </div>
        <div>
            <InputTextWithLabel
                errorMessage={presenter.form.$(EMAIL).error}
                {...presenter.form.$(EMAIL).bind()}
            />
        </div>
        <div className={styles.passwordRow}>
            <div>
                <InputTextWithLabel
                    errorMessage={presenter.form.$(PASSWORD).error}
                    {...presenter.form.$(PASSWORD).bind()}
                />
            </div>
            <div>
                <InputTextWithLabel
                    errorMessage={presenter.form.$(CONFIRM_PASSWORD).error}
                    {...presenter.form.$(CONFIRM_PASSWORD).bind()}
                />
            </div>
        </div>
        <div className={styles.submitRow}>
            <PrimaryButton
                onClick={presenter.form.onSubmit}
                disabled={presenter.submitDisabled}
            >
                Create account
            </PrimaryButton>
            <span
                className={styles.secondaryButton}
                onClick={presenter.signInButtonWasClicked}
            >
                Sign in
            </span>
        </div>
    </>
);

SignUpForm.propTypes = {
    presenter: PropTypes.instanceOf(SignUpPresenter).isRequired,
};

export default observer(SignUpForm);
