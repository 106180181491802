import PropTypes from 'prop-types';

import {Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import clsx from 'clsx';

import {IconButton} from '../IconButton';

import {useStyles} from './ButtonMenu.styles';

const ButtonMenu = ({
    icon,
    options,
    className,
    position,
    dataTestId,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = !!anchorEl;

    const handleClick = (event) => {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            event.preventDefault();
            /* eslint-disable-next-line no-param-reassign */
            event.cancelBubble = true;
        }

        setAnchorEl(event.currentTarget);
    };

    const closeMenu = (event) => {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            event.preventDefault();
            /* eslint-disable-next-line no-param-reassign */
            event.cancelBubble = true;
        }
        setAnchorEl(null);
    };

    const createOptionOnClick = (event, onClick) => {
        closeMenu(event);
        onClick();
    };

    const anchorOrigin = {
        vertical: 'top',
        horizontal: position,
    };

    const transformOrigin = {
        vertical: 'top',
        horizontal: position === 'right' ? 'left' : 'right',
    };

    return (
        <div>
            <IconButton
                onClick={handleClick}
                className={clsx(classes.icon, className, open && classes.isOpen)}
                id={dataTestId}
            >
                {icon}
            </IconButton>
            <Menu
                classes={{
                    list: classes.list,
                    paper: classes.paper,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {options.map((option) => (
                    <MenuItem
                        classes={{root: classes.menuItem}}
                        className="menuItem"
                        key={option.key}
                        onClick={(event) => createOptionOnClick(event, option.onClick)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

ButtonMenu.propTypes = {
    icon: PropTypes.any.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.any.isRequired,
        onClick: PropTypes.func.isRequired,
    })),
    className: PropTypes.string,
    position: PropTypes.string,
    dataTestId: PropTypes.string,
};

ButtonMenu.defaultProps = {
    options: [],
    className: '',
    position: 'right',
    dataTestId: '',
};

export default ButtonMenu;
