/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Select} from '../Select';
import {InputTextLabel} from '../InputTextLabel';
import {EMAIL} from '../../constants/formFields/addMemberForm';
import Form from '../../forms/Form';
import User from '../../entities/User';
import UserOption from './components/UserOption';

import {useStyles, selectStyles} from './AddMemberForm.styles';
import {TextButton} from '../TextButton';
import {PrimaryButton} from '../PrimaryButton';
import ModalFooter from '../ModalFooter/ModalFooter';

const AddMemberForm = ({
    form,
    users,
    returnToPreviousModalButtonWasClicked,
}) => {
    const [
        inviteMemberButtonIsDisabled,
        setInviteMemberButtonIsDisabled,
    ] = useState(true);
    const classes = useStyles();

    const handleChange = (user) => {
        setInviteMemberButtonIsDisabled(user === null);
        form?.$(EMAIL).set(user?.email || '');
    };

    return (
        <>
            <div className={classes.addMemberForm}>
                <InputTextLabel text="User name" />
                <Select
                    options={users}
                    getOptionLabel={(user) => user.fullName}
                    getOptionValue={(user) => user.searchName}
                    placeholder=""
                    isClearable
                    onChange={handleChange}
                    styles={selectStyles}
                    components={{
                        Option: (props) => (
                            <UserOption
                                {...props}
                                user={props.data}
                                className={classes.userOption}
                            />
                        ),
                    }}
                />
            </div>
            <ModalFooter>
                <TextButton onClick={returnToPreviousModalButtonWasClicked}>
                    Cancel
                </TextButton>
                <PrimaryButton onClick={form?.onSubmit} disabled={inviteMemberButtonIsDisabled}>
                    Invite member
                </PrimaryButton>
            </ModalFooter>
        </>
    );
};

AddMemberForm.propTypes = {
    returnToPreviousModalButtonWasClicked: PropTypes.func.isRequired,
    form: PropTypes.instanceOf(Form),
    users: PropTypes.arrayOf(PropTypes.instanceOf(User)),
};

AddMemberForm.defaultProps = {
    form: null,
    users: [],
};

export default observer(AddMemberForm);
