import {makeAutoObservable} from 'mobx';
import DateToDisplay from './ToDisplay/DateToDisplay';

export default class CommentPresenter {
    constructor({
        comment,
        assetVersions,
        getSelectedAssetVersionId,
        assetVersionWasClicked,
        getCurrentUserFromStore,
        getUserFromStore,
    } = {}) {
        this.comment = comment;
        this.assetVersions = assetVersions;
        this.getSelectedAssetVersionId = getSelectedAssetVersionId;
        this.assetVersionWasClicked = assetVersionWasClicked;

        this.getUserFromStore = getUserFromStore;
        this.getCurrentUserFromStore = getCurrentUserFromStore;

        this.mentionRegExp = /@\[(.+?(?=\]))\]\((\d+)\)/g;

        makeAutoObservable(this);
    }

    get dateToDisplay() {
        return new DateToDisplay({date: this.comment.createdAt}).displayTimeAgoShort;
    }

    get user() {
        return this.comment.user;
    }

    get username() {
        return this.user?.fullName;
    }

    get text() {
        return this._commentText().replace(
            this.mentionRegExp, (mention, email, id) => this._replaceMention(mention, email, id),
        );
    }

    get mentionsTexts() {
        const mentionTextRegex = /@\[(.*?)]/g;
        return this._commentText().match(mentionTextRegex)?.map(
            (text) => text.replace(/[[\]]/g, ''),
        ) || [];
    }

    get commentId() {
        return this.comment.id;
    }

    get isSelectedAssetVersionComment() {
        return this.comment.assetVersionId === this.getSelectedAssetVersionId();
    }

    _replaceMention(mention, email, id) {
        return `@${this.getUserFromStore.execute(+id).fullName}`;
    }

    _commentText() {
        return this.comment.text;
    }
}
