import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

import {useStyles} from './VideoDisplay.styles';

const VideoDisplay = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.videoDisplay}>
            <ReactPlayer
                url={presenter.fileUrl}
                controls
                className={classes.videoPlayer}
                data-testid="video-display"
                width="100%"
                height="100%"
            />
        </div>
    );
};

VideoDisplay.propTypes = {
    presenter: PropTypes.shape({
        fileUrl: PropTypes.string.isRequired,
    }).isRequired,
};

VideoDisplay.defaultProps = {
};

export default observer(VideoDisplay);
