/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import noop from '../../helpers/noop';

const Button = ({
    onClick, children, id, startIcon, className, disabled,
}) => (
    <MuiButton
        className={className}
        variant="contained"
        onClick={onClick}
        data-testid={id}
        disableRipple
        startIcon={startIcon}
        disabled={disabled}
    >
        {children}
    </MuiButton>
);

Button.propTypes = {
    onClick: propTypes.func,
};

Button.defaultProps = {
    onClick: noop,
};

export default Button;
