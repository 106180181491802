export const EMAIL = 'email';
const fields = [
    {
        name: EMAIL,
        label: 'Email',
        placeholder: 'name@mail.com',
        rules: 'required',
        type: 'email',
    },
];

export default fields;
