import Project from '../entities/Project';
import User from '../entities/User';
import MediaGroup from '../entities/MediaGroup';
import Asset from '../entities/Asset';
import AssetVersion from '../entities/AssetVersion';
import Mention from '../entities/Mention';
import ProjectMember from '../entities/ProjectMember';
import Activity from '../entities/Activity';

import UserService from './UserService';
import MediaGroupService from './MediaGroupService';
import AssetService from './AssetService';
import AssetVersionService from './AssetVersionService';
import ActivityService from './ActivityService';
import MentionService from './MentionService';
import ProjectService from './ProjectService';
import ProjectMemberService from './ProjectMemberService';

const PROJECT_ENTITY_NAME = 'project';
const PROJECTS_API_PATH = 'projects';
const USER_ENTITY_NAME = 'user';
const USERS_API_PATH = 'users';
const MEDIA_GROUP_ENTITY_NAME = 'mediaGroup';
const MEDIA_GROUPS_API_PATH = 'media_groups';
const ASSET_ENTITY_NAME = 'asset';
const ASSETS_API_PATH = 'assets';
const ASSET_VERSION_ENTITY_NAME = 'assetVersion';
const ASSET_VERSIONS_API_PATH = 'asset_versions';
const MENTION_ENTITY_NAME = 'mention';
const MENTIONS_API_PATH = 'mentions';
const PROJECT_MEMBER_ENTITY_NAME = 'projectMember';
const PROJECT_MEMBERS_API_PATH = 'project_members';
const ACTIVITY_ENTITY_NAME = 'activity';
const ACTIVITIES_API_PATH = 'activities';

export default class EntityServiceFactory {
    static forProjects({api, buildEntity = Project.fromJSON}) {
        return new ProjectService({
            api,
            entityName: PROJECT_ENTITY_NAME,
            basePath: PROJECTS_API_PATH,
            buildEntity,
        });
    }

    static forUsers({api, buildEntity = User.fromJSON}) {
        return new UserService({
            api,
            entityName: USER_ENTITY_NAME,
            basePath: USERS_API_PATH,
            buildEntity,
        });
    }

    static forMediaGroups({api, buildEntity = MediaGroup.fromJSON}) {
        return new MediaGroupService({
            api,
            entityName: MEDIA_GROUP_ENTITY_NAME,
            basePath: MEDIA_GROUPS_API_PATH,
            buildEntity,
        });
    }

    static forAssets({api, buildEntity = Asset.fromJSON}) {
        return new AssetService({
            api,
            entityName: ASSET_ENTITY_NAME,
            basePath: ASSETS_API_PATH,
            buildEntity,
        });
    }

    static forAssetVersions({api, buildEntity = AssetVersion.fromJSON}) {
        return new AssetVersionService({
            api,
            entityName: ASSET_VERSION_ENTITY_NAME,
            basePath: ASSET_VERSIONS_API_PATH,
            buildEntity,
        });
    }

    static forActivities({api, buildEntity = Activity.fromJSON}) {
        return new ActivityService({
            api,
            entityName: ACTIVITY_ENTITY_NAME,
            basePath: ACTIVITIES_API_PATH,
            buildEntity,
        });
    }

    static forMentions({api, buildEntity = Mention.fromJSON}) {
        return new MentionService({
            api,
            entityName: MENTION_ENTITY_NAME,
            basePath: MENTIONS_API_PATH,
            buildEntity,
        });
    }

    static forProjectMembers({api, buildEntity = ProjectMember.fromJSON}) {
        return new ProjectMemberService({
            api,
            entityName: PROJECT_MEMBER_ENTITY_NAME,
            basePath: PROJECT_MEMBERS_API_PATH,
            buildEntity,
        });
    }
}
