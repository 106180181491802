import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {useStyles} from './Mention.styles';
import MarkAsReadButton from '../MarkAsReadButton/MarkAsReadButton';
import MentionPresenter from '../../presenters/MentionPresenter';
import {Highlight} from '../Highlight';

const Mention = ({
    presenter,
}) => {
    const classes = useStyles();
    const {
        mentionUrl,
    } = presenter;
    const isUnseen = presenter.unseenMention;
    const markAsSeen = () => presenter.markOneAsSeenButtonWasClicked(presenter.mentionId);
    const mentionOnClick = isUnseen ? markAsSeen : () => {};

    const textToHighlight = presenter.commentPresenter.mentionsTexts.join('|');
    const textToHighlightRegex = textToHighlight === '' ? textToHighlight : new RegExp(textToHighlight, 'gi');

    return (
        <Link
            to={mentionUrl}
            onClick={mentionOnClick}
            className={classes.mentionContainer}
        >
            {isUnseen && (
                <div
                    className={classes.mentionNotification}
                    data-testid="mention-notification"
                />
            )}
            <div className={classes.mention}>
                <div className={classes.mentionInfo}>
                    <span>{`${presenter.mentionProjectName} / ${presenter.mentionPostName}`}</span>
                </div>
                {isUnseen && (
                    <div className={clsx(classes.markAsRead, 'mark-as-read')}>
                        <MarkAsReadButton
                            onClick={markAsSeen}
                        />
                    </div>
                )}
                <div className={classes.mentionContent}>
                    <span className={classes.mentionUsername}>
                        {`${presenter.username}:`}
                    </span>
                    <p
                        title={presenter.text}
                        className={classes.mentionText}
                    >
                        <Highlight
                            source={presenter.text}
                            target=""
                            targetRegex={textToHighlightRegex}
                            renderHighlight={(mention) => (
                                <span className={classes.highlightedUsername}>{mention}</span>
                            )}
                        />
                    </p>
                </div>
            </div>
        </Link>
    );
};

Mention.propTypes = {
    presenter: PropTypes.instanceOf(MentionPresenter).isRequired,
};

Mention.defaultProps = {
};

export default observer(Mention);
