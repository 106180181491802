import {Icon} from '../Icon';
import {clockIcon} from '../../icons';

const ClockIcon = () => (
    <Icon>
        <img src={clockIcon} alt="clock" />
    </Icon>
);

export default ClockIcon;
