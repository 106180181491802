import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    form: {
        position: 'relative',
        backgroundColor: palette.formBackgroundPrimary,
        paddingTop: 'min(6%, 30px)',
        paddingLeft: 'min(5%, 44px)',
        paddingRight: 'min(5%, 30px)',
        paddingBottom: 'min(3%, 22px)',
    },

    actions: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },

    addMediaCard: {
        justifyContent: 'center',
        flexDirection: 'row',
        display: 'flex',
    },
}));
