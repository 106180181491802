import {
    action,
    makeObservable,
} from 'mobx';
import Store from './Store';

export default class MentionsStore extends Store {
    constructor(props) {
        super(props);

        makeObservable(this, {
            markAsSeenAll: action,
            markAsSeenById: action,
        });
    }

    markAsSeenAll() {
        this.all.forEach((mention) => {
            if (mention.unseen) {
                mention.markAsSeen();
            }
        });
    }

    markAsSeenById(mentionId) {
        const mention = this.find(mentionId);
        mention.markAsSeen();
    }
}
