import {useEffect, useState} from 'react';
import CreateMediaGroupPresenter from '../presenters/CreateMediaGroupPresenter';
import CreateMediaGroup from '../interactors/CreateMediaGroup';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import AddAssetToMediaGroup from '../interactors/AddAssetToMediaGroup';
import DirectFileUpload from '../services/DirectFileUpload';
import UploadFile from '../interactors/UploadFile';
import Api from '../services/Api';
import CreateAsset from '../interactors/CreateAsset';
import CreateAssetVersion from '../interactors/CreateAssetVersion';
import EntityServiceFactory from '../services/EntityServiceFactory';
import GetEntityFromStoreFactory from '../interactors/GetEntityFromStoreFactory';
import FetchEntityFromRemote from '../interactors/FetchEntityFromRemote';

const useCreateMediaGroupWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();

    useEffect(() => {
        const {authStore} = root;
        const {projectStore} = root;
        const {mediaGroupStore} = root;
        const {assetStore} = root;

        const authApi = new Api({authStore});

        const mediaGroupService = EntityServiceFactory.forMediaGroups(
            {api: authApi},
        );

        const createMediaGroup = new CreateMediaGroup({
            service: mediaGroupService,
            projectStore,
            mediaGroupStore,
        });

        const addAssetToMediaGroup = new AddAssetToMediaGroup({
            mediaGroupService,
            mediaGroupStore,
            assetStore,
        });

        const assetService = EntityServiceFactory.forAssets({api: authApi});

        const uploadFile = new UploadFile({
            authStore,
            uploader: DirectFileUpload,
            service: assetService,
        });

        const createAsset = new CreateAsset({service: assetService});
        const createAssetVersion = new CreateAssetVersion({
            assetService,
            assetStore,
        });

        const projectService = EntityServiceFactory.forProjects(
            {api: authApi},
        );

        const getProjectFromStore = GetEntityFromStoreFactory.forProjects(
            {store: projectStore},
        );

        const fetchProjectFromRemote = new FetchEntityFromRemote({
            service: projectService,
            store: projectStore,
        });

        const presenter = new CreateMediaGroupPresenter({
            createMediaGroup,
            router,
            uploadFile,
            addAssetToMediaGroup,
            createAsset,
            createAssetVersion,
            getProjectFromStore,
            fetchProjectFromRemote,
        });

        setPresenterInstance(presenter);
    }, [root, router]);

    return presenterInstance;
};

export default useCreateMediaGroupWireframe;
