import {Redirect, Route, Switch} from 'react-router-dom';
import routes from './routes';
import {PrivateRoute} from './components/PrivateRoute';

const Navigation = () => {
    const getRouteProps = (route) => ({
        key: route.path,
        exact: true,
        path: route.path,
        component: route.screen,
    });

    const renderPublicRoutes = () => (
        Object.values(routes.public).map((route) => (
            <Route {...getRouteProps(route)} />
        ))
    );

    const renderPrivateRoutes = () => (
        Object.values(routes.private).map((route) => (
            <PrivateRoute {...getRouteProps(route)} />
        ))
    );

    return (
        <Switch>
            {renderPublicRoutes()}
            {renderPrivateRoutes()}
            <Route path="*">
                <Redirect to={routes.default.path} />
            </Route>
        </Switch>
    );
};

export default Navigation;
