import {makeAutoObservable} from 'mobx';
/* eslint-disable-next-line import/no-cycle */
import MediaGroup from './MediaGroup';
import User from './User';

export default class Project {
    constructor({
        id, name, mediaGroups, owner, lastUpdatedAt, membersAmount,
        thumbnailFileExtension, thumbnailUrl, brief, links, timeline,
    }) {
        this.id = id;
        this.name = name;
        this.mediaGroups = mediaGroups;
        this.owner = owner;
        this.lastUpdatedAt = lastUpdatedAt;
        this.membersAmount = membersAmount;
        this.thumbnailFileExtension = thumbnailFileExtension;
        this.thumbnailUrl = thumbnailUrl;
        this.brief = brief;
        this.links = links;
        this.timeline = timeline;

        makeAutoObservable(this);
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            media_groups: this.mediaGroups,
            owner: this.owner.toJSON(),
            last_updated_at: this.lastUpdatedAt,
            members_amount: this.membersAmount,
            thumbnail_file_extension: this.thumbnailFileExtension,
            thumbnail_url: this.thumbnailUrl,
            brief: this.brief,
            links: this.links,
            timeline: this.timeline,
        };
    }

    static fromJSON(data) {
        const mediaGroups = data.media_groups && data.media_groups.map(MediaGroup.fromJSON);
        const owner = data.owner && User.fromJSON(data.owner);

        return new Project({
            id: data.id,
            name: data.name,
            mediaGroups,
            owner,
            lastUpdatedAt: new Date(data.last_updated_at),
            membersAmount: data.members_amount,
            thumbnailFileExtension: data.thumbnail_file_extension,
            thumbnailUrl: data.thumbnail_url,
            brief: data.brief,
            links: data.links,
            timeline: data.timeline,
        });
    }

    addMediaGroup(mediaGroup) {
        this.mediaGroups = [
            mediaGroup,
            ...this.mediaGroups,
        ];
    }

    removeMediaGroup(mediaGroupId) {
        this.mediaGroups = this.mediaGroups.filter(({id}) => id !== mediaGroupId);
    }

    updateMediaGroup(mediaGroup) {
        this.mediaGroups = this.mediaGroups.map((mGroup) => {
            if (mGroup.id === mediaGroup.id) {
                return mediaGroup;
            }

            return mGroup;
        });
    }
}
