import {
    makeAutoObservable,
} from 'mobx';
import Asset from './Asset';

export const ACTIVITY_ON_TYPES = [
    'Asset',
    'AssetVersion',
];

export default class Activity {
    constructor({
        id,
        seenAt,
        activityOnType,
        activityOn,
    }) {
        this.id = id;
        this.seenAt = seenAt;
        this.activityOnType = activityOnType;
        this.activityOn = activityOn;

        makeAutoObservable(this);
    }

    toJSON() {
        const activityOn = this.activityOn && this.activityOn.toJSON();

        return {
            id: this.id,
            seenAt: this.seenAt,
            activityOnType: this.activityOnType,
            activityOn,
        };
    }

    static fromJSON(data) {
        const activityOn = ACTIVITY_ON_TYPES.includes(data.activity_on_type)
            && data.activity_on
            && Asset.fromJSON(data.activity_on);

        return new Activity({
            id: data.id,
            seenAt: data.seen_at && new Date(data.seen_at),
            activityOnType: data.activity_on_type,
            activityOn,
        });
    }

    get isSeen() {
        return Boolean(this.seenAt);
    }

    markAsSeen() {
        this.seenAt = new Date();
    }
}
