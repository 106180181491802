import {makeStyles} from '@material-ui/core/styles';
import {fonts, palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    margin: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        ...fonts.buttonText,
    },

    root: {
        marginBottom: 'min(8%, 58px)',
        paddingTop: 'min(7%, 51px)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& h1': {
            ...fonts.subTitle,

            fontWeight: 'bold',
            color: palette.formTitle,
        },
    },
}));
