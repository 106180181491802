import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import MediaGroupItem from './components/MediaGroupItem';

import classes from './MediaGroupList.module.scss';
import ProjectDetailPresenter from '../../presenters/ProjectDetailPresenter';

const MediaGroupList = ({presenter}) => {
    if (!presenter.mediaGroupThumbnailPresenters?.length) {
        return null;
    }

    return (
        <div className={classes.root}>
            {presenter.mediaGroupThumbnailPresenters.map((thumbnailPresenter) => (
                <MediaGroupItem
                    key={String(thumbnailPresenter.mediaGroupId)}
                    presenter={thumbnailPresenter}
                />
            ))}
        </div>
    );
};

MediaGroupList.propTypes = {
    presenter: PropTypes.instanceOf(ProjectDetailPresenter).isRequired,
};

MediaGroupList.defaultProps = {
};

export default observer(MediaGroupList);
