import LogIn from '../screens/LogIn/LogIn';
import SignUp from '../screens/SignUp/SignUp';
import Projects from '../screens/Projects/Projects';
import ProjectDetail from '../screens/ProjectDetail/ProjectDetail';
import MediaGroupDetail from '../screens/MediaGroupDetail/MediaGroupDetail';
import MediaGroupCreation from '../screens/MediaGroupCreation/MediaGroupCreation';
import AssetDetail from '../screens/AssetDetail/AssetDetail';
import Profile from '../screens/Profile/Profile';
import {
    DEFAULT_PATH,
    LOGIN_PATH,
    PROJECT_DETAIL_PATH,
    DASHBOARD_PATH,
    MEDIA_GROUP_DETAIL_PATH,
    ASSET_DETAIL_PATH,
    NEW_MEDIA_GROUP_PATH,
    SIGNUP_PATH,
    PROFILE_PATH,
} from './paths';

const publicRoutes = {
    login: {
        path: LOGIN_PATH,
        screen: LogIn,
    },

    signup: {
        path: SIGNUP_PATH,
        screen: SignUp,
    },
};

const privateRoutes = {
    dashboard: {
        path: DASHBOARD_PATH,
        screen: Projects,
    },

    projectDetail: {
        path: PROJECT_DETAIL_PATH,
        screen: ProjectDetail,
    },

    mediaGroupDetail: {
        path: MEDIA_GROUP_DETAIL_PATH,
        screen: MediaGroupDetail,
    },

    newMediaGroup: {
        path: NEW_MEDIA_GROUP_PATH,
        screen: MediaGroupCreation,
    },

    assetDetail: {
        path: ASSET_DETAIL_PATH,
        screen: AssetDetail,
    },

    profile: {
        path: PROFILE_PATH,
        screen: Profile,
    },
};

const defaultRoute = {
    path: DEFAULT_PATH,
};

const routes = {
    public: publicRoutes,
    private: privateRoutes,
    default: defaultRoute,
};

export default routes;
