import {useEffect, useState} from 'react';
import ProjectsPresenter from '../presenters/ProjectsPresenter';
import GetEntitiesFromStore from '../interactors/GetEntitiesFromStore';
import FetchEntitiesFromRemote from '../interactors/FetchEntitiesFromRemote';
import {useRootStore} from '../providers/RootStoreProvider';
import {useRouter} from '../providers/RouterProvider';
import {useFirebase} from '../providers/FirebaseProvider';
import Api from '../services/Api';
import AuthService from '../services/AuthService';
import ApiResponseDeserializer from '../services/ApiResponseDeserializer';
import OAuthResponseDeserializer from '../services/OAuthResponseDeserializer';
import EntityServiceFactory from '../services/EntityServiceFactory';
import GetCurrentUserFromStore from '../interactors/GetCurrentUserFromStore';
import GetEntityFromStoreFactory from '../interactors/GetEntityFromStoreFactory';
import MarkMentionsAsSeen from '../interactors/MarkMentionsAsSeen';
import FetchCurrentUserProjects from '../interactors/FetchCurrentUserProjects';
import LogOutUser from '../interactors/LogOutUser';
import ManageProject from '../interactors/ManageProject';
import {useModalService} from '../providers/ModalProvider';
import MarkActivitiesAsSeen from '../interactors/MarkActivitiesAsSeen';
import FetchActivitiesFromRemote from '../interactors/FetchActivitiesFromRemote';
import GetEntityFromStore from '../interactors/GetEntityFromStore';

const useProjectsWireframe = () => {
    const [presenterInstance, setPresenterInstance] = useState(null);
    const root = useRootStore();
    const router = useRouter();
    const firebase = useFirebase();
    const modalService = useModalService();

    useEffect(() => {
        const {
            activitiesStore,
            assetVersionStore,
            authStore,
            projectStore,
            mentionStore,
            userStore,
        } = root;

        const authResponseDeserializerKlass = OAuthResponseDeserializer;
        const apiResponseDeserializerKlass = ApiResponseDeserializer;

        const authApi = new Api({
            authStore,
            responseDeserializerKlass: authResponseDeserializerKlass,
        });
        const api = new Api({
            authStore,
            responseDeserializerKlass: apiResponseDeserializerKlass,
        });

        const authService = new AuthService({api: authApi, auth: firebase.auth});

        const userService = EntityServiceFactory.forUsers({api});

        const getProjectsFromStore = new GetEntitiesFromStore({
            store: projectStore,
        });
        const fetchProjectsFromRemote = new FetchCurrentUserProjects({
            projectStore,
            userService,
        });

        const mentionService = EntityServiceFactory.forMentions({api});
        const getMentionsFromStore = new GetEntitiesFromStore({
            store: mentionStore,
        });
        const fetchMentionsFromRemote = new FetchEntitiesFromRemote({
            service: mentionService,
            store: mentionStore,
        });
        const markMentionsAsSeen = new MarkMentionsAsSeen({
            mentionService,
            mentionStore,
        });

        const getCurrentUserFromStore = new GetCurrentUserFromStore({store: userStore});
        const getUserFromStore = GetEntityFromStoreFactory.forUsers(
            {store: userStore},
        );

        const assetVersionService = EntityServiceFactory.forAssetVersions({api});
        const getAssetVersionFromStore = new GetEntityFromStore({
            store: assetVersionStore,
        });
        const fetchAssetVersionsFromRemote = new FetchEntitiesFromRemote({
            service: assetVersionService,
            store: assetVersionStore,
        });

        const activitiesService = EntityServiceFactory.forActivities({api});
        const getActivitiesFromStore = new GetEntitiesFromStore({
            store: activitiesStore,
        });
        const fetchActivitiesFromRemote = new FetchActivitiesFromRemote({
            service: activitiesService,
            store: activitiesStore,
        });
        const markActivitiesAsSeen = new MarkActivitiesAsSeen({
            service: activitiesService,
            store: activitiesStore,
        });

        const logOut = new LogOutUser({authService, userStore});

        const projectService = EntityServiceFactory.forProjects({
            api: authApi,
        });

        const manageProject = new ManageProject({
            service: projectService,
            store: projectStore,
        });

        const presenter = new ProjectsPresenter({
            router,
            manageProject,
            getProjectsFromStore,
            getMentionsFromStore,
            fetchProjectsFromRemote,
            fetchMentionsFromRemote,
            getCurrentUserFromStore,
            getUserFromStore,
            markMentionsAsSeen,
            markActivitiesAsSeen,
            getActivitiesFromStore,
            fetchActivitiesFromRemote,
            getAssetVersionFromStore,
            fetchAssetVersionsFromRemote,
            logOut,
            modalService,
        });

        setPresenterInstance(presenter);
    }, [
        root,
        router,
        firebase,
        modalService,
    ]);

    return presenterInstance;
};

export default useProjectsWireframe;
