import {makeAutoObservable} from 'mobx';
import Comment from './Comment';

export default class Mention {
    constructor({
        id,
        seenAt,
        comment,
    }) {
        this.id = id;
        this.seenAt = seenAt;
        this.comment = comment;

        makeAutoObservable(this);
    }

    toJSON() {
        const comment = this.comment.toJSON();

        return {
            id: this.id,
            seen_at: this.seenAt,
            comment,
        };
    }

    static fromJSON(data) {
        const comment = data.comment && Comment.fromJSON(data.comment);

        return new Mention({
            id: data.id,
            seenAt: data.seen_at && new Date(data.seen_at),
            comment,
        });
    }

    get assetVersion() {
        return this.comment.assetVersion;
    }

    get unseen() {
        return !this.seenAt;
    }

    markAsSeen() {
        this.seenAt = new Date();
    }
}
