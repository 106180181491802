export default class UpdateMediaGroup {
    constructor({
        service,
        projectStore,
        mediaGroupStore,
    }) {
        this.service = service;
        this.projectStore = projectStore;
        this.mediaGroupStore = mediaGroupStore;
    }

    async remove({
        mediaGroupId,
        projectId,
    }) {
        await this.service.delete(mediaGroupId);

        this.mediaGroupStore.delete(mediaGroupId);
        const project = this.projectStore.find(projectId);
        project.removeMediaGroup(mediaGroupId);
        this.projectStore.update(projectId, project);
    }

    async update({
        name,
        mediaGroupId,
        projectId,
    }) {
        await this.service.update(mediaGroupId, {
            name,
        });

        const mediaGroup = this.mediaGroupStore.find(mediaGroupId);
        const updatedMediaGroup = {
            ...mediaGroup,
            name,
        };
        this.mediaGroupStore.update(mediaGroupId, updatedMediaGroup);

        const project = this.projectStore.find(projectId);
        project.updateMediaGroup(updatedMediaGroup);
        this.projectStore.update(projectId, project);
    }
}
