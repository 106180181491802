import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {NewMediaGroupForm} from '../NewMediaGroupForm';
import {useStyles} from './NewMediaGroup.styles';

const NewMediaGroup = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.background}>
            <div className={classes.root}>
                <NewMediaGroupForm
                    presenter={presenter}
                    onClick={presenter.form.onSubmit}
                    onCancelClick={presenter.cancelMediaGroupCreationButtonWasClicked}
                />
            </div>
        </div>
    );
};

NewMediaGroup.propTypes = {
    presenter: PropTypes.any.isRequired,
};

NewMediaGroup.defaultProps = {
};

export default observer(NewMediaGroup);
