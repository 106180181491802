import EntityService from './EntityService';
import Comment from '../entities/Comment';

export default class AssetVersionService extends EntityService {
    async getComments({assetVersionId}) {
        const commentsJSON = await this.api.get(`${this.getBasePath()}/${assetVersionId}/comments`);

        return commentsJSON.map((comment) => Comment.fromJSON(comment));
    }

    async createComment({assetVersionId, text}) {
        const commentJSON = await this.api.post(`${this.getBasePath()}/${assetVersionId}/comments`, {
            comment: {
                text,
            },
        });

        return Comment.fromJSON(commentJSON);
    }
}
