import PropTypes from 'prop-types';
import {ReactComponent as ReactIcon} from '../../icons/upload-icon.svg';
import {ReactComponent as ReactIconDark} from '../../icons/upload-icon-dark.svg';

const UploadIcon = ({theme}) => (
    <>
        {theme === 'dark' && (
            <ReactIconDark />
        )}
        {theme === 'light' && (
            <ReactIcon />
        )}
    </>
);

UploadIcon.propTypes = {
    theme: PropTypes.oneOf([
        'light',
        'dark',
    ]),
};

UploadIcon.defaultProps = {
    theme: 'light',
};

export default UploadIcon;
