import FetchEntitiesFromRemote from './FetchEntitiesFromRemote';
import {
    ACTIVITY_ON_TYPES,
} from '../entities/Activity';

export default class FetchActivitiesFromRemote extends FetchEntitiesFromRemote {
    constructor({
        service,
        store,
    }) {
        super({
            service,
            store,
        });
    }

    async execute() {
        const allActivities = await this.fetchFromService();
        const allowedActivities = allActivities.filter((activity) => ACTIVITY_ON_TYPES.includes(activity.activityOnType));

        this.putInStore(allowedActivities);
    }
}
