import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {ProjectList} from '../ProjectList';
import {DashboardCard} from '../DashboardCard';

import {useStyles} from './ProjectsSection.styles';
import ProjectsPresenter from '../../presenters/ProjectsPresenter';

const ProjectsSection = ({presenter}) => {
    const classes = useStyles();

    const header = presenter.numberOfProjects > 0
        ? (
            <>
                <em>{presenter.numberOfProjectsToDisplay}</em>
                {` Active Project${presenter.numberOfProjects !== 1 ? 's' : ''}`}
            </>
        )
        : 'Add your first project';

    return (
        <DashboardCard
            className={classes.projectsSection}
            header={header}
        >
            <ProjectList presenter={presenter} />
        </DashboardCard>
    );
};

ProjectsSection.propTypes = {
    presenter: PropTypes.instanceOf(ProjectsPresenter).isRequired,
};

export default observer(ProjectsSection);
