import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {PrimaryButton} from '../PrimaryButton';
import {WhiteButton} from '../WhiteButton';

import classes from './FeedHeader.module.scss';

const FeedHeader = ({
    title,
    primary,
    secondary,
}) => (
    <div className={classes.root}>
        <h1
            className={classes.title}
            title={title}
        >
            {title}
        </h1>
        <div className={classes.buttons}>
            {secondary && (
                <WhiteButton
                    onClick={secondary.onClick}
                    startIcon={secondary.icon}
                >
                    {secondary.name}
                </WhiteButton>
            )}
            {primary && (
                <PrimaryButton
                    onClick={primary.onClick}
                    startIcon={primary.icon}
                >
                    {primary.name}
                </PrimaryButton>
            )}
        </div>
    </div>
);

FeedHeader.propTypes = {
    title: PropTypes.string,
    primary: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.any,
    }),
    secondary: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.any,
    }),
};

FeedHeader.defaultProps = {
    title: null,
    primary: null,
    secondary: null,
};

export default observer(FeedHeader);
