export const ASSET_DESCRIPTION = 'description';

const fields = [{
    name: ASSET_DESCRIPTION,
    label: '',
    placeholder: '',
    rules: 'required',
}];

export default fields;
