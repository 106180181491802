import PropTypes from 'prop-types';
import classes from './Checkbox.module.scss';

function Checkbox({
    id,
    label,
    checked,
    onChange,
}) {
    return (
        <label
            className={classes.checkbox}
            htmlFor={id}
        >
            <input
                type="checkbox"
                checked={checked}
                className={classes.input}
                id={id}
                onChange={() => onChange(!checked)}
            />
            <span className={classes.mark} />
            <span className={classes.label}>
                {label}
            </span>
        </label>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
    checked: false,
};

export default Checkbox;
