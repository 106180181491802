import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    screen: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#000',
        boxSizing: 'border-box',
        gap: '15px',
        height: '100vh',
        maxHeight: '100vh',
        padding: '20px 16px 16px 32px',
    },

    content: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 35px)',
    },

    breadcrumbs: {
        height: '20px',
    },

    assetDetail: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 350px)',
    },

    assetHeader: {
        height: '60px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
    },

    mediaDisplayContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: '1',
        color: palette.textLight,
        overflowY: 'auto',
        padding: '0 24px 0 0',
        boxSizing: 'border-box',
    },
}));
