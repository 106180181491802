import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import AssetItem from './components/AssetItem';

import classes from './AssetList.module.scss';
import MediaGroupDetailPresenter from '../../presenters/MediaGroupDetailPresenter';

const AssetList = ({presenter}) => (
    <div className={classes.root}>
        {presenter.assets?.map((asset) => (
            <AssetItem
                key={asset.id}
                asset={asset}
                renameAsset={() => presenter.openRenameAssetModal(asset.id)}
                deleteAsset={() => presenter.openDeleteAssetModal(asset.id)}
            />
        ))}
    </div>
);

AssetList.propTypes = {
    presenter: PropTypes.instanceOf(MediaGroupDetailPresenter).isRequired,
};

export default observer(AssetList);
