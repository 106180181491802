import VeneerError from './VeneerError';

export default class AvailabilityError extends VeneerError {
    get isAvailabilityError() {
        return true;
    }

    get isNetworkError() {
        return false;
    }
}
