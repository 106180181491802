import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {EMAIL, PASSWORD} from '../../constants/formFields/logInForm';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import InputTextWithLabel from '../InputTextWithLabel/InputTextWithLabel';
import styles from './LogInForm.module.scss';
import LogInPresenter from '../../presenters/LogInPresenter';

const LogInForm = ({presenter}) => (
    <>
        <div>
            <InputTextWithLabel
                errorMessage={presenter.loginForm.$(EMAIL).error}
                {...presenter.loginForm.$(EMAIL).bind()}
            />
        </div>
        <div>
            <InputTextWithLabel
                errorMessage={presenter.loginForm.$(PASSWORD).error}
                {...presenter.loginForm.$(PASSWORD).bind()}
                label={(
                    <div className={styles.formLabel}>
                        <span>
                            {presenter.loginForm.$(PASSWORD).label}
                        </span>
                        <span
                            className={styles.forgotPassword}
                            onClick={presenter.resetPassword}
                        >
                            Forgot password?
                        </span>
                    </div>
                )}
            />
        </div>
        {presenter.formError && (
            <div className={styles.errorRow}>
                {presenter.formError}
            </div>
        )}
        <div className={styles.submitRow}>
            <PrimaryButton
                onClick={presenter.loginForm.onSubmit}
                disabled={presenter.submitDisabled}
            >
                Sign In
            </PrimaryButton>
            <span
                className={styles.secondaryButton}
                onClick={presenter.signUpButtonWasClicked}
            >
                Create account
            </span>
        </div>
    </>
);

LogInForm.propTypes = {
    presenter: PropTypes.instanceOf(LogInPresenter).isRequired,
};

LogInForm.defaultProps = {
};

export default observer(LogInForm);
