import {Icon} from '../Icon';
import {sendCommentIcon} from '../../icons';

import {useStyles} from './SendCommentIcon.styles';

const SendCommentIcon = () => {
    const classes = useStyles();

    return (
        <Icon>
            <img src={sendCommentIcon} className={classes.root} alt="send-comment" />
        </Icon>
    );
};

SendCommentIcon.propTypes = {
};

SendCommentIcon.defaultProps = {
};

export default SendCommentIcon;
