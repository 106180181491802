import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        minWidth: '625px',
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },

    label: {
        color: '#728391',
        marginBottom: '7px',
    },

    // rewrite
    control: {
        marginBottom: 0,
    },

    textarea: {
        minHeight: '200px',
        flexGrow: '1',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: '2px',
    },

    input: {
        paddingRight: '2px',
    },
}));
