import {observer} from 'mobx-react-lite';
import {useStyles} from './HorizontalLine.styles';

const HorizontalLine = () => {
    const classes = useStyles();

    return (
        <>
            <hr className={classes.root} />
        </>
    );
};

HorizontalLine.propTypes = {
};

HorizontalLine.defaultProps = {
};

export default observer(HorizontalLine);
