import EntityService from './EntityService';

export default class MentionService extends EntityService {
    markAsSeenAll() {
        return this.api.post(`${this.getBasePath()}/mark_all_as_seen`);
    }

    markAsSeenById(mentionId) {
        return this.api.post(`${this.getBasePath()}/${mentionId}/mark_as_seen`);
    }
}
