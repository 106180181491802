import {makeAutoObservable} from 'mobx';
import Form from '../forms/Form';
import fields from '../forms/signUpFields';
import {LOGIN_PATH} from '../routes/paths';
import {PASSWORD} from '../constants/formFields/signUpForm';

export default class SignUpPresenter {
    constructor({
        signUp,
        authStore,
        router,
        currentUser,
        fetchAllUsers,
    } = {}) {
        this.signUp = signUp;
        this.authStore = authStore;
        this.router = router;
        this.currentUser = currentUser;
        this.fetchAllUsers = fetchAllUsers;
        this.submitDisabled = false;

        this.formHooks = {
            onSuccess: this.onSubmitSuccess,
        };

        if (this.isLoggedIn()) this.router.onLogIn();

        this.form = new Form({fields}, {hooks: this.formHooks});

        makeAutoObservable(this);
    }

    onSubmitSuccess = async (form) => {
        const formData = form.values();
        this.submitDisabled = true;

        let firebaseOk = false;
        try {
            await this.signUp.execute(formData);
            firebaseOk = true;
        } catch (ex) {
            this.form.$(PASSWORD).invalidate(ex.message);
        } finally {
            this.submitDisabled = false;
        }

        if (firebaseOk) {
            await this.currentUser.execute();
            const currentUser = await this.currentUser.afterSignUp({
                firstName: formData.first_name,
                lastName: formData.last_name,
            });
            await this.fetchAllUsers.execute();
            this._identifyCurrentUserInLogService(currentUser);

            this.router.onLogIn();
        }
    };

    isLoggedIn() {
        return this.authStore.isAuthenticated;
    }

    clear = () => {
        this.form.clear();
    };

    signInButtonWasClicked = () => {
        this.router.navigateTo(LOGIN_PATH);
    };

    _identifyCurrentUserInLogService(currentUser) {
        this.logService?.identify(currentUser.id);
    }
}
