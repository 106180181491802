import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';

import {NAME} from '../../constants/formFields/createMediaGroupForm';
import {InputTextWithLabel} from '../InputTextWithLabel';
import {FormHeader} from '../FormHeader';
import {Card} from '../Card';
import {HorizontalLine} from '../HorizontalLine';
import {PrimaryButton} from '../PrimaryButton';
import NewAssetForm from './NewAssetForm';
import CreateMediaGroupPresenter from '../../presenters/CreateMediaGroupPresenter';
import {useStyles} from './NewMediaGroupForm.styles';

const NewMediaGroupForm = ({
    presenter,
    onClick,
    onCancelClick,
}) => {
    const classes = useStyles();

    return (
        <>
            <FormHeader
                onCancelClick={onCancelClick}
                onClick={onClick}
                formTitle="New Post"
                submitButtonName="Save Post"
                submitButtonNameIsDisabled={presenter.saveButtonIsDisabled}
            />
            <Card>
                <InputTextWithLabel
                    errorMessage={presenter.form.$(NAME).error}
                    {...presenter.form.$(NAME).bind()}
                />
            </Card>
            {
                presenter.createAssetPresenters.map((createAssetPresenter) => (
                    <NewAssetForm
                        presenter={createAssetPresenter}
                    />
                ))
            }
            <Card>
                <div className={classes.addMediaCard}>
                    <PrimaryButton
                        onClick={presenter.addMediaGroupButtonWasClicked}
                        disabled={presenter.addMediaButtonIsDisabled}
                    >
                        Add Media
                    </PrimaryButton>
                </div>
            </Card>
            <HorizontalLine />
            <FormHeader
                onCancelClick={onCancelClick}
                onClick={onClick}
                submitButtonName="Save Post"
                submitButtonNameIsDisabled={presenter.saveButtonIsDisabled}
            />
        </>
    );
};

NewMediaGroupForm.propTypes = {
    presenter: PropTypes.instanceOf(CreateMediaGroupPresenter).isRequired,
    onClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};

NewMediaGroupForm.defaultProps = {
};

export default observer(NewMediaGroupForm);
