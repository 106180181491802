export default class AddAssetToMediaGroup {
    constructor({mediaGroupService, mediaGroupStore, assetStore}) {
        this.mediaGroupService = mediaGroupService;
        this.mediaGroupStore = mediaGroupStore;
        this.assetStore = assetStore;
    }

    async execute({mediaGroupId, assetId}) {
        const asset = await this.mediaGroupService.addAsset({mediaGroupId, assetId});
        const mediaGroup = this.mediaGroupStore.find(mediaGroupId);
        mediaGroup.addAsset({asset});

        this.mediaGroupStore.update(mediaGroupId, mediaGroup);
        this.assetStore.add(asset);
    }
}
