export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const CURRENT_PASSWORD = 'current_password';
export const NEW_PASSWORD = 'password';
export const CONFIRM_PASSWORD = 'confirm_password';
export const MAIL_NOTIFICATIONS_MENTIONS = 'mail_mentions';
export const MAIL_NOTIFICATIONS_COMMENTS = 'mail_comments';

export const profileNameFields = [
    {
        name: FIRST_NAME,
        label: 'First Name',
        placeholder: '',
        rules: 'required',
        type: 'text',
        autocomplete: 'off',
    },
    {
        name: LAST_NAME,
        label: 'Last Name',
        placeholder: '',
        rules: 'required',
        type: 'text',
        autocomplete: 'off',
    },
];

export const passwordFields = [
    {
        name: CURRENT_PASSWORD,
        label: 'Current Password',
        placeholder: '',
        rules: 'required',
        type: 'password',
        autocomplete: 'off',
    },
    {
        name: NEW_PASSWORD,
        label: 'New password',
        placeholder: '',
        rules: 'required',
        type: 'password',
        autocomplete: 'off',
    },
    {
        name: CONFIRM_PASSWORD,
        label: 'Confirm password',
        placeholder: '',
        rules: `required|same:${NEW_PASSWORD}`,
        type: 'password',
        autocomplete: 'off',
    },
];
