import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        outline: 'none',
        paddingTop: '35px',
        margin: '0 30% 0 13%',
        height: '100%',
    },

    background: {
        backgroundColor: palette.formBackgroundSecondary,
    },
}));
