import {makeAutoObservable} from 'mobx';
import FileUploadPresenter from './FileUploadPresenter';
import CommentSectionPresenter from './CommentSectionPresenter';
import {DASHBOARD_PATH, PROJECT_DETAIL_PATH, MEDIA_GROUP_DETAIL_PATH} from '../routes/paths';
import AssetVersionToDisplay from './ToDisplay/AssetVersionToDisplay';
import {CONFIRMATION_MODAL, FILE_UPLOAD_MODAL} from '../constants/modals';

export default class AssetDetailPresenter {
    constructor({
        router,
        modalService,
        getAssetFromStore,
        fetchAssetFromRemote,
        mediaDisplayPresenterFactory,
        uploadFile,
        createAsset,
        createAssetVersion,
        getCommentsFromStore,
        fetchCommentsFromRemote,
        getCurrentUserFromStore,
        getUserFromStore,
        getProjectMembersFromStore,
        fetchProjectMembersFromRemote,
        createComment,
        toastService,
        archiveAsset,
    } = {}) {
        this.router = router;
        this.modalService = modalService;
        this.getAssetFromStore = getAssetFromStore;
        this.fetchAssetFromRemote = fetchAssetFromRemote;
        this.mediaDisplayPresenterFactory = mediaDisplayPresenterFactory;
        this.selectedAssetVersionNumber = 1;
        this.toastService = toastService;
        this.getCommentsFromStore = getCommentsFromStore;
        this.fetchCommentsFromRemote = fetchCommentsFromRemote;
        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.getUserFromStore = getUserFromStore;
        this.getProjectMembersFromStore = getProjectMembersFromStore;
        this.fetchProjectMembersFromRemote = fetchProjectMembersFromRemote;
        this.createComment = createComment;
        this.archiveAsset = archiveAsset;

        this.fileUploadPresenter = new FileUploadPresenter({
            uploadFile,
            createAsset,
            createAssetVersion,
            maxFiles: 1,
        });

        this.commentSectionPresenter = undefined;

        this._fetchAssetAndCreateCommentPresenter();

        makeAutoObservable(this);
    }

    getAssetIdFromRouter() {
        return this.router.resourceIds[0];
    }

    get asset() {
        return this.getAssetFromStore.execute(this.getAssetIdFromRouter());
    }

    get assetVersions() {
        return this._assetVersionsToDisplay.sort((a, b) => b.number - a.number);
    }

    get latestVersion() {
        return this.assetVersions[0];
    }

    get isLatestVersionSelected() {
        return this.selectedAssetVersion === this.latestVersion;
    }

    get selectedAssetVersion() {
        return this.assetVersions.find(
            (assetVersion) => (
                assetVersion.number === this.selectedAssetVersionNumber
            ),
        );
    }

    openUploadModal = () => {
        this.modalService.openModal(FILE_UPLOAD_MODAL, {
            title: 'Upload new version',
            presenter: this.fileUploadPresenter,
            onSelectFiles: this.onSelectFiles,
            backdropClose: false,
            openFileManager: true,
            onClose: this.closeUploadModal,
        });
    }

    downloadAsset = async () => {
        const version = this.selectedAssetVersion;
        const blob = await fetch(version.fileUrl).then((res) => res.blob());

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
            'download',
            `${this.assetName} — version ${this.selectedAssetVersionNumber}.${version.fileExtension}`,
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    openDeleteModal = () => {
        this.modalService.openModal(CONFIRMATION_MODAL, {
            onConfirmationClick: async () => {
                await this.archiveAsset.execute(this.asset.id);
                this.router.navigateTo(
                    MEDIA_GROUP_DETAIL_PATH.replace(':id', this._mediaGroupId),
                );
            },
            title: 'Delete Asset',
            body: `Are you sure you want to delete asset <strong>${this.assetName}</strong>?`,
        });
    }

    closeUploadModal = () => {
        this.modalService.closeModal(FILE_UPLOAD_MODAL);
        this.fileUploadPresenter.cancel();
    }

    get mediaDisplay() {
        return this.asset.contentType && this.mediaDisplayPresenterFactory.for({
            asset: this.asset,
            assetVersion: this.selectedAssetVersion,
        });
    }

    get selectedAssetVersionId() {
        return this.selectedAssetVersion?.id || null;
    }

    get breadcrumbs() {
        return [
            this._breadcrumb('Dashboard', DASHBOARD_PATH),
            this._breadcrumb(this._projectName, PROJECT_DETAIL_PATH.replace(':id', this._projectId)),
            this._breadcrumb(this._mediaGroupName, MEDIA_GROUP_DETAIL_PATH.replace(':id', this._mediaGroupId)),
            this._breadcrumb(this.assetName),
        ];
    }

    _breadcrumb(text, url) {
        return {
            text,
            url,
        };
    }

    get _mediaGroup() {
        return this.asset?.mediaGroup;
    }

    get _mediaGroupName() {
        return this._mediaGroup?.name;
    }

    get _mediaGroupId() {
        return this._mediaGroup?.id;
    }

    get _project() {
        return this._mediaGroup?.project;
    }

    get _projectName() {
        return this._project?.name;
    }

    get _projectId() {
        return this._project?.id;
    }

    async _fetchAsset() {
        try {
            await this.fetchAssetFromRemote.execute(this.getAssetIdFromRouter());
            this.selectLatestVersion();
        } catch (error) {
            this.toastService.notifyError(error.message);
            this._redirectToDashboardPath();
        }
    }

    get assetName() {
        return this.asset.name;
    }

    onSelectFiles = async () => {
        const assetId = this.asset.id;
        const assetVersion = await this.fileUploadPresenter.uploadAssetVersion(assetId);
        if (assetVersion) {
            window.location.reload();
        }
    }

    assetVersionWasClicked = (assetVersion) => {
        this._selectAssetVersion(assetVersion);
    }

    selectLatestVersion = () => {
        if (this.latestVersion) {
            this._selectAssetVersion(this.latestVersion);
        }
    }

    get _assetExtensionLength() {
        return this.asset.extension.length + 1;
    }

    get _assetVersionsToDisplay() {
        return this.asset?.assetVersions
            ? this.asset.assetVersions.map(this._createAssetVersionToDisplay)
            : [];
    }

    _createAssetVersionToDisplay(assetVersion) {
        return new AssetVersionToDisplay(assetVersion);
    }

    _selectAssetVersion(assetVersion) {
        this.selectedAssetVersionNumber = assetVersion.number;
    }

    _redirectToDashboardPath = () => {
        this.router.navigateTo(DASHBOARD_PATH);
    };

    _fetchAssetAndCreateCommentPresenter = async () => {
        await this._fetchAsset();

        this.commentSectionPresenter = new CommentSectionPresenter({
            getCommentsFromStore: this.getCommentsFromStore,
            fetchCommentsFromRemote: this.fetchCommentsFromRemote,
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            getUserFromStore: this.getUserFromStore,
            getProjectMembersFromStore: this.getProjectMembersFromStore,
            fetchProjectMembersFromRemote: this.fetchProjectMembersFromRemote,
            createComment: this.createComment,
            assetId: this.getAssetIdFromRouter(),
            projectId: this.asset.projectId,
            assetVersions: this.assetVersions.slice().reverse(),
            assetLatestVersion: this.latestVersion,
            getSelectedAssetVersionId: () => this.selectedAssetVersionId,
            assetVersionWasClicked: this.assetVersionWasClicked,
        });
    };
}
