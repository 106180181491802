import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import useStyles from './Welcome.styles';
import ProjectsPresenter from '../../presenters/ProjectsPresenter';

/**
 * Good morning
 * Good afternoon
 * Good evening
 */
function Welcome({
    presenter,
}) {
    const classes = useStyles();
    const currentHours = (new Date()).getHours();
    let greetingText;

    switch (true) {
    case currentHours >= 2 && currentHours < 12:
        greetingText = 'Good morning';
        break;

    case currentHours >= 12 && currentHours < 16:
        greetingText = 'Good afternoon';
        break;

    default:
        greetingText = 'Good evening';
        break;
    }

    if (presenter.currentUser?.firstName) {
        greetingText = `${greetingText}, ${presenter.currentUser.firstName}.`;
    }

    return (
        <div className={classes.root}>
            <h1 className={classes.title}>
                {greetingText}
            </h1>
        </div>
    );
}

Welcome.propTypes = {
    presenter: PropTypes.instanceOf(ProjectsPresenter).isRequired,
};

export default observer(Welcome);
