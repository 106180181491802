import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    commentContainer: {
        width: '100%',
        overflowY: 'auto',
    },

    root: {
        'mentions--multiLine': {
            mentions__control: {
                fontSize: '14px',
                color: '#4cacf7',
            },

            mentions__highlighter: {
                border: '1px solid transparent',
                padding: '9px',
                minHeight: '63px',
            },

            mentions__input: {
                border: '1px solid silver',
                padding: '9px',
                outline: 0,
            },
        },

        mentions__suggestions__list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0, 0, 0, 0.15)',
            fontSize: '14px',
            overflowY: 'auto',
        },

        mentions__suggestions__item: {
            padding: '5px 15px',
        },

        'mentions__suggestions__item--focused': {
            backgroundColor: '#cee4e5',
        },
    },

    mentions__mention: {
        position: 'relative',
        color: '#4cacf7',
        zIndex: 1,
        pointerEvents: 'none',
        textShadow: '1px 1px 1px #202325, 1px -1px 1px #202325, -1px 1px 1px #202325, -1px -1px 1px #202325',
    },
}));
