const camelToSnakeCaseString = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const pascalToSnakeCaseString = (str) => camelToSnakeCaseString(
    `${str.charAt(0).toLowerCase()}${str.slice(1)}`,
);

const pascalToSnakeCaseObject = (obj) => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [pascalToSnakeCaseString(key), value]),
);

export const toSnakeCase = (value) => (
    typeof value === 'object' && value !== null
        ? pascalToSnakeCaseObject(value)
        : pascalToSnakeCaseString(value)
);

export const toCapitalized = (str) => (
    (str && str[0].toUpperCase() + str.slice(1)) || ''
);
