import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    projectsSection: {
        backgroundColor: palette.dashboardCardBackgroundSecondary,
        display: 'flex',
        flexDirection: 'column',
    },
}));
