import {makeAutoObservable} from 'mobx';
import FileUploadPresenter from './FileUploadPresenter';
import ProjectTeamPresenter from './ProjectTeamPresenter';
import {DASHBOARD_PATH, PROJECT_DETAIL_PATH} from '../routes/paths';
import {FILE_UPLOAD_MODAL, CONFIRMATION_MODAL, EDIT_FIELDS_MODAL} from '../constants/modals';
import SidebarPresenter from './SidebarPresenter';
import Form from '../forms/Form';
import updateBriefFields from '../forms/updateProjectBriefFields';
import updateLinksFields from '../forms/updateProjectLinksFields';
import updateTimelineFields from '../forms/updateProjectTimelineFields';
import manageFolderFields from '../forms/manageFolderFields';
import renameAssetFields, {
    ASSET_DESCRIPTION,
} from '../forms/renameAssetFields';
import {BRIEF} from '../constants/formFields/updateProjectBriefForm';
import {LINK_ADDRESS, LINK_ID, LINK_TITLE} from '../constants/formFields/updateProjectLinksForm';
import {TIMELINE} from '../constants/formFields/updateProjectTimelineForm';
import {FILE_UPLOAD_STATUS} from '../constants/fileUpload';

export default class MediaGroupDetailPresenter {
    constructor({
        router,
        modalService,
        fetchProjectFromRemote,
        getProjectFromStore,
        getMediaGroupFromStore,
        fetchMediaGroupFromRemote,
        uploadFile,
        addAssetToMediaGroup,
        createAsset,
        createAssetVersion,
        getAssetsFromStore,
        fetchAssetFromRemote,
        getUsersFromStore,
        addProjectMember,
        getCurrentUserFromStore,
        removeProjectMember,
        toastService,
        getProjectMembersFromStore,
        fetchProjectMembersFromRemote,
        archiveAsset,
        logOut,
        manageProject,
        renameAsset,
    } = {}) {
        this.router = router;
        this.modalService = modalService;
        this.fetchProjectFromRemote = fetchProjectFromRemote;
        this.getProjectFromStore = getProjectFromStore;
        this.getMediaGroupFromStore = getMediaGroupFromStore;
        this.fetchMediaGroupFromRemote = fetchMediaGroupFromRemote;
        this.getUsersFromStore = getUsersFromStore;
        this.addProjectMember = addProjectMember;
        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.removeProjectMember = removeProjectMember;
        this.addAssetToMediaGroup = addAssetToMediaGroup;
        this.toastService = toastService;
        this.getProjectMembersFromStore = getProjectMembersFromStore;
        this.fetchProjectMembersFromRemote = fetchProjectMembersFromRemote;
        this.archiveAsset = archiveAsset;
        this.logOut = logOut;
        this.manageProject = manageProject;
        this.renameAsset = renameAsset;

        this.fileUploadPresenter = new FileUploadPresenter({
            uploadFile,
            confirmUpload: this.confirmUpload,
            createAsset,
            createAssetVersion,
            maxFiles: 999,
        });

        this.getAssetsFromStore = getAssetsFromStore;
        this.fetchAssetFromRemote = fetchAssetFromRemote;

        makeAutoObservable(this, {
            sidebarPresenter: false,
        });

        this._fetchMediaGroup();

        this._initializeForms();
    }

    getMediaGroupIdFromRouter() {
        return this.router.resourceIds[0];
    }

    get mediaGroup() {
        return this.getMediaGroupFromStore.execute(this.getMediaGroupIdFromRouter());
    }

    get mediaGroupName() {
        return this.mediaGroup?.name;
    }

    get assets() {
        const assetIds = this.mediaGroup?.assets?.map(
            (asset) => (asset.id),
        );

        return assetIds && this.getAssetsFromStore.execute({ids: assetIds});
    }

    get projectTeamSectionPresenter() {
        return new ProjectTeamPresenter({
            project: this.project,
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            getProjectMembersFromStore: this.getProjectMembersFromStore,
            fetchProjectMembersFromRemote: this.fetchProjectMembersFromRemote,
            modalService: this.modalService,
            getUsersFromStore: this.getUsersFromStore,
            addProjectMember: this.addProjectMember,
            removeProjectMember: this.removeProjectMember,
        });
    }

    get breadcrumbs() {
        return this.project ? [
            this._breadcrumb('Dashboard', DASHBOARD_PATH),
            this._breadcrumb(this.projectName, PROJECT_DETAIL_PATH.replace(':id', this.projectId)),
            this._breadcrumb(this.mediaGroupName),
        ] : [];
    }

    get sidebarPresenter() {
        return new SidebarPresenter({
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            logOut: this.logOut,
            router: this.router,
        });
    }

    _breadcrumb(text, url) {
        return {
            text,
            url,
        };
    }

    get currentUser() {
        return this.getCurrentUserFromStore.execute();
    }

    get isCurrentUserTheOwner() {
        if (this.projectOwner && this.currentUser) {
            return this.currentUser.id === this.projectOwner.id;
        }

        return false;
    }

    get project() {
        return this.mediaGroup?.project?.id && this.getProjectFromStore.execute(this.mediaGroup?.project?.id);
    }

    get projectName() {
        return this.project?.name;
    }

    get projectId() {
        return this.project?.id;
    }

    get projectBrief() {
        return this.project?.brief || '';
    }

    get projectLinks() {
        return this.project?.links || [];
    }

    get projectTimeline() {
        return this.project?.timeline || '';
    }

    get projectOwner() {
        return this.project?.owner;
    }

    async _fetchProject(projectId) {
        try {
            await this.fetchProjectFromRemote.execute(projectId);
        } catch (error) {
            this.toastService.notifyError(error.message);
            this._redirectToDashboardPath();
        }
    }

    async _fetchMediaGroup() {
        try {
            const mediaGroup = await this.fetchMediaGroupFromRemote.execute(
                this.getMediaGroupIdFromRouter(),
            );
            if (mediaGroup) {
                this._fetchProject(mediaGroup.project.id);

                if (mediaGroup.assets) {
                    this._fetchAssets(mediaGroup.assets);
                }
            }
        } catch (error) {
            if (error.isAvailabilityError) {
                this.toastService.notifyError('Post not found');
            } else {
                this.toastService.notifyError(error.message);
            }

            this._redirectToDashboardPath();
        }
    }

    _fetchAssets(assets) {
        assets.forEach((asset) => {
            this.fetchAssetFromRemote.execute(asset.id);
        });
    }

    confirmUpload = async () => {
        const mediaGroupId = this.mediaGroup.id;
        await this.fileUploadPresenter.filesArray
            .filter((file) => file.status === FILE_UPLOAD_STATUS.SUCCESS)
            .reduce(async (acc, file) => {
                const prevAcc = await acc;

                try {
                    const addedAsset = await this.addAssetToMediaGroup.execute({
                        mediaGroupId,
                        assetId: file.assetId,
                    });

                    return [
                        ...prevAcc,
                        addedAsset,
                    ];
                } catch (ex) {
                    return prevAcc;
                }
            }, Promise.resolve([]));

        this.closeUploadModal();
    }

    openUploadModal = () => {
        this.modalService.openModal(FILE_UPLOAD_MODAL, {
            title: 'Upload files',
            presenter: this.fileUploadPresenter,
            onSelectFiles: this.onSelectFiles,
            backdropClose: false,
            onClose: this.closeUploadModal,
        });
    }

    closeUploadModal = () => {
        this.modalService.closeModal(FILE_UPLOAD_MODAL);
        this.fileUploadPresenter.clearFiles();
    }

    onSelectFiles = () => this.fileUploadPresenter.uploadAssets()

    openRenameAssetModal = (assetId) => {
        const asset = this.assets.find(({id}) => assetId === id);

        this.renameAssetForm = new Form({
            fields: renameAssetFields,
        }, {
            hooks: {
                onSuccess: () => this.renameAssetOnSubmitSuccess(assetId),
            },
        });
        this.renameAssetForm.$(ASSET_DESCRIPTION).set(asset.name);
        this.renameAssetForm.$(ASSET_DESCRIPTION).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: 'Rename asset',
            inputs: {
                [ASSET_DESCRIPTION]: {
                    control: this.renameAssetForm.$(ASSET_DESCRIPTION),
                },
            },
            onConfirm: this.renameAssetForm.onSubmit,
        });
    }

    renameAssetOnSubmitSuccess = async (assetId) => {
        const asset = this.assets.find(({id}) => assetId === id);
        const description = this.renameAssetForm.values()[ASSET_DESCRIPTION];

        await this.renameAsset.execute(assetId, {
            filename: asset.filename,
            description,
        });

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
    }

    openDeleteAssetModal = (assetId) => {
        this.modalService.openModal(CONFIRMATION_MODAL, {
            onConfirmationClick: () => this.archiveAsset.execute(assetId),
            title: 'Delete asset?',
            body: `Are you sure you want to delete <strong>${this.assets.find((asset) => asset.id === assetId)?.name}</strong>?`,
            confirm: 'Delete',
        });
    }

    _redirectToDashboardPath = () => {
        this.router.navigateTo(DASHBOARD_PATH);
    }

    editBriefButtonWasClicked = () => {
        this.updateBriefForm.$(BRIEF).set(this.projectBrief);
        this.updateBriefForm.$(BRIEF).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: this.projectBrief
                ? 'Edit brief'
                : 'Add brief',
            inputs: {
                [BRIEF]: {
                    multiline: true,
                    control: this.updateBriefForm.$(BRIEF),
                },
            },
            onConfirm: this.updateBriefForm.onSubmit,
        });
    }

    addLinkButtonWasClicked = () => {
        this.createLinkForm.clear();
        this.createLinkForm.$(LINK_TITLE).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: 'Add a link',
            inputs: {
                [LINK_TITLE]: {
                    control: this.createLinkForm.$(LINK_TITLE),
                },
                [LINK_ADDRESS]: {
                    control: this.createLinkForm.$(LINK_ADDRESS),
                },
            },
            submitButton: 'Add',
            onConfirm: this.createLinkForm.onSubmit,
        });
    }

    editLinkButtonWasClicked = (id) => {
        const {
            address,
            title,
        } = this.projectLinks.find((link) => link.id === id);

        this.updateLinkForm.$(LINK_ID).set(id);
        this.updateLinkForm.$(LINK_TITLE).set(title);
        this.updateLinkForm.$(LINK_TITLE).focus();
        this.updateLinkForm.$(LINK_ADDRESS).set(address);

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: 'Edit link',
            inputs: {
                [LINK_TITLE]: {
                    control: this.updateLinkForm.$(LINK_TITLE),
                },
                [LINK_ADDRESS]: {
                    control: this.updateLinkForm.$(LINK_ADDRESS),
                },
            },
            onConfirm: this.updateLinkForm.onSubmit,
        });
    }

    deleteLinkButtonWasClicked = async (linkId) => {
        await this.manageProject.deleteLink(this.projectId, linkId);
        this.toastService.notifySuccess('Link was successfully deleted');
    }

    editTimelineButtonWasClicked = () => {
        this.updateTimelineForm.$(TIMELINE).set(this.projectTimeline);
        this.updateTimelineForm.$(TIMELINE).focus();

        this.modalService.openModal(EDIT_FIELDS_MODAL, {
            title: this.projectTimeline
                ? 'Edit timeline'
                : 'Add timeline',
            inputs: {
                [TIMELINE]: {
                    multiline: true,
                    control: this.updateTimelineForm.$(TIMELINE),
                },
            },
            onConfirm: this.updateTimelineForm.onSubmit,
        });
    }

    _initializeForms() {
        this.updateBriefForm = new Form({
            fields: updateBriefFields,
        }, {
            hooks: {
                onSuccess: this.updateBriefOnSubmitSuccess,
            },
        });

        this.createLinkForm = new Form({
            fields: updateLinksFields,
        }, {
            hooks: {
                onSuccess: this.createLinkOnSubmitSuccess,
            },
        });

        this.updateLinkForm = new Form({
            fields: updateLinksFields,
        }, {
            hooks: {
                onSuccess: this.updateLinkOnSubmitSuccess,
            },
        });

        this.updateTimelineForm = new Form({
            fields: updateTimelineFields,
        }, {
            hooks: {
                onSuccess: this.updateTimelineOnSubmitSuccess,
            },
        });

        this.createFolderForm = new Form({
            fields: manageFolderFields,
        }, {
            hooks: {
                onSuccess: this.createFolderSuccess,
            },
        });
    }

    updateBriefOnSubmitSuccess = async () => {
        const {
            brief,
        } = this.updateBriefForm.values();

        await this.manageProject.update(this.projectId, {
            brief,
        });

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
    };

    createLinkOnSubmitSuccess = async () => {
        const link = this.createLinkForm.values();
        await this.manageProject.createLink(this.projectId, link);

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
        this.toastService.notifySuccess('Link was successfully created');
    };

    updateLinkOnSubmitSuccess = async () => {
        const link = this.updateLinkForm.values();
        await this.manageProject.updateLink(this.projectId, link);

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
        this.toastService.notifySuccess('Link was successfully updated');
    };

    updateTimelineOnSubmitSuccess = async () => {
        const {
            timeline,
        } = this.updateTimelineForm.values();

        await this.manageProject.update(this.projectId, {
            timeline,
        });

        this.modalService.closeModal(EDIT_FIELDS_MODAL);
    };
}
