import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {ProjectTeamList} from '../ProjectTeamList';
import {Avatar} from '../Avatar';
import {IconButton} from '../IconButton';
import PersonPlusIcon from '../Icons/PersonPlusIcon';
import {useStyles, addMemberStyle} from './ProjectTeamSection.styles';
import ProjectTeamPresenter from '../../presenters/ProjectTeamPresenter';

const ProjectTeamSection = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.projectTeamSection}>
            {presenter.isCurrentUserTheOwner && (
                <IconButton
                    onClick={presenter.expandInviteMemberButtonWasClicked}
                    className={classes.addMember}
                >
                    <Avatar
                        icon={(
                            <PersonPlusIcon />
                        )}
                        styles={addMemberStyle}
                    />
                </IconButton>
            )}
            {!!presenter.mediaGroupTeamListSectionPresenter && (
                <ProjectTeamList
                    presenter={presenter.mediaGroupTeamListSectionPresenter}
                    className={classes.teamList}
                />
            )}
        </div>
    );
};

ProjectTeamSection.propTypes = {
    presenter: PropTypes.instanceOf(ProjectTeamPresenter).isRequired,
};

ProjectTeamSection.defaultProps = {
};

export default observer(ProjectTeamSection);
