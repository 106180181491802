import {makeStyles} from '@material-ui/core/styles';
import {colors, fonts, palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    button: {
        ...fonts.buttonText,

        color: palette.cancelAction,
        textTransform: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            color: colors.cherry,
        },
    },
}));
