import GetEntityFromStore from './GetEntityFromStore';
import Project from '../entities/Project';
import MediaGroup from '../entities/MediaGroup';
import Asset from '../entities/Asset';
import User from '../entities/User';
import Mention from '../entities/Mention';

export default class GetEntityFromStoreFactory {
    static forProjects({store}) {
        return new GetEntityFromStore({
            store, createEntity: (params) => new Project(params),
        });
    }

    static forMediaGroups({store}) {
        return new GetEntityFromStore({
            store, createEntity: (params) => new MediaGroup(params),
        });
    }

    static forAssets({store}) {
        return new GetEntityFromStore({
            store, createEntity: (params) => new Asset(params),
        });
    }

    static forUsers({store}) {
        return new GetEntityFromStore({
            store, createEntity: (params) => new User(params),
        });
    }

    static forMentions({store}) {
        return new GetEntityFromStore({
            store, createEntity: (params) => new Mention(params),
        });
    }
}
