import {
    action, computed, makeObservable, observable,
} from 'mobx';
import Store from './Store';

export default class UserStore extends Store {
    constructor(props) {
        super(props);
        this._currentUserId = null;

        makeObservable(this, {
            _currentUserId: observable,
            updateCurrentUser: action,
            currentUser: computed,
        });
    }

    updateCurrentUser(user) {
        this._currentUserId = user.id;
        if (this.currentUser) {
            this.update(this._currentUserId, user);
        } else {
            this.add(user);
        }
    }

    get currentUser() {
        return this.find(this._currentUserId);
    }

    deleteCurrentUser() {
        this.delete(this._currentUserId);
        this._currentUserId = null;
    }
}
