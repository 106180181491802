import LogRocket from 'logrocket';

export default class LogService {
    init() {
        try {
            LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
                network: {
                    isEnabled: false,
                },
            });
        // eslint-disable-next-line no-empty
        } catch (ex) {}
    }

    identify = (userId) => {
        try {
            LogRocket.identify(userId.toString());
        // eslint-disable-next-line no-empty
        } catch (ex) {}
    }
}
