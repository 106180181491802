export default class FetchEntitiesFromRemote {
    constructor({store, service}) {
        this.store = store;
        this.service = service;
    }

    async execute() {
        const entities = await this.fetchFromService();
        this.putInStore(entities);
    }

    fetchFromService() {
        return this.service.getAll();
    }

    putInStore(entities) {
        this.store.updateList(entities);
    }
}
