export default class ArchiveAsset {
    constructor({assetService, assetStore, mediaGroupStore}) {
        this.assetStore = assetStore;
        this.assetService = assetService;
        this.mediaGroupStore = mediaGroupStore;
    }

    async execute(assetId) {
        const asset = await this._archiveAsset(assetId);
        const mediaGroup = this.mediaGroupStore.find(asset.mediaGroup.id);
        this._removeAssetFromStore(assetId);
        this._removeAssetFromMediaGroup(mediaGroup, assetId);
        return asset;
    }

    async _archiveAsset(assetId) {
        return this.assetService.delete(assetId);
    }

    _removeAssetFromStore(assetId) {
        this.assetStore.delete(assetId);
    }

    _removeAssetFromMediaGroup(mediaGroup, assetId) {
        mediaGroup.removeAsset({assetId});
        this.mediaGroupStore.update(mediaGroup.id, mediaGroup);
    }
}
