import {
    useCallback,
    useEffect,
    useState,
} from 'react';

export default function useCalculatePreviewLayout({
    presenter,
    columnWidth,
    asideWidth,
    ref,
}) {
    const [
        columnsCount,
        setColumnsCount,
    ] = useState(0);

    const handleResize = useCallback(() => {
        if (ref?.current) {
            const layoutWidth = ref.current.offsetWidth;

            const columns = Math.floor((layoutWidth - asideWidth) / columnWidth);
            setColumnsCount(
                Math.max(2, columns),
            );
        }
    }, [
        columnWidth,
        asideWidth,
        ref,
    ]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, [
        presenter,
        handleResize,
    ]);

    return [
        columnsCount,
    ];
}
