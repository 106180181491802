import {
    makeAutoObservable,
} from 'mobx';
import {
    DASHBOARD_PATH,
} from '../routes/paths';
import SidebarPresenter from './SidebarPresenter';
import Form from '../forms/Form';
import {
    FIRST_NAME,
    LAST_NAME,
    passwordFields,
    profileNameFields,
} from '../forms/profileFields';

export default class ProfilePresenter {
    constructor({
        router,
        getCurrentUserFromStore,
        logOut,
        usersService,
        userStore,
    } = {}) {
        this.router = router;
        this.getCurrentUserFromStore = getCurrentUserFromStore;
        this.logOut = logOut;
        this.userStore = userStore;
        this.usersService = usersService;

        this.formSubmitDisabled = false;
        this.formError = null;
        this.currentForm = null;

        this.formsEnum = {
            name: 'name',
            password: 'password',
        };
        this.passwordChangedMessage = null;

        makeAutoObservable(this);

        this._initForms();
    }

    get currentUser() {
        return this.getCurrentUserFromStore.execute();
    }

    get breadcrumbs() {
        return [
            this._breadcrumb('Back', DASHBOARD_PATH),
        ];
    }

    get sidebarPresenter() {
        return new SidebarPresenter({
            getCurrentUserFromStore: this.getCurrentUserFromStore,
            logOut: this.logOut,
            router: this.router,
        });
    }

    showNameForm = () => {
        this.currentForm = this.formsEnum.name;
        this.nameForm.$(FIRST_NAME).set(this.currentUser?.firstName);
        this.nameForm.$(FIRST_NAME).focus();
        this.nameForm.$(LAST_NAME).set(this.currentUser?.lastName);
    }

    showPasswordForm = () => {
        this.passwordChangedMessage = null;
        this.currentForm = this.formsEnum.password;
        this.nameForm.clear();
    }

    closeForm = () => {
        this.passwordForm.clear();
        this.currentForm = null;
        this.formError = null;
    }

    changeNotificationsSettings = async (key, value) => {
        await this._updateCurrentUser({
            [key]: value,
        });
    }

    _initForms() {
        this.nameForm = new Form({
            fields: profileNameFields,
        }, {
            hooks: {
                onSuccess: this._onNameFormSubmitSuccess,
            },
        });

        this.passwordForm = new Form({
            fields: passwordFields,
        }, {
            hooks: {
                onSuccess: this._onPasswordFormSubmitSuccess,
            },
        });
    }

    _onNameFormSubmitSuccess = async (form) => {
        const formData = form.values();

        const isOk = await this._updateCurrentUser(formData);
        if (isOk) {
            this.closeForm();
        }
    }

    _onPasswordFormSubmitSuccess = async (form) => {
        const formData = form.values();

        const isOk = await this._updateCurrentUser(formData);
        if (isOk) {
            this.closeForm();
            this.passwordChangedMessage = 'Password successfully changed';
        }
    }

    _breadcrumb(text, url) {
        return {
            text,
            url,
        };
    }

    async _updateCurrentUser(data) {
        let ok = false;
        try {
            this.formSubmitDisabled = true;
            this.formError = null;

            const updatedUser = await this.usersService.updateUser(this.currentUser?.id, data);
            // Do not update form if password was updated due to string response
            if (this.currentForm !== this.formsEnum.password) {
                this.userStore.updateCurrentUser(updatedUser);
            }

            ok = true;
        } catch (ex) {
            this.formError = ex.message;
            ok = false;
        } finally {
            this.formSubmitDisabled = false;
        }

        return ok;
    }
}
