export default class FetchEntityFromRemote {
    constructor({store, service}) {
        this.store = store;
        this.service = service;
    }

    async execute(id, settings = {}) {
        const {
            useCache = false,
        } = settings;

        if (useCache) {
            const entityFromStore = this.store.find(id);
            if (entityFromStore) {
                this.store.update(id, entityFromStore);

                return entityFromStore;
            }
        }

        const entityFromRemote = await this.service.get(id);
        this.store.update(id, entityFromRemote);

        return entityFromRemote;
    }
}
