import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from './providers/AppProvider';

if (process.env.REACT_APP_USE_MSW === 'true') {
    // eslint-disable-next-line global-require
    const {worker} = require('./tests/mocks/browser');
    worker.start();
}

ReactDOM.render(
    <AppProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </AppProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
