import {
    DirectUpload,
} from 'activestorage';
import ErrorFactory from '../factories/ErrorFactory';

export default class DirectFileUpload {
    constructor({
        file,
        url = `${process.env.REACT_APP_API_BASE_URL}/1/presigned_url`,
        token,
        onProgress,
    } = {}) {
        this.file = file;
        this.url = url;
        this.token = token;
        this.onProgress = onProgress;
        this.directUpload = new DirectUpload(this.file, this.url, this);
    }

    static with({
        file,
        token,
        onProgress,
    }) {
        const fileUpload = new DirectFileUpload({
            file,
            token,
            onProgress,
        });

        return fileUpload.upload();
    }

    upload() {
        return new Promise((resolve, reject) => {
            this.directUpload.create(this.createResponseHandler(resolve, reject));
        });
    }

    directUploadWillCreateBlobWithXHR(xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    }

    directUploadWillStoreFileWithXHR(xhr) {
        xhr.upload.addEventListener('progress', this.handleProgress);
    }

    createResponseHandler(resolve, reject) {
        return (error, blob) => {
            try {
                const result = this.handleResponse(error, blob);
                resolve(result);
            } catch (ex) {
                reject(ex);
            }
        };
    }

    handleResponse(error, blob) {
        if (error) {
            throw ErrorFactory.fromDirectUploadError({error});
        }

        return blob;
    }

    handleProgress = ({
        loaded,
        total,
    }) => this.onProgress({
        loaded,
        total,
    })
}
