import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

import {useStyles} from './AudioDisplay.styles';

const AudioDisplay = ({presenter}) => {
    const classes = useStyles();

    return (
        <div className={classes.audio_display}>
            <ReactPlayer
                config={{file: {attributes: {controlsList: 'nodownload noplaybackrate'}}}}
                url={presenter.fileUrl}
                controls
                data-testid="audio-display"
                className={classes.video}
                width="100%"
                height="100%"
            />
        </div>
    );
};

AudioDisplay.propTypes = {
    presenter: PropTypes.shape({
        fileUrl: PropTypes.string.isRequired,
    }).isRequired,
};

AudioDisplay.defaultProps = {
};

export default observer(AudioDisplay);
