import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {DashboardCard} from '../DashboardCard';
import {MentionList} from '../MentionList';

import {useStyles} from './MentionSection.styles';
import {KebabMenu} from '../KebabMenu';
import ProjectsPresenter from '../../presenters/ProjectsPresenter';

const MentionSection = ({presenter}) => {
    const classes = useStyles();

    const showEmptyMessage = presenter.mentionPresenters.length === 0;
    let header;

    switch (presenter.numberOfUnseenMentions) {
    case 0:
        header = 'You’ve got no new mentions';
        break;

    default:
        header = (
            <>
                {'You’ve got '}
                <em>{presenter.numberOfUnseenMentionsToDisplay}</em>
                {` new mention${presenter.numberOfUnseenMentions > 1 ? 's' : ''}`}
            </>
        );
        break;
    }

    return (
        <DashboardCard
            className={classes.mentionSection}
            header={header}
            scrollable={!showEmptyMessage}
            headerActions={presenter.numberOfUnseenMentions > 0 && (
                <div className={classes.kebab}>
                    <KebabMenu
                        theme={'dark'}
                        position={'left'}
                        options={[{
                            key: 'mark',
                            label: 'Mark all as seen',
                            onClick: presenter.markAllMentionsAsSeenButtonWasClicked,
                        }]}
                    />
                </div>
            )}
        >
            {showEmptyMessage && (
                <div className={classes.empty}>
                    Comments will appear in this section when someone mentions you.
                </div>
            )}
            {!showEmptyMessage && (
                <MentionList
                    mentionPresenters={presenter.mentionPresenters}
                />
            )}
        </DashboardCard>
    );
};

MentionSection.propTypes = {
    presenter: PropTypes.instanceOf(ProjectsPresenter).isRequired,
};

export default observer(MentionSection);
