import {
    Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import {
    observer,
} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {Accordion as MantineAccordion, Text} from '@mantine/core';
import {KebabMenu} from '../KebabMenu';

import {useStyles} from './Accordion.styles';
import Scrollable from '../Scrollable/Scrollable';
import {ReactComponent as PlusIcon} from '../../icons/plus-icon.svg';

function onToggle(evt, allowed) {
    if (!allowed) {
        evt.preventDefault();
        evt.stopPropagation();
    }
}

const AccordionLabel = ({item, showKebabMenu}) => {
    const classes = useStyles();

    const options = [{
        key: item.action,
        label: item.action,
        onClick: item.onClick,
    }];

    return (
        <div
            className={clsx(classes.labelComponent, !showKebabMenu && classes.labelComponentDisabled)}
            onClick={(evt) => onToggle(evt, showKebabMenu)}
        >
            <Text className={classes.labelText}>
                {item.label}
            </Text>
            {showKebabMenu && (
                <KebabMenu
                    theme={'dark'}
                    className={classes.labelKebab}
                    options={options}
                    position={'left'}
                />
            )}
        </div>
    );
};

const Accordion = ({
    items,
    showKebabMenu,
    showEmptyItems,
}) => {
    const classes = useStyles();

    return (
        <MantineAccordion
            iconPosition={'right'}
            classNames={classes}
        >
            {items
                .filter((accordionItem) => Boolean(accordionItem.content) || showEmptyItems)
                .map((accordionItem) => {
                    const isEmpty = !accordionItem.content;

                    return (
                        <MantineAccordion.Item
                            className={classes.toggle}
                            label={(
                                <AccordionLabel
                                    item={accordionItem}
                                    showKebabMenu={!isEmpty && showKebabMenu}
                                />
                            )}
                            icon={isEmpty && showKebabMenu && (
                                <Tooltip
                                    title={`Add ${accordionItem.label.toLowerCase()}`}
                                    placement={'top'}
                                >
                                    <div
                                        className={classes.addIcon}
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();

                                            accordionItem.onClick(evt);
                                        }}
                                    >
                                        <PlusIcon />
                                    </div>
                                </Tooltip>
                            )}
                            disableIconRotation={isEmpty}
                            key={accordionItem.label}
                            onClick={(evt) => onToggle(evt, !isEmpty)}
                        >
                            {!isEmpty && (
                                <Scrollable
                                    theme={'light'}
                                    className={classes.scrollable}
                                    watch
                                >
                                    {typeof accordionItem.content === 'string' && (
                                        <div className={classes.contentWrapper}>
                                            {accordionItem.content}
                                        </div>
                                    )}
                                    {typeof accordionItem.content !== 'string' && accordionItem.content}
                                </Scrollable>
                            )}
                        </MantineAccordion.Item>
                    );
                })}
        </MantineAccordion>
    );
};

Accordion.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        content: PropTypes.any,
    })).isRequired,
    showKebabMenu: PropTypes.bool,
    showEmptyItems: PropTypes.bool,
};

Accordion.defaultProps = {
    showKebabMenu: true,
    showEmptyItems: true,
};

AccordionLabel.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }).isRequired,
    showKebabMenu: PropTypes.bool.isRequired,
};

export default observer(Accordion);
