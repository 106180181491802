export const PROJECT_ID = 'id';
export const PROJECT_NAME = 'name';

const fields = [{
    name: PROJECT_NAME,
    label: 'Name',
    placeholder: '',
    rules: 'required',
}];

export default fields;
