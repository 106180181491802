export default class GetEntitiesByIdFromStore {
    constructor({store}) {
        this.store = store;
    }

    execute({ids}) {
        return this.entities(ids);
    }

    entities(ids) {
        return this.store.findAll(ids);
    }
}
