import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import {ModalHeader} from '../ModalHeader';
import ModalFooter from '../ModalFooter/ModalFooter';

import classes from './FileUploadModal.module.scss';
import {FileUploader} from '../FileUploader';
import {ModalCard} from '../ModalCard';
import FileUploadPresenter from '../../presenters/FileUploadPresenter';
import {TextButton} from '../TextButton';
import {PrimaryButton} from '../PrimaryButton';
import ModalBody from '../ModalBody/ModalBody';

const FileUploadModal = ({
    open,
    onClose,
    presenter,
    onSelectFiles,
    title,
    openFileManager,
    ...rest
}) => (
    <Modal
        open={open}
        onClose={onClose}
        invisible={openFileManager && !presenter.hasFilesToUpload}
        {...rest}
    >
        <ModalCard className={classes.root}>
            <ModalHeader>
                <h1>{title}</h1>
            </ModalHeader>
            <ModalBody
                className={classes.fileUploaderContainer}
                scrollable
            >
                <FileUploader
                    presenter={presenter}
                    onSelectFiles={onSelectFiles}
                    openFileManager={openFileManager}
                    dropzoneProps={{
                        onFileDialogCancel: openFileManager ? onClose : () => {},
                    }}
                />
            </ModalBody>
            {!openFileManager && (
                <ModalFooter>
                    <TextButton onClick={onClose}>
                        Cancel
                    </TextButton>
                    <PrimaryButton
                        disabled={!presenter.isConfirmUploadButtonActive}
                        onClick={presenter.confirmUpload}
                    >
                        {presenter.isPosting ? 'Posting…' : 'Post'}
                    </PrimaryButton>
                </ModalFooter>
            )}
        </ModalCard>
    </Modal>
);

FileUploadModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    presenter: PropTypes.instanceOf(FileUploadPresenter),
    onSelectFiles: PropTypes.func,
    title: PropTypes.string,
    openFileManager: PropTypes.bool,
};

FileUploadModal.defaultProps = {
    open: false,
    presenter: null,
    onSelectFiles: null,
    title: '',
    openFileManager: false,
};

export default observer(FileUploadModal);
