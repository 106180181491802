import ResponseDeserializer from './ResponseDeserializer';
import ErrorResponse from './ErrorResponse';

export default class FileResponseDeserializer extends ResponseDeserializer {
    get responseBody() {
        return {
            data: this.response.data,
            contentType: this.response.headers['content-type'],
        };
    }

    get errorResponse() {
        return new ErrorResponse({
            statusCode: this.statusCode,
            name: this.responseBody.data.error_name,
            description: this.responseBody.data.error_message,
        });
    }
}
