import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        color: palette.formTitle,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '30px',
        minHeight: '30px',

        '& h1': {
            margin: 0,
            ...fonts.subTitle,
        },
    },
}));
