import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import ProjectCard from './ProjectCard';
import PlusIcon from '../Icons/PlusIcon';

import classes from './ProjectList.module.scss';
import ProjectsPresenter from '../../presenters/ProjectsPresenter';

const ProjectList = ({presenter}) => (
    <div className={classes.projectGrid}>
        {presenter.projects.map((project) => (
            <ProjectCard
                project={project}
                presenter={presenter}
                key={project.id}
            />
        ))}
        <div
            className={classes.projectItem}
            onClick={presenter.onCreateProjectButtonClicked}
        >
            <div className={classes.projectItemInner}>
                <div className={classes.addProject}>
                    <PlusIcon />
                </div>
            </div>
        </div>
    </div>
);

ProjectList.propTypes = {
    presenter: PropTypes.instanceOf(ProjectsPresenter).isRequired,
};

export default observer(ProjectList);
