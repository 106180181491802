import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#202325',
        display: 'flex',
        margin: '0 0 5px 0',
        borderRadius: '8px',
        padding: '13px 10px 13px 13px',
        color: '#fff',
        boxSizing: 'border-box',
    },

    avatar_group: {
        gap: '4px',
    },

    avatar: {
        backgroundColor: '#728391 !important',
        width: '24px !important',
        height: '24px !important',
        fontSize: '10px !important',
        fontFamily: 'Poppins !important, sans-serif',
        marginLeft: '0 !important',
        border: 'none !important',
    },
}));

export const avatarStyles = {
    width: '32px',
    height: '32px',
    fontSize: '10px',
    fontFamily: 'Poppins, sans-serif',
};
