import {makeStyles} from '@material-ui/core/styles';
import {fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        fontSize: '14px',
        textAlign: 'left',
        marginBottom: '6px',
        ...fonts.smallTitle2,
    },
}));
