import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {Avatar} from '../../Avatar';
import User from '../../../entities/User';

const ProjectMemberAvatar = ({projectMember, styles}) => {
    const projectMemberKey = projectMember.id;

    return (
        <div>
            <Avatar
                user={projectMember.user}
                key={projectMemberKey}
                styles={styles}
                useTooltip
            />
        </div>
    );
};

ProjectMemberAvatar.propTypes = {
    projectMember: PropTypes.shape({
        id: PropTypes.number.isRequired,
        user: PropTypes.instanceOf(User).isRequired,
    }).isRequired,
    styles: PropTypes.any.isRequired,
};

ProjectMemberAvatar.defaultProps = {
};

export default observer(ProjectMemberAvatar);
