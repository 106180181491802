import {makeStyles} from '@material-ui/core/styles';
import {palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        opacity: 0.1,
        border: `solid 1px ${palette.lines}`,
        margin: '2% 0',
    },
}));
