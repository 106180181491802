import {Icon} from '../Icon';
import {logoutIcon} from '../../icons';

const ClockIcon = () => (
    <Icon>
        <img src={logoutIcon} alt="logout" width={12} height={12} />
    </Icon>
);

export default ClockIcon;
