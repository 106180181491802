import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import AvatarGroup from '@mui/material/AvatarGroup';

import {useStyles, avatarStyles} from './ProjectTeamList.styles';

import ProjectMemberAvatar from './components/ProjectMemberAvatar';
import {DivButton} from '../DivButton';
import MediaGroupTeamListSectionPresenter from '../../presenters/MediaGroupTeamListSectionPresenter';

const ProjectTeamList = ({presenter, className}) => {
    const classes = useStyles();
    const rootClassName = className || classes.root;

    return (
        <div className={rootClassName}>
            <DivButton onClick={presenter.projectMemberAvatarButtonWasClicked}>
                <AvatarGroup
                    max={presenter.numberOfAvatarsShown + 1}
                    classes={{avatar: classes.avatar, root: classes.avatar_group}}
                >
                    {presenter.projectMembers.map((projectMember) => (
                        <ProjectMemberAvatar
                            projectMember={projectMember}
                            styles={avatarStyles}
                            key={projectMember.id}
                        />
                    ))}
                </AvatarGroup>
            </DivButton>
        </div>
    );
};

ProjectTeamList.propTypes = {
    presenter: PropTypes.instanceOf(MediaGroupTeamListSectionPresenter).isRequired,
    className: PropTypes.string,
};

ProjectTeamList.defaultProps = {
    className: '',
};

export default observer(ProjectTeamList);
