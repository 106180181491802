import {makeAutoObservable} from 'mobx';
import User from './User';
import AssetVersion from './AssetVersion';

export default class Comment {
    constructor({
        id,
        text,
        createdAt,
        assetVersionId,
        user,
        assetVersion,
    }) {
        this.id = id;
        this.text = text;
        this.createdAt = createdAt;
        this.assetVersionId = assetVersionId;
        this.user = user;
        this.assetVersion = assetVersion;

        makeAutoObservable(this);
    }

    toJSON() {
        const user = this.user.toJSON();
        /* eslint-disable-next-line camelcase */
        const asset_version = this.assetVersion && this.assetVersion.toJSON();

        return {
            id: this.id,
            text: this.text,
            created_at: this.createdAt,
            asset_version_id: this.assetVersionId,
            user,
            asset_version,
        };
    }

    static fromJSON(data) {
        const user = data.user && User.fromJSON(data.user);
        const assetVersion = data.asset_version && AssetVersion.fromJSON(data.asset_version);

        return new Comment({
            id: data.id,
            text: data.text,
            createdAt: new Date(data.created_at),
            assetVersionId: data.asset_version_id,
            user,
            assetVersion,
        });
    }
}
