import {
    TIMELINE,
} from '../constants/formFields/updateProjectTimelineForm';

const fields = [{
    name: TIMELINE,
    label: '',
    placeholder: '',
    rules: '',
}];

export default fields;
