class Font {
    constructor({
        fontFamily, fontSize, lineHeight, letterSpacing, fontWeight, fontStretch, fontStyle,
    }) {
        this.fontFamily = fontFamily;
        this.fontSize = fontSize;
        this.lineHeight = lineHeight;
        this.letterSpacing = letterSpacing;
        this.fontWeight = fontWeight;
        this.fontStretch = fontStretch;
        this.fontStyle = fontStyle;
    }
}

export default Font;
