import {makeAutoObservable} from 'mobx';
import AssetThumbnailPresenter from './AssetThumbnailPresenter';
import DateToDisplay from './ToDisplay/DateToDisplay';
import {noPostPlaceholderIcon} from '../icons';
import {MEDIA_GROUPS_PATH} from '../routes/paths';
import {CONFIRMATION_MODAL, MANAGE_FOLDER_MODAL} from '../constants/modals';
import Form from '../forms/Form';
import manageFolderFields, {
    FOLDER_NAME,
} from '../forms/manageFolderFields';

export default class MediaGroupThumbnailPresenter {
    constructor({
        getMediaGroupFromStore,
        fetchMediaGroupFromRemote,
        getAssetsFromStore,
        fetchAssetsFromRemote,
        mediaGroup,
        modalService,
        updateMediaGroup,
    } = {}) {
        this.getMediaGroupFromStore = getMediaGroupFromStore;
        this.fetchMediaGroupFromRemote = fetchMediaGroupFromRemote;
        this.getAssetsFromStore = getAssetsFromStore;
        this.fetchAssetsFromRemote = fetchAssetsFromRemote;
        this.mediaGroupId = mediaGroup.id;
        this.maxNumberOfAssetsToDisplay = 3;
        this.modalService = modalService;
        this.updateMediaGroup = updateMediaGroup;

        makeAutoObservable(this);

        this._fetchMediaGroup();

        this.updateFolderForm = new Form({
            fields: manageFolderFields,
        }, {
            hooks: {
                onSuccess: this.updateFolderSuccess,
            },
        });
    }

    get mediaGroup() {
        return this.getMediaGroupFromStore.execute(this.mediaGroupId);
    }

    get mediaGroupName() {
        return this.mediaGroup?.name || '';
    }

    get mediaGroupUrl() {
        return `${MEDIA_GROUPS_PATH}/${this.mediaGroupId}`;
    }

    get assets() {
        const assetIds = this._assetIdsToDisplay;
        return assetIds && this.getAssetsFromStore.execute({ids: assetIds});
    }

    get numberOfAssets() {
        return this.mediaGroup?.assets?.length || 0;
    }

    get assetThumbnailPresenters() {
        return (this.assets || [])
            .map(this._createAssetThumbnailPresenter)
            .slice(0, this.maxNumberOfAssetsToDisplay);
    }

    get numberOfExtraThumbnails() {
        return Math.max(this.numberOfAssets - this.maxNumberOfAssetsToDisplay, 0);
    }

    updateMediaGroupButtonWasClicked = () => {
        this.updateFolderForm.$(FOLDER_NAME).set(this.mediaGroup.name);
        this.updateFolderForm.$(FOLDER_NAME).focus();

        this.modalService.openModal(MANAGE_FOLDER_MODAL, {
            title: 'Rename folder',
            inputs: {
                [FOLDER_NAME]: {
                    multiline: false,
                    control: this.updateFolderForm.$(FOLDER_NAME),
                },
            },
            onConfirm: this.updateFolderForm.onSubmit,
            submitButton: 'Rename',
        });
    }

    deleteMediaGroupButtonWasClicked = () => {
        this.modalService.openModal(CONFIRMATION_MODAL, {
            onConfirmationClick: () => this.updateMediaGroup.remove({
                mediaGroupId: this.mediaGroup.id,
                projectId: this.mediaGroup.project.id,
            }),
            title: 'Delete folder',
            body: `Are you sure you want to delete folder <strong>${this.mediaGroup.name}</strong>?`,
        });
    }

    get lastUpdatedAt() {
        return new DateToDisplay({
            date: this.mediaGroup.lastUpdatedAt,
        }).displayTimeAgoWithText;
    }

    get _assetIdsToDisplay() {
        return this.mediaGroup?.assets?.map((asset) => asset.id) || [];
    }

    async _fetchMediaGroup() {
        try {
            const mediaGroup = await this.fetchMediaGroupFromRemote.execute(this.mediaGroup.id);

            if (mediaGroup && mediaGroup.assets) {
                this._fetchAssets(mediaGroup.assets);
            }
        } catch {} // eslint-disable-line no-empty
    }

    _fetchAssets(assets) {
        const assetsToDisplay = assets.slice(0, this.maxNumberOfAssetsToDisplay);
        assetsToDisplay.forEach((asset) => this.fetchAssetsFromRemote.execute(asset.id, {
            useCache: true,
        }));
    }

    _createAssetThumbnailPresenter = (asset) => (
        new AssetThumbnailPresenter({
            asset,
            useWhiteThumbnails: true,
        })
    )

    _createEmptyPostThumbnailPresenter = () => (
        new AssetThumbnailPresenter({
            img: noPostPlaceholderIcon,
        })
    )

    updateFolderSuccess = async () => {
        const {
            name,
        } = this.updateFolderForm.values();

        await this.updateMediaGroup.update({
            name,
            mediaGroupId: this.mediaGroup.id,
            projectId: this.mediaGroup.project.id,
        });

        this.modalService.closeModal(MANAGE_FOLDER_MODAL);
    };
}
