import {makeAutoObservable} from 'mobx';

export default class ImageDisplayPresenter {
    constructor({imageUrl}) {
        this.imageUrl = imageUrl;

        makeAutoObservable(this);
    }

    get type() {
        return 'image';
    }
}
