import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';

import Form from '../../forms/Form';
import {DESCRIPTION} from '../../constants/formFields/createAssetForm';
import {InputTextWithLabel} from '../InputTextWithLabel';
import {FileUploader} from '../FileUploader';
import {Card} from '../Card';

const NewAssetForm = ({
    presenter,
}) => (
    <Card>
        <h1>Add media</h1>
        <FileUploader
            presenter={presenter.fileUploadPresenter}
        />
        <InputTextWithLabel
            errorMessage={presenter.form.$(DESCRIPTION).error}
            {...presenter.form.$(DESCRIPTION).bind()}
        />
    </Card>
);

NewAssetForm.propTypes = {
    presenter: PropTypes.shape({
        form: PropTypes.instanceOf(Form).isRequired,
        fileUploadPresenter: PropTypes.shape({
            uploadAsset: PropTypes.func.isRequired,
        }),
    }).isRequired,
};

NewAssetForm.defaultProps = {
};

export default observer(NewAssetForm);
