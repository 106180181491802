import {makeAutoObservable} from 'mobx';
import DateToDisplay from './ToDisplay/DateToDisplay';
import {
    ASSET_DETAIL_PATH,
} from '../routes/paths';

export default class ActivityPresenter {
    constructor({
        activity,
        assetVersion,
        markActivitiesAsSeen,
    } = {}) {
        this.activity = activity;
        this.assetVersion = assetVersion;
        this.markActivitiesAsSeen = markActivitiesAsSeen;

        makeAutoObservable(this);
    }

    get activityId() {
        return this.activity.id;
    }

    get isSeen() {
        return this.activity.isSeen;
    }

    get assetName() {
        return this._asset.name;
    }

    get assetUrl() {
        return ASSET_DETAIL_PATH.replace(':id', this._asset.id);
    }

    get projectName() {
        return this._project.name;
    }

    get mediaGroupName() {
        return this._mediaGroup.name;
    }

    get assetVersionNumberToDisplay() {
        return `version ${this._assetVersionNumber}`;
    }

    get dateToDisplay() {
        if (!this.assetVersion?.createdAt) {
            return null;
        }

        const date = new DateToDisplay({date: this.assetVersion.createdAt});
        return date.displayTimeAgoShort;
    }

    markOneAsSeen(activityId) {
        return this.markActivitiesAsSeen.markAsSeenById(activityId);
    }

    get _asset() {
        return this.assetVersion?.asset || this.activity?.activityOn;
    }

    get _assetVersionNumber() {
        return this.assetVersion?.number || this._asset?.number || 1;
    }

    get _project() {
        return this._mediaGroup?.project;
    }

    get _mediaGroup() {
        return this._asset?.mediaGroup;
    }

    get _dateToDisplayPresenter() {
        return this.assetVersion?.createdAt && new DateToDisplay({date: this.assetVersion?.createdAt});
    }
}
