import {
    observer,
} from 'mobx-react-lite';
import useProfileWireframe from '../../wireframes/useProfileWireframe';
import ProfileSection from '../../components/ProfileSection/ProfileSection';

import classes from './Profile.module.scss';
import {Breadcrumbs} from '../../components/Breadcrumbs';
import {Sidebar} from '../../components/Sidebar';

const ProfileScreen = () => {
    const presenter = useProfileWireframe();

    return !!presenter && (
        <div className={classes.root}>
            <Sidebar
                presenter={presenter.sidebarPresenter}
            />
            <div className={classes.breadcrumbs}>
                <Breadcrumbs
                    breadcrumbs={presenter.breadcrumbs}
                    theme="dark"
                />
            </div>
            <div className={classes.content}>
                <h1 className={classes.header}>
                    Account Settings
                </h1>
                <div className={classes.layout}>
                    <aside className={classes.aside}>
                        <div className={classes.navigationList}>
                            <div className={classes.navigationItem}>
                                Profile
                            </div>
                        </div>
                    </aside>
                    <main className={classes.main}>
                        <ProfileSection
                            presenter={presenter}
                        />
                    </main>
                </div>
            </div>
        </div>
    );
};

export default observer(ProfileScreen);
