import {makeAutoObservable} from 'mobx';
import History from './History';
import {DEFAULT_PATH, LOGIN_PATH} from '../routes/paths';

export default class Router {
    constructor(history = new History()) {
        this.history = history;

        makeAutoObservable(this, {
            history: false,
            resourceIds: false,
        });
    }

    get resourceIds() {
        // Matches "/1"
        const getIdsRegexp = /\/(\d+)/g;
        const matches = this.history.location.pathname.match(getIdsRegexp) || [];
        const groups = matches.map((match) => (
            +match.substring(1)
        ));

        return groups;
    }

    navigateTo(path) {
        this.history.push(path);
    }

    onLogIn() {
        this.history.replace(this.history.location.state?.from || DEFAULT_PATH);
    }

    onLogOut() {
        this.history.replace(LOGIN_PATH);
    }
}
