export default class FetchFileFromRemote {
    constructor({service}) {
        this.service = service;
    }

    async execute(url) {
        return this._fetchFromService(url);
    }

    _fetchFromService(url) {
        return this.service.get(url);
    }
}
