import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import ProjectMember from '../../entities/ProjectMember';
import MemberList from './components/MemberList';
import Owner from './components/Owner';
import {ModalHeader} from '../ModalHeader';
import {PrimaryButton} from '../PrimaryButton';
import {ModalCard} from '../ModalCard';
import PersonPlusIcon from '../Icons/PersonPlusIcon';

import {useStyles} from './TeamModal.styles';
import User from '../../entities/User';
import ModalBody from '../ModalBody/ModalBody';

const TeamModal = ({
    open,
    onClose,
    owner,
    members,
    inviteMemberButtonWasClicked,
    isCurrentUserTheOwner,
    removeMemberButtonWasClicked,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            {...rest}
        >
            <ModalCard className={classes.root}>
                <ModalHeader>
                    <h1>Team</h1>
                    {
                        isCurrentUserTheOwner
                        && (
                            <PrimaryButton
                                onClick={inviteMemberButtonWasClicked}
                                startIcon={(
                                    <div className={classes.personPlusIcon}>
                                        <PersonPlusIcon />
                                    </div>
                                )}
                            >
                                Invite User
                            </PrimaryButton>
                        )
                    }
                </ModalHeader>
                <ModalBody>
                    <Owner owner={owner} />
                    <MemberList
                        members={members}
                        removeMemberButtonWasClicked={removeMemberButtonWasClicked}
                        isCurrentUserTheOwner={isCurrentUserTheOwner}
                    />
                </ModalBody>
            </ModalCard>
        </Modal>
    );
};

TeamModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    owner: PropTypes.instanceOf(User),
    members: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMember)),
    isCurrentUserTheOwner: PropTypes.bool,
    inviteMemberButtonWasClicked: PropTypes.func,
    removeMemberButtonWasClicked: PropTypes.func,
};

TeamModal.defaultProps = {
    open: false,
    owner: null,
    members: [],
    isCurrentUserTheOwner: false,
    inviteMemberButtonWasClicked: null,
    removeMemberButtonWasClicked: null,
};

export default observer(TeamModal);
