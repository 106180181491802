import PropTypes from 'prop-types';
import clsx from 'clsx';
import AssetVersion from '../../../entities/AssetVersion';

const VersionOption = ({
    innerProps,
    data,
    isSelected,
    isFocused,
    className,
}) => (
    <div
        {...innerProps}
        className={clsx(className, isSelected && 'selected', isFocused && 'focused')}
    >
        <span className="dropdownOption__number">{`V${data.number}`}</span>
        <div className="dropdownOption__detail">
            <div className="dropdownOption__title">{data.filename}</div>
            <div>{data.dateToDisplay}</div>
        </div>
    </div>
);
VersionOption.propTypes = {
    innerProps: PropTypes.any.isRequired,
    data: PropTypes.instanceOf(AssetVersion).isRequired,
    isSelected: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
};

export default VersionOption;
