import {makeStyles} from '@material-ui/core/styles';
import {palette, fonts} from '../../Theme';

export const useStyles = makeStyles(() => ({
    card: {
        position: 'relative',
        backgroundColor: palette.formBackgroundPrimary,
        paddingTop: 'min(6%, 30px)',
        paddingLeft: 'min(5%, 44px)',
        paddingRight: 'min(5%, 30px)',
        paddingBottom: 'min(3%, 22px)',
        marginTop: '8px',
        marginBottom: '8px',
        borderRadius: '6px',

        '& h1': {
            ...fonts.subTitle,

            color: palette.formTitle,
        },
    },
}));
