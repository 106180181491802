import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Avatar} from '../../Avatar';
import {BorderButton} from '../../BorderButton';

import {useStyles, avatarStyle} from '../TeamModal.styles';
import User from '../../../entities/User';

const Member = ({
    user,
    removeMemberButtonWasClicked,
    isCurrentUserTheOwner,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.memberItem}>
            <div className={classes.memberInfo}>
                <Avatar
                    user={user}
                    styles={avatarStyle}
                />
                <p>{user.fullName}</p>
            </div>
            {
                isCurrentUserTheOwner && (
                    <BorderButton onClick={removeMemberButtonWasClicked}>
                        Remove
                    </BorderButton>
                )
            }
        </div>
    );
};

Member.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    removeMemberButtonWasClicked: PropTypes.func.isRequired,
    isCurrentUserTheOwner: PropTypes.bool,
};

Member.defaultProps = {
    isCurrentUserTheOwner: false,
};

export default observer(Member);
