import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {MentionsInput, Mention} from 'react-mentions';

import styles from './TextAreaWithMentions.module.scss';
import {useStyles} from './TextAreaWithMentions.styles';
import noop from '../../helpers/noop';
import {Highlight} from '../Highlight';
import User from '../../entities/User';

const TextAreaWithMentions = ({
    value,
    onChange,
    users,
    submitOnEnter,
    onSubmit,
}) => {
    const mentionableUsers = users
        .map((user) => ({id: user.id, display: user.fullName}));

    const renderUserSuggestion = (suggestion, search) => (
        <Highlight source={suggestion.display} target={search} />
    );

    const classes = useStyles();

    const handleKeyPress = async (event) => {
        if (!submitOnEnter) return;

        if (event.key === 'Enter' && !event.shiftKey) {
            await onSubmit(event);
        }
    };

    return (
        <div className={classes.commentContainer}>
            <MentionsInput
                value={value}
                onChange={onChange}
                classNames={styles}
                placeholder="Write a comment..."
                onKeyPress={handleKeyPress}
            >
                <Mention
                    trigger={'@'}
                    appendSpaceOnAdd
                    data={mentionableUsers}
                    renderSuggestion={renderUserSuggestion}
                    className={classes.mentions__mention}
                />
            </MentionsInput>
        </div>
    );
};

TextAreaWithMentions.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.instanceOf(User)),
    submitOnEnter: PropTypes.bool,
    onSubmit: PropTypes.func,
};

TextAreaWithMentions.defaultProps = {
    value: '',
    onChange: noop,
    users: [],
    submitOnEnter: false,
    onSubmit: null,
};

export default observer(TextAreaWithMentions);
