import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Accordion} from '../Accordion';

import {useStyles} from './ProjectInformation.styles';
import ProjectLinks from '../ProjectLinks/ProjectLinks';

const ProjectInformation = ({presenter}) => {
    const classes = useStyles();
    const accordionItems = [
        {
            label: 'Project links',
            action: 'Add a link',
            content: presenter.projectLinks.length > 0 && (
                <ProjectLinks
                    isEditable={presenter.isCurrentUserTheOwner}
                    links={presenter.projectLinks}
                    onEdit={(id) => presenter.editLinkButtonWasClicked(id)}
                    onDelete={(id) => presenter.deleteLinkButtonWasClicked(id)}
                />
            ),
            onClick: presenter.addLinkButtonWasClicked,
        },
        {
            label: 'Brief',
            action: 'Edit brief',
            content: presenter.projectBrief,
            onClick: presenter.editBriefButtonWasClicked,
        },
        {
            label: 'Timeline',
            action: 'Edit timeline',
            content: presenter.projectTimeline,
            onClick: presenter.editTimelineButtonWasClicked,
        },
    ];

    return (
        <div className={classes.root}>
            <Accordion
                items={accordionItems}
                showKebabMenu={presenter.isCurrentUserTheOwner}
                showEmptyItems
            />
        </div>
    );
};

ProjectInformation.propTypes = {
    presenter: PropTypes.shape({
        projectLinks: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                project_id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                address: PropTypes.string.isRequired,
            }),
        ),
        projectBrief: PropTypes.string,
        projectTimeline: PropTypes.string,
        addLinkButtonWasClicked: PropTypes.func,
        editLinkButtonWasClicked: PropTypes.func,
        deleteLinkButtonWasClicked: PropTypes.func,
        editBriefButtonWasClicked: PropTypes.func,
        editTimelineButtonWasClicked: PropTypes.func,
        isCurrentUserTheOwner: PropTypes.bool,
    }).isRequired,
};

ProjectInformation.defaultProps = {
};

export default observer(ProjectInformation);
