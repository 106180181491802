import {makeStyles} from '@material-ui/core/styles';
import {fonts, palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    root: {
        ...fonts.buttonText,

        textTransform: 'none',
        display: 'flex',
        backgroundColor: palette.secondaryButtonBackground,
        color: palette.primary,
        padding: '6px 16px',
        borderRadius: '22.5px',
        boxShadow: 'none',
        whiteSpace: 'nowrap',

        '&:hover': {
            ...fonts.buttonText,

            backgroundColor: palette.secondaryButtonBackground,
            color: palette.primary,
            boxShadow: '0 4px 10px 0 rgba(67, 85, 176, 0.45)',
        },

        '&:disabled': {
            ...fonts.buttonText,

            backgroundColor: palette.secondaryDisabledButtonBackground,
        },
    },
}));
