import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import classes from './ModalCard.module.scss';

const ModalCard = ({children, className}) => (
    <div className={clsx(classes.root, className)}>
        {children}
    </div>
);

ModalCard.propTypes = {
    className: PropTypes.string,
};

ModalCard.defaultProps = {
    className: '',
};

export default observer(ModalCard);
