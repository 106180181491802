import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    audio_display: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        width: '100%',
        marginTop: '20%',
        position: 'relative',
    },

    video: {
        borderRadius: '15px',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));
