import TextDisplayPresenter from '../presenters/TextDisplayPresenter';
import ImageDisplayPresenter from '../presenters/ImageDisplayPresenter';
import PdfDisplayPresenter from '../presenters/PdfDisplayPresenter';
import VideoDisplayPresenter from '../presenters/VideoDisplayPresenter';
import VeneerError from '../errors/VeneerError';
import AudioDisplayPresenter from '../presenters/AudioDisplayPresenter';

export default class MediaDisplayPresenterFactory {
    constructor({fetchFileFromRemote}) {
        this.fetchFileFromRemote = fetchFileFromRemote;
    }

    for({asset, assetVersion}) {
        const {contentType} = asset;

        if (this._isText(contentType)) {
            return this._createTextDisplayPresenter(assetVersion);
        } if (this._isImage(contentType)) {
            return this._createImageDisplayPresenter(assetVersion);
        } if (this._isPdf(contentType)) {
            return this._createPdfDisplayPresenter(assetVersion);
        } if (this._isVideo(contentType)) {
            return this._createVideoDisplayPresenter(assetVersion);
        } if (this._isAudio(contentType)) {
            return this._createAudioDisplayPresenter(assetVersion);
        }

        throw this._createError();
    }

    _createTextDisplayPresenter({fileUrl}) {
        return new TextDisplayPresenter({
            fileUrl,
            fetchFileFromRemote: this.fetchFileFromRemote,
        });
    }

    _createImageDisplayPresenter({fileUrl}) {
        return new ImageDisplayPresenter({
            imageUrl: fileUrl,
        });
    }

    _createPdfDisplayPresenter({fileUrl}) {
        return new PdfDisplayPresenter({
            fileUrl,
        });
    }

    _createVideoDisplayPresenter({fileUrl}) {
        return new VideoDisplayPresenter({
            fileUrl,
        });
    }

    _createAudioDisplayPresenter({fileUrl}) {
        return new AudioDisplayPresenter({
            fileUrl,
        });
    }

    _isText(contentType) {
        return contentType.startsWith('text');
    }

    _isImage(contentType) {
        return contentType.startsWith('image');
    }

    _isPdf(contentType) {
        return contentType.endsWith('pdf');
    }

    _isVideo(contentType) {
        return contentType.startsWith('video');
    }

    _isAudio(contentType) {
        return contentType.startsWith('audio');
    }

    _createError() {
        throw new VeneerError({
            name: 'invalid file type',
            description: 'this file format cannot be displayed',
        });
    }
}
