/* eslint-disable camelcase */
import {
    makeAutoObservable,
} from 'mobx';

export default class User {
    constructor(data) {
        this.data = {
            id: '',
            email: '',
            first_name: '',
            last_name: '',
            mail_mentions: false,
            mail_comments: false,
            ...data,
        };

        makeAutoObservable(this);
    }

    get id() {
        return this.toJSON().id;
    }

    get email() {
        return this.toJSON().email;
    }

    get name() {
        return this.firstName || this.email;
    }

    get fullName() {
        const fullName = [
            this.firstName,
            this.lastName,
        ].filter(Boolean).join(' ');

        return fullName || this.email;
    }

    get searchName() {
        return [
            this.firstName,
            this.lastName,
            this.email,
        ].filter(Boolean).join(' ');
    }

    get firstName() {
        return this.toJSON().first_name || '';
    }

    get lastName() {
        return this.toJSON().last_name || '';
    }

    get initials() {
        return this.fullName
            .split(' ')
            .map((part) => part.substring(0, 1))
            .map((part) => part.toUpperCase())
            .join('');
    }

    get mailMentions() {
        return this.toJSON().mail_mentions;
    }

    get mailComments() {
        return this.toJSON().mail_comments;
    }

    toJSON() {
        return this.data;
    }

    static fromJSON(data) {
        return new User(data);
    }
}
