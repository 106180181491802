import {observer} from 'mobx-react-lite';

import {useStyles} from './ModalHeader.styles';

const ModalHeader = ({children}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};

ModalHeader.propTypes = {
};

ModalHeader.defaultProps = {
};

export default observer(ModalHeader);
