import {makeStyles} from '@material-ui/core/styles';
import {fonts, palette} from '../../Theme';

export const useStyles = makeStyles(() => ({
    SignUpSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '490px',
        padding: '50px 45px 40px',
        boxSizing: 'border-box',
        borderRadius: '16px',
        backgroundColor: palette.dashboardCardBackgroundSecondary,
    },

    logo: {
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'center',
    },

    header: {
        ...fonts.title,

        padding: 0,
        fontSize: '24px',
        textAlign: 'center',
        marginBottom: '45px',
        marginTop: 0,
        fontWeight: 500,
    },
}));
