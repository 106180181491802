import {observer} from 'mobx-react-lite';
import useCreateMediaGroupWireframe from '../../wireframes/useCreateMediaGroupWireframe';
import {NewMediaGroup} from '../../components/NewMediaGroup';

const MediaGroupCreation = () => {
    const presenter = useCreateMediaGroupWireframe();

    return !!presenter && (
        <NewMediaGroup presenter={presenter} />
    );
};

MediaGroupCreation.propTypes = {
};

MediaGroupCreation.defaultProps = {
};

export default observer(MediaGroupCreation);
