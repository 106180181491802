export default class RemoveProjectMember {
    constructor({projectMemberStore, projectMemberService}) {
        this.projectMemberStore = projectMemberStore;
        this.projectMemberService = projectMemberService;
    }

    async execute(projectMemberId) {
        const projectMember = await this._removeMember(projectMemberId);
        this._removeFromStore(projectMember);
        return projectMember;
    }

    async _removeMember(projectMemberId) {
        return this.projectMemberService.removeMember(projectMemberId);
    }

    _removeFromStore(projectMember) {
        this.projectMemberStore.delete(projectMember.id);
    }
}
