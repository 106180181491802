export default class CreateAsset {
    constructor({service}) {
        this.service = service;
    }

    async execute({signedId, description}) {
        return this.service.create({
            file: signedId,
            description,
        });
    }
}
